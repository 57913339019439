@media screen and (max-width: 1199px) and (min-width: 300px) {
  .desk {
    display: none !important;
  }

  .mobile {
  }

  body {
    margin: 0;
    padding: 0;
    font-family: NotoSansRegular;
    height: 100%;
  }

  #root {
    height: 100%;
  }

  .m-l-10 {
    margin-left: 10px;
  }

  .m-r-2 {
    margin-right: 8px;
  }

  .m-r-10 {
    margin-right: 10px;
  }

  .m-t-10 {
    margin-top: 10px !important;
  }

  .m-t-m13 {
    margin-top: -13px !important;
  }

  .m-t-20 {
    margin-top: 20px !important;
  }

  .m-b-0 {
    margin-bottom: 0px !important;
  }

  .m-b-1 {
    margin-bottom: 4px !important;
  }

  .m-b-2 {
    margin-bottom: 8px !important;
  }

  .m-b-10 {
    margin-bottom: 10px !important;
  }

  .width-50 {
    width: 50%;
  }

  .flex-1 {
    flex: 1;
  }

  .flex-row {
    display: flex;
  }

  .flex-column {
    flex-direction: column;
  }

  .flex-sb {
    justify-content: space-between;
  }

  .flex-end {
    justify-content: flex-end;
  }

  .flex-center {
    align-items: center;
  }

  .justify-center {
    justify-content: center;
  }

  .pointer {
    color: blue;
    cursor: pointer;
  }
  .ant-descriptions-item-label {
    background-color: #cef1ce !important;
    border: 1px #bbb solid !important;
  }
  .ant-descriptions-item-content {
    background-color: #fff !important;
    border: 1px #bbb solid !important;
  }
  .ant-table-thead > tr > th {
    /* text-align: center; */
    background-color: #cee2f1;
    /* border: 1px solid #c2c2c0;
        font-weight: 530; */
  }

  .table-column-text {
    word-break: break-all;
  }

  .table-column-center {
    text-align: center !important;
  }
  .table-column-hide {
    display: none;
  }

  /* .table-column-surGroup{
        text-align: center;
        width: 50%;
      } */

  .table-column-left {
    display: inline-block;
    width: 550;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    height: 1.5em;
    -webkit-box-orient: vertical;
    line-height: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  .table-column-content {
    text-align: left;
    border: 1px solid #d1d1d1;
    background-color: #f5f5f5;
    margin-bottom: 10px;
    padding: 10px 15px 10px 20px;
    word-break: keep-all;
  }

  .detailModal-wrapper {
    /* border: 1px solid #696968; */
    border-radius: 0px;
    padding: 20px;
  }

  .detailRegistryModal-wrapper {
    border: 1px solid #696968;
    border-radius: 0px;
    padding: 20px;
  }

  .grid-title {
    border: 1px solid #cfcfcf;
    background-color: #cee2f1;
    text-align: center;
    height: 30px;
  }

  .grid-content {
    border: 1px solid #cfcfcf;
    padding-left: 10px;
  }

  .grid-content-bottom {
    border-bottom: 1px solid #cfcfcf;
    border-right: 1px solid #cfcfcf;
    padding-left: 10px;
  }

  .grid-content-subText > {
    min-height: 800px;
    margin-left: 10px;
  }

  .width-1240 {
    min-width: 1000px;
  }

  /* system*/
  .block-chain-input {
    width: 200px;
  }
  .header-menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .menu-wrapper {
    padding: 15px 10px;
  }
  .menu-right {
    padding-right: 15px;
  }

  .top-menu {
    display: inline-block;
    margin: 0px 5px;
    cursor: pointer;
    font-family: NotoSansRegular;
    font-size: 14px;
    color: #fff;
    font-weight: normal;
  }
  .top-menu:hover {
    color: #fddc00;
    border-bottom: 3px solid #fddc00;
  }
  .top-menu.active {
    color: #fddc00;
    border-bottom: 3px solid #fddc00;
  }

  .login-container {
    max-width: 900px;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .login-form {
    text-align: center;
    width: 340px;
    padding: 50px;
    display: inline-block;
    background-color: #fddc00;
    height: 750px;
  }
  .login-logo {
    margin-top: 100px;
  }
  .login-system-name {
    font-size: 28px;
  }
  .login-input {
    margin-top: 40px;
    text-align: left;
  }
  .login-input-item {
    height: 40px;
    font-size: 16px;
  }
  .login-version {
    margin-top: 80px;
    text-align: right;
  }
  .login-form-button {
    background-color: #000;
    color: #fff;
    border-width: 0px;
    width: 100%;
    height: 40px;
    font-size: 16px;
  }
  .login-form-button:hover {
    background-color: #fff;
    color: #fddc00;
    border-width: 0px;
  }
  .top-input-checkbox {
    margin-top: 0px;
    padding-left: 5px;
    text-align: left;
  }
  .top-input-checkbox-text {
    margin-left: 5px;
    display: inline-block;
    font-size: 16px;
    letter-spacing: -1px;
  }

  .inline-formitem {
    display: inline-block;
    margin-bottom: 0;
  }

  .multi-depth-menuitem {
    padding-left: 60px !important;
  }

  .table-disabled {
    background-color: #eeeeee;
  }
  .font-red {
    color: #ff0000;
  }
  .text-ellipsis {
    max-width: 180px;
    white-space: nowrap;
    /* width: 180px; */
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .batch {
    font-size: 13px;
    color: green;
  }
  .logo {
    width: 300px;
    margin-bottom: 50px;
  }
  .ant-layout-header {
    line-height: 30px !important;
  }
}
