@font-face {
  font-family: 'NotoSansLight';
  font-style: normal;
  font-weight: 300;
  src: local('Noto Sans Light'), local('NotoSans-Light'),
    url(NotoSans-Light.eot);
  src: url(NotoSans-Light.eot?#iefix) format('embedded-opentype'),
    url(NotoSans-Light.woff2) format('woff2'),
    url(NotoSans-Light.woff) format('woff');
}
@font-face {
  font-family: 'NotoSansMedium';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans Medium'), local('NotoSans-Medium'),
    url(NotoSans-Medium.eot);
  src: url(NotoSans-Medium.eot?#iefix) format('embedded-opentype'),
    url(NotoSans-Medium.woff2) format('woff2'),
    url(NotoSans-Medium.woff) format('woff');
}
@font-face {
  font-family: 'NotoSansRegular';
  font-style: normal;
  font-weight: 500;
  src: local('Noto Sans Regular'), local('NotoSans-Regular'),
    url(NotoSans-Regular.eot);
  src: url(NotoSans-Regular.eot?#iefix) format('embedded-opentype'),
    url(NotoSans-Regular.woff2) format('woff2'),
    url(NotoSans-Regular.woff) format('woff');
}
@font-face {
  font-family: 'NotoSansBold';
  font-style: normal;
  font-weight: 600;
  src: local('Noto Sans Bold'), local('NotoSans-Bold'), url(NotoSans-Bold.eot);
  src: url(NotoSans-Bold.eot?#iefix) format('embedded-opentype'),
    url(NotoSans-Bold.woff) format('woff');
}
@font-face {
  font-family: 'NotoSansBlack';
  font-style: normal;
  font-weight: 900;
  src: local('Noto Sans Black'), local('NotoSans-Black'),
    url(NotoSans-Black.eot);
  src: url(NotoSans-Black.eot?#iefix) format('embedded-opentype'),
    url(NotoSans-Black.woff2) format('woff2'),
    url(NotoSans-Black.woff) format('woff');
}
