@media screen and (min-width: 1200px) {
  .desk {
  }

  .mobile {
    display: none !important;
  }

  .Dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.56);
    z-index: 100;
  }
  .Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 720px;
    border-radius: 30px;
    z-index: 999;
  }

  .contentBlock:nth-child(1) {
    margin-top: 0px !important;
  }

  .input-deliveryPrice {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    font-size: 20px;
  }

  /* 가맹점조회 */
  .searchFranchise-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    border-radius: 30px;
    z-index: 999;
  }

  .searchFranchise-Dialog .layout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .frNameTag {
    cursor: pointer;
    line-height: 32px;
    font-size: 15px;
    letter-spacing: -0.5px;
  }
  .frNameTag:nth-child(odd) {
    cursor: pointer;
    background-color: #efefef;
  }
  /* .frNameTag > div{
    line-height:32px;
    font-size:16px;
  } */

  .riderNameTag {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }

  .selMulti {
    margin-left: 20px !important;
  }

  .searchFranchise-checkAll {
    float: right;
    margin-right: -380px;
  }

  .searchFranchise-checkAll {
    float: right;
    margin-right: -380px;
  }

  .searchFranchise-content {
    background-color: white;
    padding: 30px;
    max-height: 80vh;
    text-align: left;
    color: #777;
  }

  .searchFranchise-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .searchFranchise-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }
  .searchFranchise-Dialog .layout {
    margin-top: 20px;
    width: 100%;
    border: 1px solid #ccc;
    padding: 20px 20px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .searchFranchise-Dialog .layout .contentBlock {
    display: inline-block;
    line-height: 20px;
  }

  .searchFranchise-Dialog .layout .contentBlock span {
    font-size: 18px;
  }

  .searchFranchise-Dialog .layout .submitBtn {
    float: right;
  }

  .searchFranchise-list {
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
  }
  .searchFranchise-list input {
    width: 400px;
  }
  .searchFranchise-Dialog .ant-table-thead > tr > th {
    background-color: black !important;
    color: white !important;
  }
  .searchFranchise-btn {
    display: inline-block;
    vertical-align: bottom;
  }

  .searchFranchise-btn .ant-btn-primary {
    background-color: black;
    border-style: none;
  }
  .searchFranchise-searchbox {
    float: right;
    margin: 20px 0;
  }

  .searchFranchise-Dialog .layout .dataTableLayout-01 {
    margin-top: 30px;
    max-height: 400px;
    overflow-y: scroll;
  }
  .searchFranchise-Dialog .layout .ant-table-tbody > tr > td {
    padding: 0 !important;
  }

  /* 우편번호 팝업 */
  .postCode-Dialog {
    position: fixed;
    top: 20%;
    left: 48%;
    transform: translate(-50%, -50%);
    width: 530px;
    border-radius: 30px;
    z-index: 999;
  }

  .postCode-Dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 990;
  }

  .postCode-close {
    width: 30px;
    position: absolute;
    right: -20px;
    top: 10px;
    cursor: pointer;
    background-color: #fddc00;
    padding: 8px;
  }

  /* 직원 수정 dialog */
  .updateStaff-Dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .updateStaff-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 530px;
    border-radius: 30px;
    z-index: 2;
  }

  .updateStaff-content {
    background-color: white;
    padding: 30px;
    text-align: left;
    color: #777;
  }

  .updateStaff-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .updateStaff-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .updateStaffWrapper .mainTitle {
    width: 130px;
  }

  .updateStaffWrapper .updateStaffCheck {
    display: inline-block;
    margin-left: 22px;
  }

  .updateStaff-Dialog .layout {
    margin-top: 20px;
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px 20px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .updateStaff-Dialog .layout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .updateStaff-Dialog .layout .contentBlock {
    margin-top: 25px;
  }

  .updateStaffWrapper .submitBlock {
    margin-top: 30px;
    text-align: center;
  }

  .updateStaffWrapper .clearBtn {
    margin-left: 20px;
  }

  .updateStaffWrapper .contentBlock .override-select.branch {
    width: 240px;
  }

  .updateStaffWrapper .override-select.sub {
    width: 270px !important;
  }

  .updateStaffWrapper .contentBlock .override-input {
    width: 270px;
  }
  .updateStaffWrapper .ant-radio-group {
    margin-left: 20px;
  }
  .updateStaffWrapper span.ant-radio + * {
    padding-left: 5px;
    padding-right: 5px;
  }
  .updateStaff-Dialog .ant-btn {
    width: 130px;
    height: 40px !important;
    font-size: 18px !important;
  }

  /* 지도 관제 dialog */

  .map-Dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
  }

  .map-Dialog .mapLayout .map-navermap {
    width: 100%;
    height: 100%;
  }

  .map-Dialog .layout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .assign-rider-btn {
    position: absolute;
    top: 10%;
    left: 24.95%;
  }

  .map-menu-bar {
    width: 100%;
    background-color: #fff;
    height: 56px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .map-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }

  .map-close {
    z-index: 90;
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
  }

  .select-rider-orderCnt {
    display: flex;
    z-index: 90;
    width: 30px;
    cursor: pointer;
  }

  .map-inner .textLayout {
    height: 56px;
    line-height: 56px;
  }
  .orderListInMapControl {
    max-width: 40vw;
    min-width: 30vw;
    position: absolute;
    top: 40vh;
    left: 10px;
    z-index: 50;
    height: 40vh;
  }
  .riderOderTable {
    height: 25vh;
    position: absolute;
    top: 56px;
    overflow-x: auto;
    /* -ms-overflow-style: none; */
  }
  /* .riderOderTable::-webkit-scrollbar {
    display: none;
  } */
  .callDataTable {
    height: 100%;
    overflow-y: scroll;
    z-index: 11;
    /* -ms-overflow-style: none; */
  }
  /* .callDataTable::-webkit-scrollbar {
    display: none;
  } */

  .riderListTable {
    height: 40vh;

    overflow-y: auto;
    /* -ms-overflow-style: none; */
  }
  /* .riderListTable::-webkit-scrollbar {
    display: none;
  } */
  .table-column-center.arrive {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .riderListInMapControl {
    min-width: 22vw;
    position: absolute;
    right: 10px;
    top: 40vh;
    z-index: 50;
  }
  .riderListInMapControl-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2px;
  }
  .rider-list-show-btn {
    float: right;
    position: absolute;
    z-index: 100;
    right: 10px;
    top: 44%;
    background-color: #000;
    padding: 5px 20px;
    color: #fff;
    cursor: pointer;
  }
  .rider-list-show-btn-01 {
    background-color: #000;
    padding: 5px 20px;
    color: #fff;
    cursor: pointer;
  }

  .order-list-show-btn {
    position: absolute;
    z-index: 100;
    left: 10px;
    top: 42%;
    background-color: #000;
    padding: 5px 20px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    border: 1px solid yellow;
  }
  .order-list-show-btn-02 {
    position: absolute;
    z-index: 100;
    left: 135px;
    top: 42%;
    background-color: #000;
    padding: 5px 20px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    border: 1px solid yellow;
  }
  .order-list-show-btn-02-open {
    background-color: #000;
    padding: 5px 20px;
    color: #fff;
    cursor: pointer;
  }

  .order-list-show-btn-01 {
    background-color: #000;
    padding: 5px 20px;
    color: #fff;
    cursor: pointer;
  }
  .selectFrLayout {
    position: absolute;
    left: 80px;
    top: 42%;
    display: inline-block;
    z-index: 11;
    /* margin-left: 100px; */
    /* margin-right: 1rem; */
  }

  .map-box .ant-checkbox {
    top: 0em !important;
  }
  .map-box .ant-checkbox-wrapper {
    display: table;
  }

  .map-box .ant-checkbox-wrapper > span {
    display: table-cell;
    text-align: center;
  }

  .map-box > div {
    width: 120px;
    height: 52px;
    float: left;
    /* margin:20px; */
  }

  .map-box .ant-checkbox-inner {
    width: 45px;
    height: 45px;
  }
  .map-box .ant-checkbox-inner::after {
    width: 25px;
    height: 25px;
  }

  .map-box .ant-checkbox + span {
    font-size: 20px;
    vertical-align: middle;
  }

  /* 기사 추가 dialog */
  .addRider-Dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .addRider-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 650px;
    border-radius: 30px;
    z-index: 2;
  }

  .addRider-content {
    background-color: white;
    padding: 30px;
    /* text-align: left; */
    /* color: #777; */
  }

  .addRider-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .addRider-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .addRider-Dialog .addRiderlayout {
    height: 540px;
  }

  .addRiderWrapper .contentBlock {
    margin-top: 30px !important;
  }

  .addRiderWrapper .contentBlock .selectItem .override-select {
    width: 345px;
  }

  .addRider-Dialog .dataTableBlock {
    margin-top: 40px;
  }

  .addRiderWrapper .submitBlock {
    margin-top: 30px;
    text-align: center;
  }

  /* 그룹 추가 dialog */
  .addGroup-Dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .addGroup-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    border-radius: 30px;
    z-index: 2;
  }

  .addGroup-content {
    background-color: white;
    padding: 30px;
    height: 100%;
    /* text-align: left; */
    /* color: #777; */
  }

  .addGroup-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .addGroupWrapper {
    margin-top: 20px;
  }
  .addGrouplayout {
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .addGrouplayout .contentBlock .override-input {
    width: 260px;
  }

  .addGroup-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .ncashMenu {
    padding: 8px;
    border-radius: 10px;
    border: 1px #000 solid;
    cursor: pointer;
  }

  .ncashMenu.active {
    border: none;
    background: #fddc00;
  }

  /* .addGroupWrapper .contentBlock {
  margin-top: 30px;
} */

  .addGroupWrapper .submitBlock {
    margin-top: 30px;
    text-align: center;
  }

  .addGroupWrapper .ant-btn-primary {
    width: 130px;
    height: 40px;
    font-size: 18px;
  }

  /* 공지사항 dialog */
  .noticeDialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    border-radius: 30px;
    padding: 20px;
    z-index: 999;
  }

  .noticeLayout {
    margin-top: 5px;
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    padding: 20px;
  }

  .deleteBox {
    float: left;
    /* margin-bottom: 20px; */
  }
  .deleteBox-text {
    font-size: 15px;
    margin-left: 5px;
    line-height: 30px;
  }

  .noticeLayout .ant-checkbox-wrapper {
    height: 32px;
    margin-left: 0 !important;
    vertical-align: top;
  }

  .registBtn {
    float: right;
    margin-bottom: 20px;
  }

  .noticeDialog .container {
    background-color: white;
    padding: 30px;
    text-align: left;
    color: #777;
  }

  .notice-title {
    /* font-size: 28px; */
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .notice-close {
    width: 30px;
    background-color: #fddc00;
    padding: 8px;
    cursor: pointer;
    /* display: inline-block; */
    float: right;
  }

  .noticeLayout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .noticeLayout .noticelistBlock {
    float: left;
    width: 100%;
  }

  .noticeTag {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  /* 공지사항 등록 dialog */
  .registNoticeDialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    border-radius: 30px;
    padding: 20px;
    z-index: 1000;
  }

  .Regist-Notice-Dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }

  .registNotice-content {
    background-color: white;
    padding: 30px;
    text-align: left;
    color: #777;
  }

  .registNotice-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .registNotice-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .registNoticeDialog .layout {
    margin-top: 20px;
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px 20px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .registNoticeWrapper {
    display: inline-block;
  }

  .registNoticeWrapper .mainTitle {
    width: 130px;
  }

  .registNoticeWrapper .subTitle {
    width: 100px;
    margin-left: 20px;
  }

  .registNoticeWrapper .importantBox {
    /* margin-left: -; */
    display: inline-block;
  }

  .registNoticeCheck {
    display: inline-block;
    margin-left: 20px;
    height: 31.7px;
  }

  .registNoticeDialog .layout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .registNoticeDialog .layout .contentBlock {
    margin-top: 25px;
  }

  .registNoticeWrapper .submitBlock {
    margin-top: 20px;
    text-align: right;
  }

  .registNoticeWrapper .clearBtn {
    margin-left: 20px;
  }

  .registNoticeWrapper .contentBlock .override-select.branch {
    width: 240px;
  }

  .registNoticeWrapper .override-select.sub {
    width: 270px !important;
  }

  .registNoticeWrapper .contentBlock .override-input {
    width: 260px;
    margin-left: 0 !important;
  }

  .registNoticeWrapper .ant-radio-group {
    margin-left: 20px;
  }

  .registNoticeWrapper span.ant-radio + * {
    padding-left: 5px;
    padding-right: 5px;
  }

  .registNoticeDialog .ant-btn {
    width: 130px;
    height: 40px !important;
    font-size: 18px !important;
  }

  .registNoticeWrapper.sub {
    display: inline-block;
    margin-left: 70px;
  }

  .registNoticeWrapper.sub .contentBlock .override-input {
    width: 260px;
    height: 85px;
  }

  .notice-content {
    display: inline-block !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    /* max-width: 20px; */
    /* height: 200px !important; */
  }

  .registNoticeDialog .ant-btn {
    width: 130px;
    height: 40px;
    font-size: 18px !important;
  }

  .registNoticeDialog .importantBox .ant-checkbox-wrapper {
    margin-left: 15px !important;
  }

  .mainTitle {
    display: inline-block;
    font-size: 16px;
    vertical-align: middle;
    font-weight: bold;
    /* margin-right: 20px; */
    /* width: 75px; */
  }
  .subTitle {
    display: inline-block;
    font-size: 16px;
    vertical-align: middle;
    font-weight: bold;
    width: 80px;
  }

  .surcheck .checkboxInput {
    display: inline-block;
    width: 225px !important;
    margin-left: 0px !important;
  }

  .selectBox {
    display: inline-block;
    margin-left: 10px;
    font-size: 18px;
    vertical-align: middle;
    color: #fddc00;
    border-radius: 10px;
  }
  .selectBox .ant-form-item {
    margin-bottom: 0px;
  }
  .selectBox .ant-picker-input > input {
    text-align: center;
    width: 130px;
  }
  .m-t-20 {
    padding-left: 15px;
  }
  .m-t-20 > div:nth-child(2) {
    margin: 20px 0;
  }
  .m-t-20 > div:nth-child(3) {
    float: left;
  }
  .m-t-20 > div > div:first-child {
    width: 80px;
  }
  .btnInsert {
    float: right;
    margin-top: 0px;
  }
  .inputBox {
    display: inline-block;
    /* margin-left: 10px; */
    font-size: 18px;
    vertical-align: middle;
    color: #fddc00;
    border-radius: 10px;
  }
  .memoInputBox {
    width: 400px;
    height: 200px;
  }
  .inputBox .ant-form-item {
    margin-bottom: 0px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }

  .btnInsert .ant-btn {
    width: 130px;
    font-size: 18px;
    border-radius: 2px;
    padding: 0px;
    height: 40px;
    margin-top: 20px;
    float: right;
  }

  /* 콜등록 dialog */
  .registCall-Dialog {
    position: fixed;
    top: 25%;
    left: 25%;
    transform: translate(-25%, -25%);
    touch-action: none;
    width: 1040px;
    border-radius: 30px;
    z-index: 999;
  }
  .registCall-container {
    background-color: white;
    padding: 20px;
    text-align: left;
    color: #777;
  }
  .registCall-title {
    /* font-size: 28px; */
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }
  .registCall-close {
    width: 30px;
    background-color: #fddc00;
    padding: 8px;
    cursor: pointer;
    /* display: inline-block; */
    float: right;
  }
  .registCallLayout {
    margin-top: 20px;
    width: 100%;
    /* height: 725px;  */
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .registCallLayout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .registCall-Dialog .registCallWrapper > div {
    display: inline-block;
    vertical-align: top;
  }

  /*wrappper */
  .registCallWrapper {
    display: inline-block;
  }
  .registCallWrapper .mainTitle {
    display: inline-block;
    vertical-align: middle;
    font-weight: bold;
    width: 100px;
  }
  /* .mainTitle.sub {
  display: inline-block;
  font-size: 18px;
  vertical-align: middle;
  font-weight: bold;
  margin-left: 10px;
} */
  .registCallLayout .first-child {
    margin-top: 15px !important;
  }
  .registCallLayout .contentBlock {
    margin-top: 15px;
  }
  .contentBlock .ant-form-item {
    margin-bottom: 0px;
  }
  .contentBlock .selectItem {
    display: inline-block;
  }
  .contentBlock .override-select {
    width: 200px;
    font-size: 15px;
  }
  .contentBlock .override-select.branch {
    width: 200px;
    font-size: 15px;
    display: inline-block;
    vertical-align: middle;
  }
  .contentBlock .override-select.fran {
    width: 201px;
    font-size: 15px;
    display: inline-block;
    vertical-align: middle;
  }
  .contentBlock .override-select.time {
    width: 201px;
    font-size: 15px;
    display: inline-block;
    vertical-align: middle;
  }
  .contentBlock .override-input {
    width: 300px;
  }
  .callTab {
    float: right;
    width: 100px;
  }
  .paymentBtn {
    width: 170px;
  }
  /* 왼쪽 wrappper */
  .registCallWrapper.sub {
    vertical-align: top;
    display: inline-block;
    width: 660px;
    padding: 0px !important;
  }

  .registCallWrapper.sub .contentBlock .selectItem {
    width: 540px;
  }
  .registCallWrapper.sub .contentBlock .override-input {
    width: 540px;
  }
  /* 오른쪽 wrappper */
  .registCallWrapper.sub2 {
    vertical-align: top;
    display: inline-block;
    width: 290px;
    margin-left: 20px;
  }

  .registCallWrapper.sub2 .mainTitle {
    display: inline-block;
    vertical-align: middle;
    font-weight: bold;
    width: 120px;
  }
  .registCallWrapper.sub2 .contentBlock .selectItem {
    display: inline-block;
    width: 160px;
  }
  .registCallWrapper.sub2 .contentBlock .override-input {
    display: inline-block;
    width: 160px;
  }
  .registCallWrapper.sub2 .sub-txt {
    width: 280px;
  }
  .contentBlock .override-input.memo {
    /* margin-left: -30px; */
    width: 160px;
  }
  .contentBlock .override-input.price {
    /* margin-left: -30px; */
    width: 160px;
  }
  .contentBlock .override-input.delprice {
    /* margin-left: -30px; */
    width: 160px;
  }
  .registCallWrapper.sub2 .callTab {
    width: 280px;
    height: 32px;
    font-size: 16px;
  }
  .registCallLayout .mapLayout {
    margin-top: 10px;
    height: 380px;
  }

  /* 코인이체 dialog */
  .coinTran-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    z-index: 2;
  }

  .coinTran-content {
    padding: 30px;
    background-color: white;
  }

  .coinTran-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }
  .coinTran-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }
  .coinTran-title-sub {
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    background-color: #222;
    color: #fff;
    height: 32px;
    line-height: 32px;
  }
  .coinTran-inner {
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 5px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .coinTran-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .coinTran-list {
    text-align: center;
    margin-top: 10px;
  }
  .coinTran-list td {
    width: 100px;
    font-size: 16px;
    display: inline-block;
  }
  .coinTran-list .coinTran-place1 {
    display: inline-block;
  }
  .coinTran-list .twl {
    width: 400px;
    display: inline-block;
    line-height: 30px;
  }
  .coinTran-btn-01 {
    margin-top: 20px;
    text-align: center;
  }
  .coinTran-btn-01 .tabBtn {
    margin: 0px !important;
  }
  .coinTran-btn-01 > button {
    height: 40px;
    width: 200px;
    font-size: 18px;
    background-color: #1890ff;
    color: #fff;
    font-size: 20px;
    text-align: center;
    display: inline-block;
    padding: 0px !important;
  }
  .coinTran-list .inputBox-coinTran.sub .ant-input {
    margin-left: -20px;
    width: 200px;
    text-align: right;
  }
  .coinTran-title-02 {
    font-size: 15px;
    font-family: NotoSansBold;
  }
  /* 코인이체모달-antd 수정 */

  .coinTran-Dialog .coinTran-inner .dataTableLayout {
    margin: 0;
  }

  .coinTran-Dialog .ant-table-content thead th {
    background-color: #222 !important;
    padding: 10px;
  }

  /* 가맹점수정 dialog */
  .modifyFran-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    border-radius: 30px;
    z-index: 2;
  }
  .modifyFran-container {
    background-color: white;
    padding: 30px;
    text-align: left;
    color: #777;
  }
  .modifyFran-title {
    /* font-size: 28px; */
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }
  .modifyFran-close {
    width: 30px;
    background-color: #fddc00;
    padding: 8px;
    cursor: pointer;
    /* display: inline-block; */
    float: right;
  }
  .modifyFranLayout {
    margin-top: 20px;
    width: 100%;
    /* height: 510px;  */
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 10px 20px 20px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .modifyFranLayout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  /* 왼쪽 wrappper */
  .modifyFranWrapper {
    display: inline-block;
  }
  .modifyFranTitle {
    display: inline-block;
    font-size: 23px;
    vertical-align: middle;
    font-weight: bold;
    color: #1890ff;
  }
  .modifyFranWrapper .mainTitle {
    display: inline-block;
    /* font-size: 20px; */
    vertical-align: middle;
    font-weight: bold;
    width: 100px;
  }
  .modifyFranWrapper .mainTitle.red {
    display: inline-block;
    font-size: 20px;
    vertical-align: middle;
    font-weight: bold;
    width: 100px;
    color: #1890ff;
  }
  .mainTitle.sub {
    display: inline-block;
    font-size: 18px;
    vertical-align: middle;
    font-weight: bold;
    margin-left: 10px;
  }
  .modifyFranLayout .contentBlock {
    margin-top: 15px;
  }
  .modifyFranWrapper .ant-form-item {
    margin-bottom: 0px;
  }
  .modifyFranWrapper .selectItem {
    display: inline-block;
  }
  .modifyFranWrapper .override-select {
    margin-left: 20px;
    width: 250px;
    font-size: 15px;
  }
  .modifyFranWrapper .override-select.branch {
    margin-left: 20px;
    width: 300px;
    font-size: 15px;
    display: inline-block;
    vertical-align: middle;
  }
  .modifyFranWrapper .override-input {
    margin-left: 20px;
    width: 300px;
  }
  /* 오른쪽 wrappper */
  .modifyFranWrapper.sub {
    vertical-align: top;
    display: inline-block;
    /* width: 340px; */
    margin-top: 36px;
    margin-left: 70px;
  }
  .modifyFranWrapper.sub .mainTitle {
    display: inline-block;
    font-size: 20px;
    vertical-align: middle;
    font-weight: bold;
    width: 75px;
  }
  .modifyFranWrapper.sub .override-input.sub {
    /* margin-left: -30px; */
    width: 300px;
  }
  .modifyFranWrapper.sub .override-input.price {
    /* margin-left: -30px; */
    width: 200px;
    margin-right: 10px;
  }
  .modifyFranWrapper.sub .callTab {
    width: 400px;
    height: 40px;
    font-size: 18px;
    margin-top: 60px;
  }
  /* 하단 랩퍼 */
  .modifyFranWrapper.bot {
    padding-top: 30px;
    display: inline-block;
    float: right;
    margin-right: 13px;
  }
  .modifyFranWrapper.bot .callTab {
    width: 185px;
    height: 40px;
    font-size: 18px;
    /* margin-top: 12px; */
    display: inline-block;
    vertical-align: middle;
  }

  /* 출금 비밀번호 초기화 */
  .updatePassword-inner {
    width: 100%;
    height: 80px;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 5px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .updatePassword-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .updatePassword-inner .twl .coinRider-list {
    margin-left: 0 !important;
  }

  /* 라이더 코인충전 dialog */
  .coinRider-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    z-index: 2;
  }

  .coinRider-content {
    padding: 20px;
    background-color: white;
  }

  .coinRider-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }
  .coinRider-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }
  .coinRider-title-sub {
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    background-color: #222;
    color: #fff;
    height: 32px;
    line-height: 32px;
  }
  .coinRider-inner {
    width: 100%;
    height: 200px;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 5px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .coinRider-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .coinRider-list {
    text-align: center;
  }
  .coinRider-list td {
    width: 100px;
    font-size: 16px;
    display: inline-block;
  }
  .coinRider-list .coinRider-place1 {
    display: inline-block;
  }
  .coinRider-list .twl {
    width: 400px;
    display: inline-block;
    height: 60px;
    line-height: 30px;
  }
  .coinRider-btn-01 {
    margin-top: 20px;
    text-align: right;
  }
  .coinRider-btn-01 .tabBtn {
    margin: 0px !important;
  }
  .coinRider-btn-01 > button {
    height: 40px;
    width: 200px;
    font-size: 18px;
    background-color: #1890ff;
    color: #fff;
    font-size: 20px;
    text-align: center;
    display: inline-block;
    padding: 0px !important;
  }
  .coinRider-list .inputBox-coinRider.sub .ant-input {
    margin-left: -20px;
    width: 300px;
  }

  /*입출금내역 dialog*/

  .riderBank-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    z-index: 2;
  }

  .riderBank-content {
    padding: 30px;
    background-color: white;
  }

  .riderBank-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }

  .riderBank-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .riderBank-inner {
    width: 100%;
    height: 650px;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .riderBank-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .riderBank-list {
    height: 500px;
  }
  .riderBank-list-01 {
    display: inline-block;
    width: 950px;
    text-align: left;
    margin-top: 30px;
  }

  .riderBank-list-01 .riderBank-datepicker {
    display: inline-block;
  }

  .riderBank-list-01 td {
    display: inline-block;
    width: 100px;
    font-size: 25px;
    text-align: center !important;
  }
  .riderBank-list-01 Button {
    margin-left: 30px;
    background-color: #40a9ff;
    color: #fff;
  }

  /* 필터링 dialog */
  .filtering-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .filtering-content {
    padding: 30px;
    background-color: white;
  }

  .filtering-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    display: inline-block;
    vertical-align: middle;
  }

  .filtering-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .filtering-inner {
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px 30px 10px;
    text-align: left;
    margin-top: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .filtering-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .filtering-box {
    border: 1px solid #e0e0e0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    height: 33px;
    margin-left: 5px;
  }

  .filtering-box1 {
    border: 1px solid #e0e0e0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    height: 33px;
    margin-left: 5px;
  }
  .filtering-box-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }
  .voice {
    display: inline-block;
  }
  .filtering-box-wrapper > div:last-child {
    margin-bottom: 0;
  }
  .filtering-name {
    font-size: 14px;
    border-right: 1px solid #e0e0e0;
    padding-right: 10px;
  }
  .filtering-box .ant-checkbox {
    top: 0em !important;
  }

  .filtering-box .ant-checkbox-wrapper {
    display: table;
  }
  .filtering-box .ant-checkbox-group {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .filtering-box .ant-radio-group {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .filtering-box .ant-checkbox-wrapper > span {
    display: table-cell;
    text-align: center;
  }
  .filter-picker {
    width: 140px;
    margin: 0 -16px 0 3px;
  }
  .filter-picker .ant-picker {
    border: none;
  }
  /* .filtering-box .ant-checkbox-inner {
    width: 15px;
    height: 15px;
  } */
  .filtering-box .ant-checkbox-inner:hover {
    display: none;
  }
  .filtering-box .ant-checkbox-inner::after {
    width: 8px;
    height: 8px;
  }
  .filtering-box .ant-checkbox-checked::after {
    width: 100%;
    height: auto;
  }
  .filtering-btn-01 {
    margin-left: -12px;
  }
  .filtering-box .ant-checkbox + span {
    font-size: 14px;
    vertical-align: top;
    padding: 3px 2px 0 3px !important;
  }

  .riderManageBtn {
    margin-left: 5px;
  }

  /*기사관리*/
  /*일차감*/

  .taskScheduler-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    z-index: 999;
  }

  .taskScheduler-content {
    padding: 30px;
    background-color: white;
  }

  .taskScheduler-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }

  .taskScheduler-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .taskScheduler-inner {
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .taskScheduler-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .taskScheduler-inner .ant-table-expanded-row .ant-table-row {
    display: inline-block;
  }

  .listBlock {
    margin-top: 20px;
  }
  .listBlock .ant-btn {
    margin-left: -5px;
  }

  .mainTitle {
    display: inline-block;
    /* font-size: 20px; */
    vertical-align: middle;
    font-weight: bold;
    /* width: 75px; */
  }

  .taskScheduler-btn {
    display: inline-block;
    width: 1098px;
  }

  .taskScheduler-btn-01 {
    text-align: center;
    vertical-align: middle;
    display: inline-block;
  }

  .rtaskScheduler-btn-01 .tabBtn {
    margin: 0px !important;
  }
  .taskScheduler-btn-01 > button {
    height: 32px;
    width: 110px;
    font-size: 14px;
    color: #222;
    text-align: center;
    display: inline-block;
    padding: 0px !important;
  }
  .taskScheduler-btn-02 {
    vertical-align: middle;
    display: inline-block;
  }

  .taskScheduler-btn-02 > button {
    height: 32px;
    width: 110px;
    font-size: 14px;
    color: #222;
    padding: 0px !important;
    display: inline-block;
  }

  .taskScheduler-btn-03 {
    vertical-align: middle;
    display: inline-block;
    float: right;
  }
  .taskScheduler-btn-03 > button {
    height: 40px;
    width: 130px;
    font-size: 18px;
    background: #1890ff;
    color: #fff;
    padding: 0px !important;
    display: inline-block;
  }
  /*일차감 그룹 설정*/

  .taskGroup-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width:650px; */
    z-index: 2;
  }

  .taskGroup-content {
    padding: 30px;
    background-color: white;
  }

  .taskGroup-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }

  .taskGroup-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .taskGroup-inner {
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .taskGroup-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .taskGroup-inner .subTable .ant-table-thead {
    /* display: none; */
  }
  .taskGroup-inner .subTable .ant-table-cell {
    padding: 13px;
  }
  .taskGroup-inner .subTable .ant-table-row {
    display: inline-block;
  }

  .taskGroup-btn {
    display: inline-block;
  }

  .taskGroup-btn-01 {
    text-align: center;
    vertical-align: middle;
    display: inline-block;
  }

  .taskGroup-btn-01 .tabBtn {
    margin: 0px !important;
  }
  .taskGroup-btn-01 > button {
    height: 40px;
    width: 130px;
    font-size: 18px;
    color: #222;
    text-align: center;
    display: inline-block;
    padding: 0px !important;
  }

  .taskGroup-list {
    border: 1px solid red;
    width: 900px;
    display: inline-block;
    margin-top: 20px;
    font-size: 15px;
  }
  .taskGroup-list-01 {
    display: inline-block;
    width: 326.67px;
    height: 32px;
    background-color: #333;
    color: #fff;
    text-align: center;
    line-height: 32px;
  }
  .taskGroup-list-02 {
    display: inline-block;
    width: 173.33px;
    height: 32px;
    background-color: #333;
    color: #fff;
    text-align: center;
    line-height: 32px;
  }
  .taskGroup-list-03-place1 {
    display: inline-block;
    float: right;
  }
  .taskGroup-content .listBlock {
    width: 550px;
  }

  /*일차감 작업등록*/

  .taskWork-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 700px; */
    z-index: 999;
  }

  .taskWork-Dialog2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1500px;
    z-index: 999;
  }

  .taskWork-content {
    padding: 20px;
    background-color: white;
  }

  .taskWork-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }

  .taskWork-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .taskWork-title-sub {
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    background-color: #222;
    color: #fff;
    height: 32px;
    line-height: 32px;
  }

  .taskWork-inner {
    width: 550px;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 5px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .taskWork-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .taskWork-list {
    /* border:1px solid red; */
    /* height:360px; */
  }
  .taskWork-list .twl-text {
    width: 120px;
    font-size: 18px;
    display: inline-block;
    /* border:1px solid blue; */
  }

  .taskWork-list-02 .twl-text {
    width: 110px;
  }

  .taskWork-list-04 .twl-text {
    width: 110px;
  }

  .taskWork-list-01 .useText {
    margin-left: 5px;
  }

  .taskWork-list-01 .selectItem {
    margin: 0;
    vertical-align: middle;
    display: inline-block;
    width: 250px;
  }

  .taskWork-list-01 .taskWorkSub {
    width: 90px;
    font-size: 16px;
    /* display: inline-block; */
    float: left;
    /* border:1px solid blue; */
  }

  .taskWork-list-05 .useBtn {
    margin-left: 10px;
    margin-right: 15px;
  }

  .taskWork-list .taskWork-place1 {
    display: inline-block;
  }

  .taskWork-list .twl {
    width: 100%;
    /* display: inline-block; */
    height: 55px;
    line-height: 55px;
    /* border:1px solid red */
  }

  .twl:nth-child(1) {
    margin-top: -10px;
  }

  .taskWork-place1 .searchRiderInput {
    width: 185px !important;
    margin-left: 0px !important;
  }
  .taskWork-btn-01 {
    margin-top: 20px;
    text-align: center;
  }

  .taskWork-btn-01 .tabBtn {
    margin: 0px !important;
  }
  .taskWork-btn-01 > button {
    height: 40px;
    width: 130px;
    font-size: 18px;
    background-color: #1890ff;
    color: #fff;
    text-align: center;
    display: inline-block;
    padding: 0px !important;
  }

  .taskWork-list-06
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    margin-left: 10px;
  }
  .taskWork-list-04 .inputBox {
    color: #222 !important;
  }
  .taskWork-list .inputBox-taskWork.sub .ant-input {
    /* margin-left: -10px; */
    width: 186.82px;
  }

  /* 일차감 내역 */
  .taskWorkList-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1100px;
    z-index: 999;
  }

  .taskWorkList-content {
    padding: 30px;
    background-color: white;
  }

  .taskWorkList-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }

  .taskWorkList-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .taskWorkList-inner {
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .taskWorkList-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  /* 코인 이력 */
  .coinList-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    z-index: 999;
  }

  .coinList-content {
    padding: 30px;
    background-color: white;
  }

  .coinList-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }

  .coinList-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .coinList-inner {
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .coinList-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  /*기사 그룹관리*/
  .riderGroup-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    z-index: 999;
  }

  .riderGroup-content {
    padding: 30px;
    background-color: white;
  }

  .riderGroup-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }

  .riderGroup-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .riderGroup-inner {
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .riderGroup-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .riderGroup-inner .listBlock {
    margin-top: 20px;
    overflow-y: scroll;
    height: 240px;
  }
  .riderGroup-inner .listBlock .ant-btn {
    margin-left: -5px;
  }

  .riderGroup-inner .mainTitle {
    display: inline-block;
    font-size: 20px;
    vertical-align: middle;
    font-weight: bold;
    /* width: 75px; */
  }

  .riderGroup-ftline {
    line-height: 30px;
    font-size: 18px;
    /* height: 215px; */
    padding: 20px;
    margin-top: 10px;
    /* border:1px solid #ccc; */
  }
  .riderGroup-ftline p {
    display: inline-block;
    width: 100px;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
  .riderGroup-ftline > div {
    margin-bottom: 10px;
  }
  .riderGroup-ftline > div:last-child {
    margin-bottom: 0;
  }
  .riderGroup-ftline td {
    width: 150px;
    display: inline-block;
  }

  .riderGroup-ftline-02 .riderSubtext,
  .riderGroup-ftline-03 .riderSubtext,
  .riderGroup-ftline-04 .riderSubtext {
    margin-left: 20px;
  }
  .riderGroup-ftline-04 {
    display: inline-block;
  }
  .riderGroup-ftline .ant-radio-wrapper {
    font-size: 16px !important;
  }
  .riderGroup-ftline-06 {
    display: inline-block;
  }

  .riderGroup-btn {
    display: inline-block;
    float: right;
  }

  .riderGroup-btn-01 {
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    /* margin-left:20px; */
  }

  .riderGroup-btn-01 .tabBtn {
    margin: 0px !important;
    display: inline-block;
  }
  .riderGroup-btn-01 > button {
    height: 40px;
    width: 130px;
    background: #1890ff;
    color: #fff;
    font-size: 18px;
    text-align: center;
    padding: 0px !important;
  }

  .riderGroup-ftline-01,
  .riderGroup-ftline-05 {
    display: inline-block;
  }

  .riderGroup-ftline-05 .ant-input {
    margin-left: 20px;
  }
  .riderGroup-ftline-05 td {
    margin-left: 35.15px;
  }
  .riderGroup-ftline .inputBox .ant-form-item {
    margin-bottom: 0px;
    display: inline-block;
    vertical-align: middle;
  }
  .riderGText {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    color: #000 !important;
    font-size: 16px;
  }
  .riderGroup-ftline .ant-input {
    padding: 0px !important;
  }

  .riderGroup-ftline .inputBox {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0px !important;
  }

  .riderGroup-ftline .inputBox-rider.sub .ant-input {
    margin-left: -10px;
  }

  .riderGroup-ftline .select-fee-pay-type {
    margin-left: 20px;
  }

  .riderGroup-inner .ant-table-row-level-0-active {
    background-color: #1a1a1a;
  }

  .ant-checkbox-checked:checked .ant-table-row-level-0:active {
    background-color: #fddc00;
  }

  /*기사 그룹관리 라인활성화*/

  .clickRowStyl {
    background-color: #40a9ff;
  }

  /* .riderGroup-inner .ant-table-row-level-0:hover{
  background-color: #40a9ff !important;
}

.riderGroup-inner .clickRowStyl:hover{
  background-color: #40a9ff !important;
  transition: all 10s ease-in-out;
} */

  /*기사 등록*/
  .registRider-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 960px;
    border-radius: 30px;
    z-index: 999;
  }

  .registRider-content {
    background-color: white;
    padding: 30px;
    text-align: left;
    color: #777;
  }
  .registRiderLayout {
    margin-top: 20px;
    width: 100%;
    /* height:500px; */
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 10px 20px 20px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .registRiderLayout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .registRider-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  /*regist rider에 있음*/
  .registFranTitle {
    font-size: 20px;
    vertical-align: middle;
    font-weight: bold;
    color: #1890ff;
    display: flex;
    align-items: center;
  }

  .registRiderTitle-foreign {
    color: #000;
    margin-left: 20px;
    margin-right: 5px;
    font-size: 16px;
  }

  .registRiderTitle-foreign-sub {
    color: #000;
    font-weight: normal;
    font-size: 14px;
    margin-left: 10px;
  }

  .registRider-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .registRiderWrapper {
    display: inline-block;
    vertical-align: top;
    padding-top: 15px;
  }

  .registRiderWrapper .contentBlock {
    margin-top: 15px;
  }

  .registRiderWrapper .ant-form-item-control-input-content {
    width: 260px;
  }

  .registRiderWrapper
    .ant-form-item-control-input-content
    .ant-checkbox
    + span {
    padding-right: 0;
  }

  .registRiderWrapper .mainTitle {
    width: 120px;
  }

  .registRiderCheck {
    display: inline-block;
    height: 31.7px;
  }
  .registRiderCheck .ant-radio-group {
    margin-top: 4px !important;
  }
  .registRiderWrapper .submitBlock {
    margin-top: 20px;
    text-align: right;
  }

  .registRiderWrapper .clearBtn {
    margin-left: 20px;
  }

  .registRiderWrapper .contentBlock .override-input {
    margin-left: 0px;
  }

  .registRiderWrapper .contentBlock .override-select.branch {
    width: 260px !important;
    margin-left: 0px;
  }

  .registRiderWrapper .contentBlock .override-input {
    width: 260px !important;
  }

  /*오른쪽*/
  .registRiderBox {
    display: inline-block;
  }
  .registRiderWrapper.sub {
    display: inline-block;
    margin-left: 50px;
    /* width: 400px; */
  }

  .registRider-Dialog .submitBlock .ant-btn {
    width: 125px;
    height: 40px;
    font-size: 16px !important;
  }

  .giveBox {
    width: 280px;
  }

  .giveBox .ant-checkbox-wrapper {
    width: 90px;
    margin: 0px !important;
  }

  .registRiderWrapper.sub .submitBlock {
    width: 390px;
    /* margin-top: 55px; */
  }
  .fileBtn {
    width: 260px;
  }

  /* 하단 */

  .bike-type-box {
    border-top: 1px solid #ccc;
    padding-top: 30px;
    margin-top: 40px;
  }
  .bike-type-box > div {
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    color: #1890ff;
  }
  .bike-type-box ul {
    padding: 0;
    margin: 0;
  }
  .bike-type-box ul li {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 15px 20px 0 0;
  }
  .bike-type-box ul li:last-child {
    margin-right: 0;
  }
  .bike-type-box ul li p {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0 !important;
  }
  .bike-type-box ul li .selectItem {
    display: inline-block;
    width: 165px;
    margin: 0 0 0 15px;

    vertical-align: 0px;
  }
  /*직원 등록*/

  .registStaff-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    border-radius: 30px;
    z-index: 2;
  }

  .registStaff-content {
    background-color: white;
    padding: 30px;
    text-align: left;
    color: #777;
  }

  .registStaff-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .registStaff-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .registStaffWrapper .mainTitle {
    width: 130px;
  }

  .registStaffWrapper .registStaffCheck {
    display: inline-block;
    margin-left: 22px;
  }

  .registStaff-Dialog .layout {
    margin-top: 20px;
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px 20px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .registStaff-Dialog .layout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .registStaff-Dialog .layout .contentBlock {
    margin-top: 25px;
  }

  .registStaffWrapper .submitBlock {
    margin-top: 30px;
    text-align: center;
  }

  .registStaffWrapper .clearBtn {
    margin-left: 20px;
  }

  .registStaffWrapper .contentBlock .override-select.branch {
    width: 240px;
  }

  .registStaffWrapper .override-select.sub {
    width: 270px !important;
  }

  .registStaffWrapper .contentBlock .override-input {
    width: 240px;
  }
  .registStaffWrapper .ant-radio-group {
    margin-left: 20px;
  }
  .registStaffWrapper span.ant-radio + * {
    padding-left: 5px;
    padding-right: 5px;
  }
  .registStaff-Dialog .ant-btn {
    width: 130px;
    height: 40px !important;
    font-size: 18px !important;
  }

  /*기사 차단*/

  .blackRider-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    border-radius: 30px;
    z-index: 2;
  }

  .blackRider-content {
    background-color: white;
    padding: 30px;
    text-align: left;
    color: #777;
  }

  .blackRider-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .blackRider-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .blackRider-Dialog .layout {
    margin-top: 20px;
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px 20px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .blackRider-Dialog .layout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .blackRiderWrapper .mainTitle {
    width: 130px;
  }

  .blackRiderWrapper .contentBlock {
    margin-top: 25px;
  }

  .blackRiderWrapper .submitBlock {
    margin-top: 30px;
    text-align: center;
  }

  .blackRiderWrapper .clearBtn {
    margin-left: 20px;
  }

  .blackRiderWrapper .submitBlock button {
    height: 40px;
    width: 130px;
    font-size: 18px;
  }
  .blackRiderWrapper .contentBlock .override-select.branch {
    width: 240px !important;
  }

  .blackRiderWrapper .contentBlock .override-input {
    width: 240px !important;
  }

  .blackRiderWrapper .contentBlock .override-input.branch {
    width: 240px !important;
    height: 200px;
  }

  /* 환경설정 */

  .pwChange-root {
    padding: 24px;
  }
  .pwChange-Layout {
    border: 1px solid #ccc;
    padding: 20px;
    width: 40%;
    margin: 0 auto;
  }
  .pwChange-title {
    font-size: 15px;
    font-family: NotoSansBold;
    text-align: center;
    margin-top: 20px;
  }
  .pwChange-box {
    width: 100%;
    padding: 20px;
    text-align: left;
    margin-top: 5px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .pwChange-list {
    text-align: center;
    padding: 5px 0;
  }
  .pwChange-list td {
    width: 110px;
    font-size: 14px;
    display: inline-block;
    text-align: right;
    padding-right: 10px;
  }
  .pwChange-list .twl {
    width: 80px;
    display: inline-block;
    height: 60px;
    line-height: 30px;
  }
  .inputBox-pwChange input {
    width: 250px;
  }
  .pwChange-btn {
    text-align: center;
    padding: 10px 0;
  }

  /* 추천배차 Dialog */

  .forceAllocate-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    border-radius: 30px;
    z-index: 2;
  }

  .forceAllocate-content {
    padding: 30px;
    background-color: white;
  }

  .forceAllocate-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 10px;
    float: left;
  }

  .forceAllocate-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .forceAllocate-inner {
    width: 100%;
    border: 1px solid #ccc;
    padding: 20px;
    margin-top: 5px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    clear: both;
  }

  .forceAllocate-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .forceAllocate-list {
    text-align: left;
    margin: 20px 0;
  }

  .forceAllocate-list span {
    text-align: left;
  }

  .forceAllocate-btn-01 {
    margin-top: 20px;
    text-align: center;
  }

  .forceAllocate-btn-01 .tabBtn {
    margin: 0px !important;
  }
  .forceAllocate-btn-01 > button {
    height: 40px;
    width: 200px;
    font-size: 18px;
    background-color: #1890ff;
    color: #fff;
    font-size: 20px;
    text-align: center;
    display: inline-block;
    padding: 0px !important;
  }

  .forceAllocate-select-btn .ant-checkbox-wrapper {
    margin: 0 !important;
  }

  .forceAllocate-Dialog .ant-table-content thead th {
    padding: 10px;
  }

  .forceAllocate-Dialog .forceAllocate-inner .ant-spin-container .ant-table {
    margin: 0 !important;
  }
  /* 메세지 dialog */

  .message-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 345px;
    z-index: 2;
  }

  .message-content {
    padding: 30px;
    background-color: white;
  }

  .message-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }

  .message-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .message-inner {
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .message-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .selectLayout {
    margin-left: 0;
    margin-right: 1rem;
  }

  /* ModifyOrdeDialog */

  .modifyOrderLayout {
    margin-top: 20px;
    border: 1px solid #ccc;
    padding: 10px 20px 20px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .modifyOrder-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 1000px; */
    border-radius: 30px;
    z-index: 2;
  }
  .modifyOrder-container {
    background-color: white;
    padding: 30px;
    text-align: left;
    color: #777;
  }
  .chat-container {
    width: 440px;
    /* height: 700px; */
    height: 100%;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }
  .chat-page {
    padding: 10px 0px;
  }
  .chat-page > ul > li {
    width: 32px;
    height: 32px;
    line-height: 30px;
    font-size: 13px;
  }
  .chat-page .ant-pagination-options {
    display: block;
    margin-left: 310px;
    margin-top: 10px;
  }
  .chat-page > ul > li a {
    padding: 0;
  }
  .chat-page .ant-select-selector {
    font-size: 12px;
    height: 30px !important;
  }
  /* .chat-list-container{
  height:650px;
  border:1px solid red;
} */
  .chat-title {
    width: 100%;
    background-color: #fddc00;
    padding: 20px;
    text-align: center;
    font-size: 20px;
    letter-spacing: -1px;
    font-family: NotoSansMedium;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .chat-item-container {
    padding: 10px 20px;
    cursor: pointer;
  }
  .chat-item-image {
    display: inline-block;
  }
  .chat-item-image img {
    width: 65px;
  }
  .chat-item-content {
    display: inline-block;
    width: 300px;
    vertical-align: middle;
    padding-left: 20px;
  }
  .chat-item-top {
    width: 100%;
  }
  .chat-item-top-title {
    font-size: 20px;
    letter-spacing: -1px;
    font-family: NotoSansBold;
  }
  .chat-item-top-time {
    float: right;
    font-size: 18px;
    letter-spacing: -1px;
    font-family: NotoSansRegular;
    color: #b1b1b1;
  }
  .chat-item-bottom {
    font-size: 16px;
    letter-spacing: -1px;
    font-family: NotoSansMedium;
    color: #999999;
    width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .chat-message-container {
    /* float: right; */
    padding: 0px;
    width: 440px;
    height: 100%;
    border-radius: 10px;
    background-color: #ededed;
    margin-left: 30px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }
  .chat-input {
    border-radius: 12px;
    border: 1px solid #000;
    margin: 10px;
    height: 55px;
    background-color: #fff;
  }

  .chat-send-input {
    width: 330px;
    font-size: 18px;
    letter-spacing: -1px;
    font-family: NotoSansMedium;
    margin-top: 10px;
    border: 0px !important;
    margin-left: 15px;
  }
  .chat-send-input:focus {
    outline: none;
  }
  .chat-send-btn {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    float: right;
    background-color: #000;
    height: 100%;
    width: 60px;
    padding-top: 10px;
    text-align: center;
    font-size: 18px;
    letter-spacing: -1px;
    font-family: NotoSansMedium;
    color: #fff;
    cursor: pointer;
  }

  .msginput-container {
    padding: 10px;
    background-color: #000;
    border-radius: 7px;
  }

  .msginput-basic {
    margin: 10px;
    padding: 10px;
    background-color: #fbdd01;
    color: #000;
    font-family: NotoSansRegular;
    font-size: 24px;
    letter-spacing: -1px;
    border-radius: 5px;
    cursor: pointer;
  }
  /* 블라인드 */
  .blind-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    border-radius: 30px;
    z-index: 999;
  }
  .blind-container {
    background-color: white;
    padding: 30px 30px 70px 30px;
    text-align: left;
    color: #777;
  }
  .blind-container > div:nth-child(2) {
    display: inline-block;
  }
  .blind_botbox {
    display: inline-block;
  }
  .blind-check {
    display: inline-block;
  }
  .blind-title {
    /* font-size: 28px; */
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
    vertical-align: middle;
  }
  .blind-check-text {
    display: inline-block;
    vertical-align: middle;
  }
  .blind-close {
    width: 30px;
    background-color: #fddc00;
    padding: 8px;
    cursor: pointer;
    /* display: inline-block; */
    float: right;
  }
  .blindLayout {
    margin-top: 20px;
    width: 100%;
    /* height: 350px; */
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .blindLayout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .blindLayout .elipsis-table-row {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 130px;
    display: inline-block;
    vertical-align: middle;
    /* text-align: center; */
  }

  .chat-message {
    height: 500px;
    padding: 20px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
  }
  .chat-message-item {
    margin: 10px 0px;
  }
  .chat-message-item.my {
    text-align: right;
  }
  .chat-message-icon {
    display: inline-block;
  }
  .chat-message-icon img {
    width: 40px;
  }
  .chat-message-content {
    display: inline-block;
    width: 320px;
    margin-left: 10px;
    vertical-align: top;
  }
  .chat-message-content-text {
    background-color: #fff;
    border-radius: 20px;
    padding: 5px 20px;
    color: #000;
    font-family: NotoSansRegular;
    font-size: 16px;
    letter-spacing: -1px;
    display: inline-block;
  }
  .chat-message-content-text.my {
    background-color: #fbdd01;
  }
  .chat-message-content-date {
    text-align: right;
    color: #999999;
    font-family: NotoSansRegular;
    font-size: 14px;
    letter-spacing: -1px;
  }
  .chat-message-content-date.my {
    padding-right: 10px;
  }

  /* 블라인드 */
  .blind-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    border-radius: 30px;
    z-index: 999;
  }
  .blind-container {
    background-color: white;
    padding: 30px 30px 70px 30px;
    text-align: left;
    color: #777;
  }
  .blind-container > div:nth-child(2) {
    display: inline-block;
  }

  .blind-title {
    /* font-size: 28px; */
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
    vertical-align: middle;
  }
  .blind-check-text {
    display: inline-block;
    vertical-align: middle;
  }
  .blind-close {
    width: 30px;
    background-color: #fddc00;
    padding: 8px;
    cursor: pointer;
    /* display: inline-block; */
    float: right;
  }
  .blindLayout {
    margin-top: 20px;
    width: 100%;
    /* height: 350px; */
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .blindLayout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .blindLayout .elipsis-table-row {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 130px;
    display: inline-block;
    vertical-align: middle;
    /* text-align: center; */
  }

  .blindWrapper.bot {
    margin-top: 10px;
    text-align: left;
  }
  .blindWrapper.bot .blindTitle {
    font-size: 20px;
    vertical-align: middle;
    font-weight: bold;
    color: #1890ff;
  }
  .blindWrapper.bot .mainTitle {
    /* font-size: 20px; */
    vertical-align: middle;
    font-weight: bold;
    /* width: 75px; */
  }
  .blindWrapper.bot .subTitle {
    font-size: 18px;
    vertical-align: middle;
    font-weight: bold;
    margin-left: 25px;
    width: auto;
  }

  .blindWrapper.bot .blindWrap-sub1 {
    display: inline-block;
  }
  .blindWrapper.bot .blindWrap-sub2 {
    display: inline-block;
  }
  .blindTab {
    float: right;
    width: 110px;
    height: 40px;
    font-size: 16px !important;
    line-height: 20px;
  }
  .blindWrapper.bot .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }
  .blindWrapper.bot .override-input.sub {
    /* margin-left: -30px; */
    margin-left: 10px;
    width: 135px;
  }
  .blindWrapper.bot .selectItem {
    vertical-align: middle;
  }
  .blindWrapper.bot .override-select {
    margin-left: 10px;
    width: 230px;
    font-size: 15px;
    vertical-align: middle;
  }
  .blindWrapper.bot .callTab {
    width: 185px;
    height: 40px;
    font-size: 18px;
    margin-top: 12px;
    margin-left: 45px;
    display: inline-block;
    vertical-align: middle;
  }
  .blindWrapper.bot .blind_botbox {
    display: inline-block;
  }
  .modifyOrderWrapper {
    display: inline-block;
  }

  .modifyOrderLayout .contentBlock {
    margin-top: 1rem;
  }

  .modifyOrderLayout .mainTitle {
    width: 8rem;
  }

  .modifyOrderBtn {
    margin-top: 3rem;
    text-align: center;
  }

  /* paymentModal */

  .payment-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 1000px; */
    z-index: 999;
    background-color: white;
    padding: 30px;
    text-align: left;
    color: #777;
  }
  .payment-container div {
    display: block;
  }
  .detail-title {
    /* font-size: 28px; */
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    font-size: 25px;
    line-height: 30px;
  }
  .detail-title > div:first-child {
    display: inline-block;
  }
  .detail-title > div:last-child {
    width: 30px;
    height: 30px;
    background-color: #fddc00;
    cursor: pointer;
    float: right;
  }
  .detail-title > div:last-child img {
    width: 14px;
    margin: 4px 8px 8px 8px;
  }
  .detail-content {
    margin-top: 20px;
    width: 100%;
    /* height: 510px;  */
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .detail-content::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .split-content-title {
    font-weight: bold;
    font-size: 18px;
    color: black;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }
  .split-content-title > div:first-child {
    display: inline-block;
  }
  .split-content-title > div:last-child {
    float: right;
    right: 0;
  }
  .split-payment-box {
    padding: 25px 20px 15px 20px;
    width: 410px;
    height: auto;
  }
  .split-payment-box div {
    display: inline-block;
  }
  .split-payment-box > div:first-child {
    margin-bottom: 0;
  }
  .split-bottom-box {
    border-top: 1px solid #ccc;
    font-size: 18px;
    font-weight: bold;
    color: black;
    padding-top: 20px;
    text-align: center;
  }
  .orderPayment-wrapper {
  }
  .orderPayments-wrapper {
    display: flex;
    flex-direction: column;
  }
  .orderPayment-col {
    width: auto;
  }
  .change {
    vertical-align: middle;
    text-align: center;
    width: 100px;
  }
  .dis-input {
    margin-bottom: 10px;
  }

  /*sns전송ㅇ*/
  .snsDialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 800px; */
    border-radius: 30px;
    padding: 20px;
    z-index: 999;
  }

  .snsLayout {
    margin-top: 5px;
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    padding: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
  }

  .snsDialog .container {
    background-color: white;
    padding: 30px;
    text-align: left;
    color: #777;
  }

  .sns-title {
    /* font-size: 28px; */
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }
  .sns-close {
    width: 30px;
    background-color: #fddc00;
    padding: 8px;
    cursor: pointer;
    float: right;
  }
  .snsLayout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .snsLayout .contentBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .snsLayout .mainTitle {
    width: 80px;
  }
  .snsLayout .sns-inputBox {
    width: 100%;
  }
  .snsLayout textarea.ant-input {
    resize: none;
    width: 320px;
    height: 170px;
    overflow-y: scroll;
    color: #000;
    margin-top: 15px;
  }
  .snsLayout textarea.ant-input::placeholder {
    font-size: 14px;
  }
  .snsbtn-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .snsBtn {
    width: 100px;
  }
  .groupLayout {
    width: 320px;
    margin-left: 15px;
  }
  .frgroup-table {
    margin-top: 15px;
  }
  /* sns전송용 그룹추가 Dialog */

  .snsGrouplayout {
    margin-top: 5px;
    width: 100%;
    border: 1px solid #ccc;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    padding: 20px;
    margin-top: 20px;
  }
  .snsGrouplayout .snsGroup-input {
    width: 240px;
  }
  .snsGrouplayout .contentBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .snsGrouplayout .contentBlock:first-child {
    margin-bottom: 15px;
  }
  .snsGrouplayout .mainTitle {
    width: 100px;
  }
  .snsGrouplayout-btn {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  /* paymentDialog */
  .plusBtn {
    width: 30px;
    height: 30px;
    padding: none;
    text-align: center;
    vertical-align: center;
    color: white;
    font-size: 2rem;
    background-color: #fddc00;
    cursor: pointer;
    float: right;
  }
  .minusBtn {
    width: 100px;
    height: 30px;
    padding: none;
    text-align: center;
    vertical-align: center;
    color: white;
    font-size: 2rem;
    background-color: #fddc00;
    cursor: pointer;
  }
  .btnWrapper {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 15px;
  }

  /* 할증 그룹관리 dialog */
  .surchargeGroupList-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 900px;
    z-index: 999;
  }

  .surchargeGroupList-content {
    padding: 30px;
    background-color: white;
  }

  .surchargeGroupList-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }

  .surchargeGroupList-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .surchargeGroupList-inner {
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .surchargeGroupList-inner .elipsis-table-row {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 130px;
    display: inline-block;
    vertical-align: middle;
    /* text-align: center; */
  }

  .surchargeGroupList-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .surchargeGroupList-inner .subTable .ant-table-thead {
    /* display: none; */
  }
  .surchargeGroupList-inner .subTable .ant-table-cell {
    padding: 13px;
  }
  .surchargeGroupList-inner .subTable .ant-table-row {
    display: inline-block;
  }

  .surchargeGroupList-btn {
    display: inline-block;
  }

  .surchargeGroupList-btn-01 {
    text-align: center;
    vertical-align: middle;
    display: inline-block;
  }

  .surchargeGroupList-btn-01 .tabBtn {
    margin: 0px !important;
  }
  .surchargeGroupList-btn-01 > button {
    height: 40px;
    width: 130px;
    font-size: 18px;
    color: #222;
    text-align: center;
    display: inline-block;
    padding: 0px !important;
  }

  .surchargeGroupList-list {
    border: 1px solid red;
    width: 900px;
    display: inline-block;
    margin-top: 20px;
    font-size: 15px;
  }
  .surchargeGroupList-list-01 {
    display: inline-block;
    width: 326.67px;
    height: 32px;
    background-color: #333;
    color: #fff;
    text-align: center;
    line-height: 32px;
  }
  .surchargeGroupList-list-02 {
    display: inline-block;
    width: 173.33px;
    height: 32px;
    background-color: #333;
    color: #fff;
    text-align: center;
    line-height: 32px;
  }
  .surchargeGroupList-list-03-place1 {
    display: inline-block;
    float: right;
  }
  .surchargeGroupList-content .listBlock {
    /* width: 550px; */
  }

  /* 할증 가맹점 추가 dialog */
  .surchargeFr-Dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .surchargeFr-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 650px;
    border-radius: 30px;
    z-index: 2;
  }

  .surchargeFr-content {
    background-color: white;
    padding: 30px;
    /* text-align: left; */
    /* color: #777; */
  }

  .surchargeFr-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .surchargeFr-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .surchargeFr-Dialog .addRiderlayout {
    height: 540px;
  }

  .surchargeFrWrapper .contentBlock {
    margin-top: 30px !important;
  }

  .surchargeFrWrapper .contentBlock .selectItem .override-select {
    width: 345px;
  }

  .surchargeFr-Dialog .dataTableBlock {
    margin-top: 40px;
  }

  .surchargeFrWrapper .submitBlock {
    margin-top: 30px;
    text-align: center;
  }

  /* 할증 그룹 추가 dialog */
  .surchargeGroup-Dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.56);
    z-index: 1;
  }

  .surchargeGroup-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    border-radius: 30px;
    z-index: 2;
  }

  .surchargeGroup-content {
    background-color: white;
    padding: 30px;
    /* text-align: left; */
    /* color: #777; */
  }

  .surchargeGroup-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .surchargeGroupWrapper {
    margin-top: 20px;
  }

  .surchargeGroupWrapper .mainTitle {
    float: left;
  }

  .surchargeGrouplayout .contentBlock .override-input {
    width: 300px;
    float: right;
  }

  .surGroup-input {
    float: right;
    margin-bottom: 30px;
  }

  .surchargeGroup-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .surchargeGroup-Dialog .addGrouplayout {
    height: 100px;
  }

  .surchargeGroupWrapper .submitBlock {
    margin-top: 30px;
    text-align: center;
  }

  .surchargeGroupWrapper .ant-btn-primary {
    width: 130px;
    height: 40px;
    font-size: 18px;
  }

  /* 출금계좌등록 dialog */
  .registAccount-Dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.56);
    z-index: 1;
  }

  .registAccount-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    border-radius: 30px;
    z-index: 2;
  }

  .registAccount-content {
    background-color: white;
    padding: 30px;
    /* text-align: left; */
    /* color: #777; */
  }

  .registAccount-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .registAccount {
    margin-top: 20px;
  }
  .registAccountlayout {
    margin-top: 30px;
  }
  .registAccountlayout .contentBlock {
    width: 350px;
    margin: 10px 0px;
    margin-left: 30px;
  }

  .registAccountlayout .contentBlock .override-input {
    width: 250px;
  }

  .registAccountlayout .contentBlock .override-select {
    width: 250px;
  }

  .registAccount-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .registAccount-Dialog .addGrouplayout {
    height: 100px;
  }

  .registAccountWrapper .mainTitle {
    width: 80px;
  }

  .registAccountWrapper .submitBlock {
    margin-top: 30px;
    text-align: center;
  }

  .registAccountWrapper .ant-btn-primary {
    width: 130px;
    height: 40px;
    font-size: 18px;
  }

  /* 가맹비예외 설정 dialog */
  .duesException-Dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.56);
    z-index: 1;
  }

  .duesException-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 650px;
    border-radius: 30px;
    z-index: 2;
  }

  .duesException-content {
    background-color: white;
    padding: 40px;
    /* text-align: left; */
    /* color: #777; */
  }

  .duesException-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .duesException {
    margin-top: 20px;
  }
  .duesExceptionlayout {
    margin-top: 30px;
  }
  .duesExceptionlayout .contentBlock {
    width: 550px;
    margin: 15px 0px;
    margin-left: 50px;
  }

  .duesExceptionlayout .contentBlock .override-input {
    width: 360px;
  }

  .duesExceptionlayout .contentBlock .override-select {
    width: 360px;
  }

  .duesExceptionlayout .contentBlock .override-input-sub {
    width: 250px;
  }

  .duesExceptionlayout .contentBlock .override-select-sub {
    width: 100px;
    margin-right: 10px;
  }

  .duesException-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .duesException-Dialog .addGrouplayout {
    height: 100px;
  }

  .duesExceptionWrapper .mainTitle {
    width: 120px;
  }

  .duesExceptionWrapper .contentBlock-Table .mainTitle {
    width: 300px;
    padding-left: 20px;
  }

  .duesExceptionWrapper .submitBlock {
    margin-top: 30px;
    text-align: center;
  }

  .duesExceptionWrapper .ant-btn-primary {
    width: 130px;
    height: 40px;
    font-size: 18px;
  }

  /* 채팅 */
  .Modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.56);
    z-index: 990;
  }

  .Modal-chat {
    z-index: 991;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    height: 650px;
    display: flex;
  }

  .Modal-chat .search-wrapper {
    display: flex;
    flex-direction: center;
    /* margin-top: 20px; */
  }

  .Modal-chat .search-wrapper .search-btn {
    width: 50%;
    height: 50px;
    font-size: large;
    background-color: black;
    color: #fddc00;
  }

  .Modal-chat .search-wrapper .ant-btn:hover,
  .Modal-chat .search-wrapper .ant-btn:focus {
    color: #fddc00;
    background: black;
    border-color: none;
  }

  .error-message {
    color: red;
  }

  td.ant-table-column-sort {
    background: none;
  }

  .ant-table-filter-trigger-container-open,
  .ant-table-filter-trigger-container:hover,
  .ant-table-thead
    th.ant-table-column-has-sorters:hover
    .ant-table-filter-trigger-container:hover {
    background: none;
  }
  .ant-table-filter-trigger-container-open .ant-table-filter-trigger,
  .ant-table-filter-trigger:hover {
    color: #bfbfbf;
  }

  .regist-bike-title {
    display: 'inline-block';
    width: 80px;
  }
  .bike-type-box .ant-picker {
    width: 165px;
  }

  .marker-name {
    font-family: NotoSansBold;
  }

  /* 배송지역 모달 */

  .zone-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }

  .zone-content {
    padding: 30px;
    width: 1060px;
    max-height: 80vh;
    background-color: white;
  }

  .zone-content > div:first-child {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    display: inline-block;
    vertical-align: middle;
  }

  .zone-content > img {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }
  .zone-inner {
    width: auto;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 20px;
  }
  .zone-inner > div {
    display: inline-block;
  }
  .inner-left {
    width: 380px;
    vertical-align: top;
    margin-right: 20px;
    display: inline-block;
  }
  .inner-left > div:first-child {
    border-top: none;
    padding-top: 0px;
  }
  .inner-left > div:nth-child(2n) {
    margin-bottom: 20px;
  }
  .inner-right {
    display: inline-block;
  }
  .zone-title {
    font-size: 20px;
    font-weight: bold;
    color: #1890ff;
    border-top: 1px solid #b5b5b5;
    padding-top: 25px;
    margin-bottom: 10px;
  }
  .radio-btn {
    float: right;
  }
  .zone-box-wrap {
    width: 380px;
    padding-top: 10px;
  }
  .zone-box {
    display: inline-block;
    text-align: center;
  }
  .zone-box > div {
    display: inline-block;
  }
  .zone-el {
    width: 83px;
    height: auto;
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 10px 5px;
    border: none;
    margin: 5px;
    font-size: 16px;
    box-shadow: none;
    display: inline-block;
    cursor: pointer;
  }
  .zone-el-new {
    width: 83px;
    height: auto;
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 10px 5px;
    border: none;
    margin: 5px;
    font-size: 16px;
    box-shadow: none;
  }
  .zone-el:hover {
    color: black;
  }
  .zone-el-active {
    width: 83px;
    background-color: black;
    color: #fddc00;
    height: auto;
    border-radius: 5px;
    padding: 10px 5px;
    border: none;
    margin: 5px;
    font-size: 16px;
    box-shadow: none;
    display: inline-block;
    cursor: pointer;
  }

  .sub-zone-active {
    width: 90px;
  }

  .sub-zone {
    width: 90px;
  }
  .zone-input {
    width: 100%;
    display: inline-block;
  }
  .zone-input-title {
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .zoneBtn {
    float: right;
  }
  .regionSurcharge-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  /* .navermap-region {
    width: 550px;
    height: 600px;
    display: inline-block;
  } */

  /* VAN 등록요청 모달 */

  .VAN-content {
    padding: 25px;
    width: auto;
    background-color: white;
  }
  .VAN-content > div:first-child {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    display: inline-block;
    vertical-align: middle;
  }
  .VAN-content > img {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .VAN-txt-sub {
    display: inline-block;
    color: #888;
    padding-left: 130px;
    margin-top: -25px;
    margin-bottom: 10px;
    font-size: 13px;
  }
  .VAN-content-first-box {
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 30px;
    padding-bottom: 10px;
  }
  .VAN-content-first-box > div:first-child {
    width: 130px;
  }
  .VAN-content-first-box > div:nth-child(2) {
    display: inline-block;
  }
  .VAN-content-box {
    display: inline-block;
    width: auto;
    vertical-align: top;
    padding-right: 30px;
  }
  .right {
    padding-right: 50px;
  }
  .VAN-content-block > div:first-child {
    width: 130px;
    display: inline-block;
    font-size: 16px;
    vertical-align: top;
    font-weight: bold;
  }
  .VAN-content-block > div:last-child {
    display: inline-block;
  }
  .VAN-content-block .ant-upload-list-item-name {
    flex: auto;
    padding: 0 8px;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .VAN-btn {
    text-align: right;
    margin-bottom: 20px;
  }
  .VAN-file-input {
    width: 200px;
  }

  .rider-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    z-index: 999;
  }

  .rider-content {
    padding: 30px;
    background-color: white;
  }

  .rider-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }

  .rider-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .rider-inner {
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .rider-inner .ant-table-tbody > tr > td {
    width: 50%;
  }
}
  .rider-Dialog2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    z-index: 999;
  }
  .rider-login-Dialog2 {
    position: fixed;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
    width: 1000px;
    z-index: 999;
  }
  .franchise-Dialog2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    z-index: 999;
  }
  .franchise-login-Dialog2 {
    position: fixed;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
    width: 1000px;
    z-index: 999;
  }

  .rider-content2 {
    padding: 30px;
    background-color: white;
  }

  .rider-title2 {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    display: inline-block;
    vertical-align: middle;
  }

  .rider-close2 {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    float: right;
    padding: 8px;
  }

  .rider-inner2 {
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

.order-route-info-dialog-wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 80%;
  height: 80%;
}

.order-route-info-dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  padding: 1rem 2rem;
  border-bottom: 3px double rgba(0, 0, 0, 0.56);
}

.dialog-close {
  width: 2rem;
  height: 2rem;
  background-color: #fddc00;
  cursor: pointer;
  padding: 5px;
}

.order-route-info-dialog-navermap-wrapper {
  flex: 1;
}

.order-route-info-dialog-navermap {
  width: 100%;
  height: 100%;
}

.searchFtStore-Dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  border-radius: 30px;
  z-index: 1000;
}
.searchFtStore-Dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.56);
  z-index: 1000;
}
.searchFtStore-row {
  cursor: pointer;
}

.surcharge-close {
  width: 30px;
  background-color: #fddc00;
  padding: 8px;
  cursor: pointer;
  float: right;
}

/* flex 모달 css */
.flex-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.56);
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 1.8rem;
  position: fixed;
  max-height: 85vh;
  max-width: 95vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: auto;
}

.flex-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.flex-inner {
  flex: 1;
  display: flex;
  justify-content: left;
  margin-top: 1.25rem;
  padding: 1.25rem;
  border: 1px solid #ccc;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-inner::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
  overflow-y: scroll;
}

.dialog-inner {
  display: flex;
  flex-direction: row;
}

.contentBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mainText {
  display: inline-block;
  font-size: 1.2rem;
  vertical-align: middle;
  font-weight: bold;
}

.mainText-blue {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: bold;
  color: #1890ff;
}

.subText {
  display: inline-block;
  font-size: 1rem;
  vertical-align: middle;
  font-weight: bold;
  width: 5rem;
}

/*주문 공유 설정*/

.order-share-icon-on {
  width: 31%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #fddc00;
  cursor: pointer;
  margin: 0.3rem;
  padding: 0.8rem;
  font-size: 1rem;
  border-radius: 0.3rem;
}

.order-share-icon-off {
  width: 31%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  cursor: pointer;
  margin: 0.3rem;
  padding: 0.8rem;
  font-size: 1rem;
  border-radius: 0.3rem;
}

.share-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.share-btn button {
  width: 110px;
  height: 40px;
  font-size: 16px;
}

/* 시간지연 dialog */

.timeDelay-inner {
  flex-direction: column;
}
.timeDelay-box {
  flex-direction: row;
  padding-top: 0.5rem;
}

/* .timeDelay-box.desk {
  margin: 0 auto;
  justify-content: 0;
  flex-direction: row;
  padding-top:.5rem;
} */

.timeDelay-box > .ant-btn:hover {
  color: #000;
  border: #000 1px solid;
}

.timeDelay-box > button {
  width: 17.8%;
  height: auto;
  border: 1px solid #777;
  border-radius: 0.7rem;
  margin-left: 0.7rem;
  margin-bottom: 0.7rem;
}

.timeDelay-box > .timeDelay-box-on {
  padding: 5px;
  background-color: #b5b5b5;
  opacity: 0.5;
  margin: 1%;
}

.timeDelay-box > .timeDelay-box-off {
  background-color: #fddc00;
  opacity: 0.9;
  padding: 5px;
  margin: 1%;
}

.timeDelay-box > .ant-btn:active {
  color: none;
  border: 1px solid #777;
}

.timeDelay-box > .ant-btn:focus {
  border: 1px solid #777;
  color: black;
}

.timeDelay-btn {
  display: inline-block;
  vertical-align: middle;
  height: 2.5rem;
  line-height: 2.5rem;
}

.timeDelay-btn-01 {
  width: 5rem;
  color: #000;
  text-align: center;
  font-size: 1.1rem;
  display: inline-block;
}

.timeDelay-btn .ant-checkbox .ant-checkbox-checked {
  margin-top: -0.7rem;
  height: 1.4rem;
}
.timeDelay-btn .ant-checkbox-input {
  margin-top: -0.7rem;
  height: 1.4rem;
}

.timeDelay-btn .ant-checkbox-inner {
  width: 1.25rem;
  height: 1.4rem;
  display: inline-block;
  vertical-align: top;
  margin-top: -0.7rem;
}

.timeDelay-btn .ant-checkbox-inner::after {
  width: 0.7rem;
  height: 0.7rem;
}

.timeDelay-btn-02 {
  display: inline-block;
  float: right;
}

.timeDelay-btn-02 > button {
  width: 8rem;
  height: 2.5rem;
  font-size: 1.1rem;
  background: #40a9ff;
  color: #fff;
  text-align: center;
  padding: 0px !important;
  display: inline-block;
}

.timeDelay-btn-02 .timeDelay-btn {
  margin: 0px !important;
}

.timeDelay-box .anticon {
  width: 1.2rem;
}
/* 할증 dialog */

.surcharge-box {
  flex-direction: column;
  /*justify-content: space-around;*/
  padding-top: 0.5rem;
}

.surcharge-box::-webkit-scrollbar {
  display: none;
}

.surcharge-box .ant-checkbox-wrapper {
  margin-left: 5px !important;
  vertical-align: middle;
}

.surGroup-btn {
  flex-direction: column;
  margin-top: 0.5rem;
}

.listTable {
  margin-top: 1.3rem;
}

.listTable .ant-btn {
  margin-left: -0.3rem;
}

.surcharge-inner .contentBox {
  margin-top: 1rem;
}

.surcharge-inner .contentBox > div {
  display: flex;
  align-items: center;
}
.surcharge-inner .contentBox > div .checkboxInput {
  display: flex;
  align-items: center;
  width: 17rem !important;
  margin-left: 0.7rem !important;
}

.insertBox {
  margin-top: 1.2rem;
  padding-top: 1.2rem;
  border-top: 1px solid #644d4d;
}

.inputB {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  width: 280px;
}

.inputB .ant-form-item {
  margin-bottom: 0px;
  margin-left: 0.7rem;
  margin-right: 0.7rem;
}
.dateBox {
  display: inline-block;
  margin-left: 0.7rem;
  font-size: 1.1rem;
  vertical-align: middle;
  border-radius: 0.7rem;
}

.dateBox .ant-form-item {
  margin-bottom: 0px;
}
.dateBox .selectItem {
  display: inline-block;
}
.dateBox .override-select {
  margin-left: 1.2rem;
  width: 15rem;
  font-size: 1rem;
}

.dateBox .ant-picker-input > input {
  text-align: center;
  width: 8rem;
}

.priceText {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.1rem;
}

.radioBox {
  margin-top: 0.7rem;
}

.radioBox .searchRequirement {
  margin-right: 3rem;
  margin-left: 0.7rem;
  margin-top: -0.1rem !important;
  vertical-align: top;
}

.radioBox .search-input {
  display: inline-block;
  margin-left: 2rem;
}

.radioBox .selectItem {
  display: inline-block;
  margin-left: 1rem;
}
.radiosubtext {
  margin-top: 0rem !important;
}

.radioBox .override-input.sub {
  text-align: center;
  display: inline-block;
  margin-left: 2rem;
}
.checkBox-B .selectItem {
  display: flex;
  font-size: 1.1rem;
  align-items: center;
}
.btnSubmit {
  float: right;
  margin-top: 0px;
}

.btnSubmit .ant-btn {
  width: 130px;
  font-size: 1.1rem;
  border-radius: 2px;
  padding: 0px;
  height: 40px;
  margin-top: 1.2rem;
  float: right;
}

/* 배송지역 모달 (지역할증, 배송가능지역) */

.zoneBox-inner-left {
  vertical-align: top;
  margin-right: 1.2rem;
  display: inline-block;
}

.zoneBox-inner-left > div:first-child {
  border-top: none;
  padding-top: 0px;
}

.zoneBox-inner-left > div:nth-child(2n) {
  margin-bottom: 1.2rem;
}

.zoneBox-inner-right {
  display: inline-block;
}

.zone-text {
  font-size: 1.2rem;
  font-weight: bold;
  color: #1890ff;
  border-top: 1px solid #b5b5b5;
  padding-top: 1.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.zone-radio-btn {
  float: right;
}

.zoneBox-wrap {
  width: 23.75rem;
  padding-top: 0.7rem;
  overflow-y: scroll;
  height: 200px;
}
.zone-box {
  display: inline-block;
  text-align: center;
}

.zone-box > div {
  display: inline-block;
}

.zone-b {
  max-width: 6.8rem;
  height: auto;
  background-color: #f5f5f5;
  border-radius: 0.3rem;
  padding: 0.3rem 0.5rem;
  border: none;
  margin: 0.3rem;
  font-size: 1rem;
  box-shadow: none;
  display: inline-block;
  cursor: pointer;
}
.zone-b-new {
  width: 5.2rem;
  height: auto;
  background-color: #f5f5f5;
  border-radius: 0.3rem;
  padding: 0.7rem 0.3rem;
  border: none;
  margin: 0.3rem;
  font-size: 1rem;
  box-shadow: none;
}
.zone-b:hover {
  color: black;
}
.zone-b-active {
  max-width: 6.8rem;
  height: auto;
  background-color: #000;
  color: #fddc00;
  border-radius: 0.3rem;
  padding: 0.3rem 0.5rem;
  border: none;
  margin: 0.3rem;
  font-size: 1rem;
  box-shadow: none;
  display: inline-block;
  cursor: pointer;
}

.sub-zone-active {
  width: 5.6rem;
}

.sub-zone {
  width: 5.6rem;
}
.zone-input {
  width: 100%;
  display: inline-block;
}
.zone-input-title {
  font-size: 1rem;
  margin-bottom: 0, 7rem;
  font-weight: bold;
}
.zone-btn {
  float: right;
}
.regionSurcharge-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navermap-region {
  width: 80rem;
  height: 50rem;
  display: inline-block;
}

/*가맹점관리 모달창부분 */

/* 가맹점등록 dialog */

.registFran-box {
  justify-content: space-around;
  padding-top: 0.5rem;
}

.registFranTitle {
  text-align: left;
}

/* 가맹점등록- 왼쪽  */
.registFranWrapper {
  display: inline-block;
  vertical-align: top;
}
.registFranWrapper .subText {
  vertical-align: top;
  width: 7rem;
  line-height: 32px;
}

.registFranWrapper .contentBox {
  margin-top: 1rem;
}

.registFranWrapper .ant-form-item {
  margin-bottom: 0px;
}
.registFranWrapper .selectItem {
  display: inline-block;
}

.registFranWrapper .override-input {
  width: 20rem;
}
.registFranWrapper .override-input2 {
  width: auto;
}
.registFranWrapper .addrBtn {
  width: 8.7rem;
}

.registFranWrapper .override-input.addrText {
  width: 11.3rem;
}

.registFranWrapper.sub .datepicker {
  width: 20rem;
}

.registFranWrapper.sub .datepicker2 {
  width: 14rem;
}

/* 가맹점등록- 오른쪽 */
.registFranWrapper.sub {
  display: inline-block;
  margin-left: 2rem;
}

.registFranWrapper.sub .override-input.orderman {
  width: 11.3rem;
}
.registFranWrapper.sub .override-input.searchRiderBtn {
  width: 7.5rem;
  margin-left: 1.2rem;
}
.registFran-btn {
  float: right;
  margin-top: -1rem;
  margin-bottom: 1rem;
}

/* 하단 랩퍼 */
/* .registFranWrapper.bot {
  width: 100%;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #b5b5b5;
}
.registFranTitle {
  font-size: 20px;
  vertical-align: middle;
  font-weight: bold;
  color: #1890ff;
  display: block;
}
.registFranWrapper.bot .mainTitle {
  vertical-align: middle;
  font-weight: bold;
  width: 75px;
}
.registFranWrapper.bot .subTitle {
  font-size: 18px;
  vertical-align: middle;
  font-weight: bold;
}
.registFranWrapper.bot .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}
.registFranWrapper.bot .override-input.sub {
  margin-left: 10px;
  width: 150px;
}
.registFranWrapper.bot .selectItem {
  vertical-align: top;
  margin-right: 30px;
}
.registFranWrapper.bot > .contentBlock {
  display: inline-block;
}
 */

/* 주소검색관리 */

.searchAddr-box {
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 0.5rem;
  max-width: 100%;
}

.searchAddr-inner {
  flex-direction: column;
}
.searchAddressWrapper {
  display: flex;
}

.searchAddress-name {
  width: 7rem;
}
.searchAddr-inner .searchAddr-innerBox {
  width: 28rem;
  vertical-align: top;
}
.searchAddr-innerBox .override-input {
  margin-bottom: 0;
}
.searchAddr-innerBox .contentBox {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.searchAddr-innerBox .contentBox .subText {
  margin-right: 10px;
}
.searchAddress-btn .addrBtn {
  width: 7.5rem !important;
  margin-left: 1.2rem;
}
.contentBox .addrBtn span {
  font-size: 0.8rem;
}
.contentBox .short {
  width: 13.2rem !important;
}
.searchAddr-innerBox .selectItem {
  width: 100%;
}
.searchAddress-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.searchAddress-btn .ant-btn-primary {
  background-color: black;
  border-style: none;
}

.third {
  display: inline-block;
}

.searchRequirement {
  margin-top: 0 !important;
  margin-left: 20px;
}

.searchAddr {
  width: 25rem;
  margin-left: 1.2rem;
  vertical-align: middle;
  display: inline-block;
}
.dataTableLayout-03 {
  width: 100%;
  margin-top: 24px;
}
/*체크안된곳*/
.ant-table-content thead th {
  padding: 10px;
}

.layout .ant-table-tbody > tr > td {
  padding: 0px;
}
.layout .pwChange-btn {
  padding: 2px;
}

.mainTitle.sub {
  display: inline-block;
  font-size: 18px;
  vertical-align: middle;
  font-weight: bold;
  margin-left: 10px;
}

.map-address-Dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  width: 130%;
  height: 100%;
  border-radius: 0;
  z-index: 5;
}
.map-address-Dialog .map-close {
  margin: 10px;
}
.map-address-Dialog .mapLayout {
  overflow: hidden;
}
.map-address-btn {
  float: right;
  margin: -70px 460px;
}
.searchAddress-btn .map-address-btn .map-btn .ant-btn-primary {
  padding: 5px 15px;
}
.contentBlock .search-address-Dialog {
  width: 540px;
}
.search-address-Dialog-scroll {
  width: 640px;
  margin-left: 0px;
}
/* 필수서류제출모달 */
.fileUpload-content-block {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.fileUpload-content-block-underline {
  margin-bottom: 20px;
  background-color: #f5f5f5;
  /* border: 1px solid #ccc; */
  padding: 20px 0;
  text-align: center;
}
.fileUpload-content-block > div:first-child {
  width: 180px;
  display: inline-block;
  font-size: 16px;
  vertical-align: middle;
  font-weight: bold;
}
.fileUpload-content-block > div:last-child {
  display: inline-block;
}
.fileUpload-content-block .ant-upload-list-item-name {
  flex: auto;
  padding: 0 8px;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fileUpload-content-block .ant-form-item {
  width: auto;
  margin-bottom: 0;
}
.fileUpload-riderName {
  font-size: 16px;
  font-weight: bold;
}
.fileUpload-riderName span {
  margin-left: 20px;
}
/*메뉴권한설정*/

.systemKey-Dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 1000px; */
  z-index: 1000;
}

.systemKey-content {
  padding: 30px;
  background-color: white;
}

.systemKey-title {
  font-size: 25px;
  font-weight: bold;
  line-height: 30px;
  /* width:300px; */
  display: inline-block;
  vertical-align: middle;
}
systemKey-inner .systemKey-close {
  width: 30px;
  cursor: pointer;
  background-color: #fddc00;
  /* display: inline-block; */
  float: right;
  padding: 8px;
}

.systemKey-inner {
  width: 100%;
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 20px;
  text-align: center;
  margin-top: 20px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.systemKey-inner::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.systemKey-inner-box {
  height: 100px;
  border: 1px solid black;
}

.create-control-form-item {
  display: inline-block;
  text-align: left;
}

.create-control-form-sub {
  width: 50%;
  display: inline-block;
  text-align: left;
}

.create-control-form-sub2 {
  width: 50%;
  display: inline-block;
  text-align: left;
  vertical-align: top;
}

.create-control-form-item-title {
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  width: 80px;
}

.create-control-form-item-title2 {
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  width: 80px;
}

.create-control-form-item-auth {
  padding-left: 20px;
  text-align: left;
  border: 1px solid;
  border-radius: 10px;
}

.create-control-form-button {
  width: 100%;
  background-color: #fddc00;
  color: #1a1a1a;
  border-color: #696968;
}

.payablePoopPoint-main-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  width: 100px;
  color: #333;
}

.payablePoopPoint-main-title-gray {
  color: #a6a6a6;
}

.selPoopPoint {
  display: inline-block;
  margin-left: 40px;
}

.payablePoopPoint-sub-title {
  letter-spacing: -1px;
  color: #333;
  text-align: left;
  font-size: 15px;
  line-height: 40px;
}

.payablePoopPoint-sub-title-gray {
  letter-spacing: -1px;
  color: #a6a6a6;
  text-align: left;
  font-size: 15px;
  line-height: 40px;
}

.payablePoopPoint-content {
  display: inline-block;
  text-align: right;
  font-size: 15px;
  width: 10%;
  color: #333;
}

.payablePoopPoint-content-gray {
  display: inline-block;
  text-align: right;
  font-size: 15px;
  width: 10%;
  color: #a6a6a6;
}

.padding10 {
  padding: 10px;
}

.registDongRate-Dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.56);
  z-index: 1;
}

.registDongRate-Dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  border-radius: 30px;
  z-index: 2;
}

.registDongRate-content {
  background-color: white;
  padding: 30px;
  /* text-align: left; */
  /* color: #777; */
}

.registDongRate-title {
  letter-spacing: -1px;
  font-weight: bold;
  color: #333;
  text-align: right;
  display: inline-block;
  font-size: 25px;
  line-height: 30px;
}

.registDongRate-close {
  width: 30px;
  cursor: pointer;
  background-color: #fddc00;
  /* display: inline-block; */
  float: right;
  padding: 8px;
}

.registDongRatelayout {
  margin-top: 30px;
}

.registDongRateWrapper .mainTitle {
  width: 80px;
}

.registDongRateWrapper .submitBlock {
  margin-top: 30px;
  text-align: center;
}

.registDongRateWrapper .ant-btn-primary {
  width: 130px;
  height: 40px;
  font-size: 18px;
}
.zeroCallFrMemoHistory::placeholder {
  color: black;
}

.insurance-menu {
  display: inline-block;
  margin: 0px 15px;
  padding: 0px 15px;
  cursor: pointer;
  font-family: NotoSansRegular;
  font-size: 18px;
  color: #f5f0d5;
  font-weight: normal;
}

.insurance-menu.active {
  color: #fddc00;
  border-bottom: solid #fddc00;
}

.download-btn {
  vertical-align: top;
  height: 33px;
  padding: 0 20px;
}

.download-btn img {
  width: 18px;
  margin: 0 5px 2px 0;
}

.deposit-Dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 1000px; */
  z-index: 101;
}

.deposit-Dialog2 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1500px;
  z-index: 2;
}

.deposit-content {
  padding: 30px;
  background-color: white;
}

.deposit-title {
  font-size: 25px;
  font-weight: bold;
  line-height: 30px;
  /* width:300px; */
  display: inline-block;
  vertical-align: middle;
}

.deposit-close {
  width: 30px;
  cursor: pointer;
  background-color: #fddc00;
  /* display: inline-block; */
  float: right;
  padding: 8px;
}

.deposit-inner {
  width: 100%;
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 20px;
  text-align: left;
  margin-top: 20px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.deposit-inner::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera*/
}

.deposit-inner-box {
  height: 100px;
  border: 1px solid black;
  padding: 10px;
}

.deposit-inner .mainTitle {
  width: 100px;
}

.serach-input {
  display: inline-block;
  margin-bottom: 24px;
  padding-left: 24px;
}

.serach-input > .ant-input {
  display: inline-block;
  width: 230px;
}

.deposit-box {
  display: inline-block;
  margin-left: 20px;
}

.deposit-box:nth-child(3) > .box-title {
  color: red;
}

.dbt {
  font-weight: bold;
}

.dbc {
  background-color: #ffde5b;
  border-radius: 10px;
  padding: 5px 8px;
}

.box-title {
  padding: 0 10px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin-bottom: 3px;
}

.box-content {
  font-weight: bold;
  display: inline-block;
  font-size: 20px;
  vertical-align: middle;
  margin-bottom: 5px;
}

.box-sub {
  display: inline-block;
  font-size: 15px;
  margin-left: 3px;
}

.deposit-formItem {
  display: inline-block;
  vertical-align: top;
}

/*일괄블라인드 css 추가 hms*/
.blindAllWrapper.bot {
  margin-top: 5px;
  text-align: left;
}

.blindWrapper.bot .allblindWrap {
  margin-top: -10px;
  margin-left: -24px;
}

.blindWrapper.bot .allblindWrap-sub {
  margin-left: -24px;
}

.blindAllTab {
  float: right;
  width: 110px;
  height: 40px;
  font-size: 16px !important;
  line-height: 20px;
  margin-left: 110px;
}

.blindRiderAllTab {
  float: right;
  width: 110px;
  height: 40px;
  font-size: 16px !important;
  line-height: 20px;
  margin-left: 110px;
  margin-top: -56px;
}

.notice-detail-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  border-radius: 30px;
  padding: 20px;
  z-index: 1000;
}

.notice-detail-content {
  background-color: white;
  padding: 30px;
  text-align: left;
  color: #777;
}

.notice-detail-title {
  letter-spacing: -1px;
  font-weight: bold;
  color: #333;
  text-align: right;
  display: inline-block;
  font-size: 25px;
  line-height: 30px;
}

.notice-detail-close {
  width: 30px;
  background-color: #fddc00;
  padding: 8px;
  cursor: pointer;
}

.notice-detail-categoryTitle {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  margin-top: 20px;
  margin-bottom: 5px;
}

.notice-detail-titlearea {
  border: 1px solid grey;
  border-radius: 3px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 10px;
  padding: 10px;
}

.notice-detail-textarea {
  border: 1px solid grey;
  border-radius: 3px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 10px;
  padding: 10px;
  height: 15rem;
  overflow-y: scroll;
}

.notice-detail-page-btn {
  border: 2px solid grey;
  border-radius: 30px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.notice-detail-list-btn {
  border-radius: 30px;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  background-color: #1890ff;
  color: white;
  font-weight: bold;
}

.receive-notice-table-row {
  cursor: pointer;
}


/* 예치금관리 모달 */
.Dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.56);
  z-index: 100;
}
.DialogBackground{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.settlementDialog{
  background-color: #fff;
  min-width: 1200px;
  height: 80vh;
  border: 1px solid black;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}
.settle-title-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #c1c1c1;
}
.settle-title-wrap2 {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #c1c1c1;
}
.settle-search-wrap{
  display: flex;
  align-items: center;
  font-size: 16px;
  margin: 20px 20px 0px 20px;
  justify-content: space-between;
}
.settle-title-content{
  display: flex;
  align-items: center;
  font-size: 24px;
  font-family: 'NotoSansBold';
}
.settle-title-sub{
  margin-left: 24px;
  background-color: #eee;
  border-radius: 5px;
  padding: 10px;
  font-size: 12px;
}
.settle-title-sub > span:first-child::after {
  color :#c1c1c1;
  contnet: "|";
}
.elem-box{
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.elem-box input:hover,
.elem-box input:active,
.elem-box input:focus,
.elem-box .ant-select-selector:hover,
.elem-box .ant-select-selector:active,
.elem-box .ant-select-selector:focus,
.elem-box .ant-picker:hover,
.elem-box .ant-picker:active,
.elem-box .ant-picker:focus,
.elem-box .ant-picker-focused{
  border: 0;
  box-shadow: none;
}
.elem-box .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0;
}
.settle-menu-wrap{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 20px 0 20px;
  justify-content: space-between;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
}
.settle-menu-tab{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #c1c1c1;
  height: 40px;
  flex:1;
  cursor: pointer;
}
.settle-menu-tab:last-child {
  border-right: 0;
}
.settle-menu-tab.active{
  background-color: #FADC00;
  color: #000;
  font-family: 'NotoSansBold';
}
.settle-menu-tab.active:first-child{
  border-radius: 5px 0 0 5px;
}
.settle-menu-tab.active:last-child {
  border-radius: 0 5px 5px 0;
}
.settle-search-wrap .ant-checkbox-group-item {
  margin-right: 0
}
.settle-search-wrap .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #eee;
}
.settle-search-wrap .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FADC00;
  border: 1px solid #C1C1C1;
  color: #000;
}
.settle-search-wrap .ant-checkbox-checked .ant-checkbox-inner:after{
  position: absolute;
  display: table;
  border: 2px solid #000;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%,-50%);
  opacity: 1;
  transition: all .2s cubic-bezier(.12,.4,.29,1.46) .1s;
  content: " ";
}
.settlementDialog .ant-table-wrapper .ant-pagination-options{
  display: none;
}
.settlementDataTable{
  overflow-y: scroll;
}
.settlementDataTable .ant-table-tbody > tr > td{
  border-bottom: 1px solid #2e2e2e;
  border-right: 1px solid #2e2e2e;
  font-size: 16px;
  padding: 10px 0 !important;
}
.settlementDataTable .ant-table-tbody > tr > td:last-child{
  border-right: 0px;
}
.settlementDataTable .settlementDataTable-row{
  background-color: #eee;
}
.cursor {
  cursor: pointer;
}
.settle-pagination {
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 16px 0;
}
.settle-pagination > button {
  border: none;
  background-color: #fff;
  margin: 0 5px;
}
.settle-pagination .ant-btn[disabled] {
  background-color: #fff;
}
.taxReturn-Dialog{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background-color: #fff;
  width: 400px;
  height: 300px;
  border: 1px solid black;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.taxReturn-Dialog-btn{
  width: 170px;
  height: 50px;
}

.disable-drag-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
