@media screen and (max-width: 1199px) and (min-width: 300px) {
  .desk {
    display: none !important;
  }

  .mobile {
  }

  .Dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.56);
    z-index: 2;
  }
  .Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 720px;
    border-radius: 30px;
    z-index: 2;
  }

  .contentBlock:nth-child(1) {
    margin-top: 0px !important;
  }

  /*헤더*/
  .ham-menu {
    float: left;
    margin-top: 10px;
    cursor: pointer;
  }
  .header-logo {
    text-align: center;
    line-height: 45px;
  }
  .header-logo img {
    width: 110px;
    margin-left: -32px;
  }
  .slide-menu-overaly {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.56);
    z-index: 10;
  }
  .slide-menu {
    width: 280px;
    height: 100%;
    font-size: 16px;
  }

  /* 가맹점조회 */
  .searchFranchise-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75vw;
    z-index: 30;
  }

  .searchFranchise-Dialog .layout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .frNameTag {
    cursor: pointer;
    line-height: 32px;
    font-size: 16px;
  }
  .frNameTag:nth-child(odd) {
    cursor: pointer;
    background-color: #efefef;
  }

  .riderNameTag {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }

  .selMulti {
    margin-left: 20px !important;
  }

  .searchFranchise-content {
    background-color: white;
    padding: 15px;
    text-align: left;
    color: #777;
  }

  .searchFranchise-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .searchFranchise-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }
  .searchFranchise-Dialog .layout {
    margin-top: 20px;
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .searchFranchise-Dialog .layout .contentBlock {
    display: inline-block;
    line-height: 20px;
  }

  .searchFranchise-Dialog .layout .contentBlock span {
    font-size: 18px;
  }

  .searchFranchise-Dialog .layout .submitBtn {
    float: right;
  }

  .searchFranchise-list {
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
  }
  .searchFranchise-list input {
    width: 200px;
    border: 1px solid red;
  }

  .searchFranchise-Dialog .ant-table-thead > tr > th {
    background-color: black !important;
    color: white !important;
  }
  .searchFranchise-btn {
    display: inline-block;
    vertical-align: bottom;
  }

  .searchFranchise-btn .ant-btn-primary {
    background-color: black;
    border-style: none;
  }
  .searchFranchise-searchbox {
    float: right;
    margin: 20px 0;
  }

  .searchFranchise-Dialog .layout .dataTableLayout-01 {
    margin-top: 20px;
  }
  .searchFranchise-Dialog .layout .ant-table-tbody > tr > td {
    padding: 0 !important;
  }
  .inputBox-searchFranchise .searchRiderInput {
    width: 60% !important;
    display: inline-block;
    margin-left: 10px !important;
  }
  .inputBox-searchFranchise .searchFranchiseInput {
    width: 60% !important;
    margin-left: 10px;
    display: inline-block;
  }

  /* 우편번호 팝업 */
  .postCode-Dialog {
    position: fixed;
    top: 20%;
    left: 48%;
    transform: translate(-50%, -50%);
    width: 320px;
    border-radius: 30px;
    z-index: 3;
  }

  .postCode-Dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .postCode-close {
    width: 30px;
    position: absolute;
    right: 10px;
    top: -20px;
    cursor: pointer;
    background-color: #fddc00;
    padding: 8px;
  }

  /* 직원 수정 dialog */
  .updateStaff-Dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .updateStaff-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 530px;
    border-radius: 30px;
    z-index: 2;
  }

  .updateStaff-content {
    background-color: white;
    padding: 30px;
    text-align: left;
    color: #777;
  }

  .updateStaff-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .updateStaff-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .updateStaffWrapper .mainTitle {
    width: 130px;
  }

  .updateStaffWrapper .updateStaffCheck {
    display: inline-block;
    margin-left: 22px;
  }

  .updateStaff-Dialog .layout {
    margin-top: 20px;
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px 20px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .updateStaff-Dialog .layout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .updateStaff-Dialog .layout .contentBlock {
    margin-top: 25px;
  }

  .updateStaffWrapper .submitBlock {
    margin-top: 30px;
    text-align: center;
  }

  .updateStaffWrapper .clearBtn {
    margin-left: 20px;
  }

  .updateStaffWrapper .contentBlock .override-select.branch {
    width: 240px;
  }

  .updateStaffWrapper .override-select.sub {
    width: 270px !important;
  }

  .updateStaffWrapper .contentBlock .override-input {
    width: 270px;
  }
  .updateStaffWrapper .ant-radio-group {
    margin-left: 20px;
  }
  .updateStaffWrapper span.ant-radio + * {
    padding-left: 5px;
    padding-right: 5px;
  }
  .updateStaff-Dialog .ant-btn {
    width: 130px;
    height: 40px !important;
    font-size: 18px !important;
  }

  /* 지도 관제 dialog */
  .map-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85vw;
    height: 85vh;
    z-index: 5;
  }

  .map-Dialog .mapLayout {
    width: 85vw;
    height: 80vh;
  }

  .map-Dialog .mapLayout .map-navermap {
    width: 100%;
    height: 100%;
  }

  .map-Dialog .layout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .assign-rider-btn {
    position: absolute;
    top: 10%;
    left: 24.95%;
  }
  .map-menu-bar {
    width: 100%;
    background-color: #fff;
    height: 56px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .map-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }

  .map-close {
    z-index: 90;
    width: 30px;
    padding: 8px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
  }

  .select-rider-orderCnt {
    z-index: 90;
    width: 30px;
    cursor: pointer;
  }

  .map-inner {
    /* width: 100%;
    height: 100%; */
    /* margin-top: -30px; */
    /* border: 1px solid #ccc;  */
    text-align: left;
  }
  .map-inner .textLayout {
    height: 56px;
    line-height: 56px;
  }
  .map-inner .riderText {
    margin-left: 100px;
  }
  .riderOrderTable {
    width: 100%;
    height: 45vh;
    position: absolute;
    top: 40%;
    left: 0px;
    overflow-x: scroll;
    -ms-overflow-style: none;
  }
  .callDataTable {
    width: 45%;
    height: 45vh;
    position: absolute;
    top: 40%;
    left: 10px;
    overflow-y: scroll;
    -ms-overflow-style: none;
  }
  .callDataTable::-webkit-scrollbar {
    display: none;
  }
  .riderListTable {
    height: 45vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
  }
  .riderListTable::-webkit-scrollbar {
    display: none;
  }
  .table-column-center.arrive {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .riderListInMapControl {
    width: 42%;
    position: absolute;
    right: 10px;
    top: 35%;
    z-index: 50;
  }
  .rider-list-show-btn {
    float: right;
    position: absolute;
    z-index: 100;
    right: 10px;
    top: 320px;
    background-color: #000;
    padding: 5px 20px;
    color: #fff;
    cursor: pointer;
  }

  .order-list-show-btn {
    float: right;
    position: absolute;
    z-index: 100;
    left: 0px;
    top: 300px;
    background-color: #000;
    padding: 5px 20px;
    color: #fff;
    cursor: pointer;
  }

  .map-box .ant-checkbox {
    top: 0em !important;
  }
  .map-box .ant-checkbox-wrapper {
    display: table;
  }

  .map-box .ant-checkbox-wrapper > span {
    display: table-cell;
    text-align: center;
  }

  .map-box > div {
    width: 120px;
    height: 52px;
    float: left;
    /* margin:20px; */
  }

  .map-box .ant-checkbox-inner {
    width: 45px;
    height: 45px;
  }
  .map-box .ant-checkbox-inner::after {
    width: 25px;
    height: 25px;
  }

  .map-box .ant-checkbox + span {
    font-size: 20px;
    vertical-align: middle;
  }

  /* 기사 추가 dialog */
  .addRider-Dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .addRider-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 650px;
    border-radius: 30px;
    z-index: 2;
  }

  .addRider-content {
    background-color: white;
    padding: 30px;
    /* text-align: left; */
    /* color: #777; */
  }

  .addRider-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .addRider-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .addRider-Dialog .addRiderlayout {
    height: 540px;
  }

  .addRiderWrapper .contentBlock {
    margin-top: 30px !important;
  }

  .addRiderWrapper .contentBlock .selectItem .override-select {
    width: 345px;
  }

  .addRider-Dialog .dataTableBlock {
    margin-top: 40px;
  }

  .addRiderWrapper .submitBlock {
    margin-top: 30px;
    text-align: center;
  }

  /* 그룹 추가 dialog */
  .addGroup-Dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .addGroup-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    border-radius: 30px;
    z-index: 2;
  }

  .addGroup-content {
    background-color: white;
    padding: 30px;
    height: 100%;
    /* text-align: left; */
    /* color: #777; */
  }

  .addGroup-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .addGroupWrapper {
    margin-top: 20px;
  }
  .addGrouplayout {
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .addGrouplayout .contentBlock .override-input {
    width: 260px;
  }

  .addGroup-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  /* .addGroupWrapper .contentBlock {
    margin-top: 30px;
  } */

  .addGroupWrapper .submitBlock {
    margin-top: 30px;
    text-align: center;
  }

  .addGroupWrapper .ant-btn-primary {
    width: 130px;
    height: 40px;
    font-size: 18px;
  }

  /* 공지사항 dialog */
  .noticeDialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    border-radius: 30px;
    padding: 20px;
    z-index: 2;
  }

  .noticeLayout {
    margin-top: 5px;
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    padding: 20px;
  }

  .deleteBox {
    float: left;
    margin-bottom: 20px;
  }

  .noticeLayout .ant-checkbox-wrapper {
    height: 32px;
    margin-left: 0 !important;
    vertical-align: top;
  }

  .registBtn {
    float: right;
    margin-bottom: 20px;
  }

  .noticeDialog .container {
    background-color: white;
    padding: 30px;
    text-align: left;
    color: #777;
  }

  .notice-title {
    /* font-size: 28px; */
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .notice-close {
    width: 30px;
    background-color: #fddc00;
    padding: 8px;
    cursor: pointer;
    /* display: inline-block; */
    float: right;
  }

  .noticeLayout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .noticeLayout .noticelistBlock {
    float: left;
    width: 100%;
  }

  .noticeTag {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  /* 공지사항 등록 dialog */
  .registNoticeDialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    border-radius: 30px;
    padding: 20px;
    z-index: 2;
  }

  .Regist-Notice-Dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
  }

  .registNotice-content {
    background-color: white;
    padding: 30px;
    text-align: left;
    color: #777;
  }

  .registNotice-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .registNotice-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .registNoticeDialog .layout {
    margin-top: 20px;
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px 20px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .registNoticeWrapper {
    display: inline-block;
  }

  .registNoticeWrapper .mainTitle {
    width: 130px;
  }

  .registNoticeWrapper .subTitle {
    width: 100px;
    margin-left: 20px;
  }

  .registNoticeWrapper .importantBox {
    /* margin-left: -; */
    display: inline-block;
  }

  .registNoticeCheck {
    display: inline-block;
    margin-left: 20px;
    height: 31.7px;
  }

  .registNoticeDialog .layout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .registNoticeDialog .layout .contentBlock {
    margin-top: 25px;
  }

  .registNoticeWrapper .submitBlock {
    margin-top: 20px;
    text-align: right;
  }

  .registNoticeWrapper .clearBtn {
    margin-left: 20px;
  }

  .registNoticeWrapper .contentBlock .override-select.branch {
    width: 240px;
  }

  .registNoticeWrapper .override-select.sub {
    width: 270px !important;
  }

  .registNoticeWrapper .contentBlock .override-input {
    width: 260px;
    margin-left: 0 !important;
  }

  .registNoticeWrapper .ant-radio-group {
    margin-left: 20px;
  }

  .registNoticeWrapper span.ant-radio + * {
    padding-left: 5px;
    padding-right: 5px;
  }

  .registNoticeDialog .ant-btn {
    width: 130px;
    height: 40px !important;
    font-size: 18px !important;
  }

  .registNoticeWrapper.sub {
    display: inline-block;
    margin-left: 70px;
  }

  .registNoticeWrapper.sub .contentBlock .override-input {
    width: 260px;
    height: 85px;
  }

  .notice-content {
    display: inline-block !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    /* max-width: 20px; */
    /* height: 200px !important; */
  }

  .registNoticeDialog .ant-btn {
    width: 130px;
    height: 40px;
    font-size: 18px !important;
  }

  .registNoticeDialog .importantBox .ant-checkbox-wrapper {
    margin-left: 15px !important;
  }

  /* 콜등록 dialog */
  .registCall-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    /* border-radius: 30px; */
    z-index: 13;
    max-height: 90%;
    overflow: scroll;
  }
  .registCall-container {
    background-color: white;
    padding: 10px;
    text-align: left;
    color: #777;
  }
  .registCall-title {
    /* font-size: 28px; */
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }
  .registCall-close {
    width: 30px;
    background-color: #fddc00;
    padding: 8px;
    cursor: pointer;
    /* display: inline-block; */
    float: right;
  }
  .registCallLayout {
    margin-top: 20px;
    width: 100%;
    /* height: 725px;  */
    overflow-y: scroll;
    border: 1px solid #ccc;
    /* padding: 20px; */
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .registCallLayout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .registCall-Dialog .registCallWrapper > div {
    display: inline-block;
    vertical-align: top;
  }
  /* wrappper */
  .registCallWrapper {
    padding: 10px;
  }
  .registCallWrapper .mainTitle {
    display: inline-block;
    vertical-align: middle;
    font-weight: bold;
    width: 110px;
  }

  .mainTitle.sub {
    display: inline-block;
    font-size: 18px;
    vertical-align: middle;
    font-weight: bold;
    margin-left: 10px;
  }

  .registCallLayout .contentBlock {
    margin-top: 8px;
  }
  .registCallLayout .orderPayment-wrapper {
    display: inline-block;
  }

  .contentBlock .ant-form-item {
    margin-bottom: 0px;
  }
  .contentBlock .selectItem {
    display: inline-block;
  }
  .contentBlock .override-select {
    margin-left: 20px;
    width: 250px;
    font-size: 15px;
  }
  .contentBlock .override-select.branch {
    margin-left: 20px;
    width: 400px;
    font-size: 15px;
    display: inline-block;
    vertical-align: middle;
  }
  .contentBlock .override-select.fran {
    margin-left: 20px;
    width: 201px;
    font-size: 15px;
    display: inline-block;
    vertical-align: middle;
  }
  .contentBlock .override-select.time {
    margin-left: 20px;
    width: 201px;
    font-size: 15px;
    display: inline-block;
    vertical-align: middle;
  }
  .contentBlock .override-input {
    margin-left: 5px;
    width: 150px;
  }

  .registCallWrapper > div:last-child {
    /* margin-top: 0; */
    vertical-align: top;
  }
  /* 왼쪽 wrappper */
  .registCallWrapper.sub {
    vertical-align: top;
    display: inline-block;
    width: 265px;
    padding: 0px !important;
  }
  /* 오른쪽 wrappper */
  .registCallWrapper.sub2 {
    vertical-align: top;
    display: inline-block;
    width: 265px;
    padding: 0px !important;
    margin-top: 10px !important;
  }

  .contentBlock .override-input.memo {
    /* margin-left: -30px; */
    width: 205px;
  }
  .contentBlock .override-input.price {
    /* margin-left: -30px; */
    width: 205px;
  }
  .contentBlock .override-input.delprice {
    /* margin-left: -30px; */
    width: 205px;
  }

  .callTab {
    float: right;
    width: 110px;
    height: 40px;
    font-size: 16px !important;
    line-height: 20px;
  }

  .content-btn {
    width: 100%;
    margin-top: 16px !important;
  }

  .paymentBtn-cancel {
    float: right;
  }
  /* 가맹점등록 dialog */

  .mainTitle.sub {
    display: inline-block;
    font-size: 16px;
    vertical-align: middle;
    font-weight: bold;
    margin-left: 10px;
  }

  /* 코인이체 dialog */
  .coinTran-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    z-index: 2;
  }

  .coinTran-content {
    padding: 30px;
    background-color: white;
  }

  .coinTran-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }
  .coinTran-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }
  .coinTran-title-sub {
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    background-color: #222;
    color: #fff;
    height: 32px;
    line-height: 32px;
  }
  .coinTran-inner {
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 5px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .coinTran-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .coinTran-list {
    text-align: center;
    margin-top: 10px;
  }
  .coinTran-list td {
    width: 100px;
    font-size: 16px;
    display: inline-block;
  }
  .coinTran-list .coinTran-place1 {
    display: inline-block;
  }
  .coinTran-list .twl {
    width: 400px;
    display: inline-block;
    line-height: 30px;
  }
  .coinTran-btn-01 {
    margin-top: 20px;
    text-align: center;
  }
  .coinTran-btn-01 .tabBtn {
    margin: 0px !important;
  }
  .coinTran-btn-01 > button {
    height: 40px;
    width: 200px;
    font-size: 18px;
    background-color: #1890ff;
    color: #fff;
    font-size: 20px;
    text-align: center;
    display: inline-block;
    padding: 0px !important;
  }
  .coinTran-list .inputBox-coinTran.sub .ant-input {
    margin-left: -20px;
    width: 200px;
    text-align: right;
  }
  .coinTran-title-02 {
    font-size: 15px;
    font-family: NotoSansBold;
  }
  /* 코인이체모달-antd 수정 */

  .coinTran-Dialog .coinTran-inner .dataTableLayout {
    margin: 0;
  }

  .coinTran-Dialog .ant-table-content thead th {
    background-color: #222 !important;
    padding: 10px;
  }

  /* 가맹점수정 dialog */
  .modifyFran-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    border-radius: 30px;
    z-index: 2;
  }
  .modifyFran-container {
    background-color: white;
    padding: 30px;
    text-align: left;
    color: #777;
  }
  .modifyFran-title {
    /* font-size: 28px; */
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }
  .modifyFran-close {
    width: 30px;
    background-color: #fddc00;
    padding: 8px;
    cursor: pointer;
    /* display: inline-block; */
    float: right;
  }
  .modifyFranLayout {
    margin-top: 20px;
    width: 100%;
    /* height: 510px;  */
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 10px 20px 20px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .modifyFranLayout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  /* 왼쪽 wrappper */
  .modifyFranWrapper {
    display: inline-block;
  }
  .modifyFranTitle {
    display: inline-block;
    font-size: 23px;
    vertical-align: middle;
    font-weight: bold;
    color: #1890ff;
  }
  .modifyFranWrapper .mainTitle {
    display: inline-block;
    /* font-size: 20px; */
    vertical-align: middle;
    font-weight: bold;
    width: 100px;
  }
  .modifyFranWrapper .mainTitle.red {
    display: inline-block;
    font-size: 20px;
    vertical-align: middle;
    font-weight: bold;
    width: 100px;
    color: #1890ff;
  }
  .mainTitle.sub {
    display: inline-block;
    font-size: 18px;
    vertical-align: middle;
    font-weight: bold;
    margin-left: 10px;
  }
  .modifyFranLayout .contentBlock {
    margin-top: 15px;
  }
  .modifyFranWrapper .ant-form-item {
    margin-bottom: 0px;
  }
  .modifyFranWrapper .selectItem {
    display: inline-block;
  }
  .modifyFranWrapper .override-select {
    margin-left: 20px;
    width: 250px;
    font-size: 15px;
  }
  .modifyFranWrapper .override-select.branch {
    margin-left: 20px;
    width: 300px;
    font-size: 15px;
    display: inline-block;
    vertical-align: middle;
  }
  .modifyFranWrapper .override-input {
    margin-left: 20px;
    width: 300px;
  }
  /* 오른쪽 wrappper */
  .modifyFranWrapper.sub {
    vertical-align: top;
    display: inline-block;
    /* width: 340px; */
    margin-top: 36px;
    margin-left: 70px;
  }
  .modifyFranWrapper.sub .mainTitle {
    display: inline-block;
    font-size: 20px;
    vertical-align: middle;
    font-weight: bold;
    width: 75px;
  }
  .modifyFranWrapper.sub .override-input.sub {
    /* margin-left: -30px; */
    width: 300px;
  }
  .modifyFranWrapper.sub .override-input.price {
    /* margin-left: -30px; */
    width: 200px;
    margin-right: 10px;
  }
  .modifyFranWrapper.sub .callTab {
    width: 400px;
    height: 40px;
    font-size: 18px;
    margin-top: 60px;
  }
  /* 하단 랩퍼 */
  .modifyFranWrapper.bot {
    padding-top: 30px;
    display: inline-block;
    float: right;
    margin-right: 13px;
  }
  .modifyFranWrapper.bot .callTab {
    width: 185px;
    height: 40px;
    font-size: 18px;
    /* margin-top: 12px; */
    display: inline-block;
    vertical-align: middle;
  }

  /* 출금 비밀번호 초기화 */
  .updatePassword-inner {
    width: 100%;
    height: 80px;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 5px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .updatePassword-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .updatePassword-inner .twl .coinRider-list {
    margin-left: 0 !important;
  }

  /* 라이더 코인충전 dialog */
  .coinRider-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    z-index: 2;
  }

  .coinRider-content {
    padding: 20px;
    background-color: white;
  }

  .coinRider-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }
  .coinRider-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }
  .coinRider-title-sub {
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    background-color: #222;
    color: #fff;
    height: 32px;
    line-height: 32px;
  }
  .coinRider-inner {
    width: 100%;
    height: 200px;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 5px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .coinRider-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .coinRider-list {
    text-align: center;
  }
  .coinRider-list td {
    width: 100px;
    font-size: 16px;
    display: inline-block;
  }
  .coinRider-list .coinRider-place1 {
    display: inline-block;
  }
  .coinRider-list .twl {
    width: 400px;
    display: inline-block;
    height: 60px;
    line-height: 30px;
  }
  .coinRider-btn-01 {
    margin-top: 20px;
    text-align: right;
  }
  .coinRider-btn-01 .tabBtn {
    margin: 0px !important;
  }
  .coinRider-btn-01 > button {
    height: 40px;
    width: 200px;
    font-size: 18px;
    background-color: #1890ff;
    color: #fff;
    font-size: 20px;
    text-align: center;
    display: inline-block;
    padding: 0px !important;
  }
  .coinRider-list .inputBox-coinRider.sub .ant-input {
    margin-left: -20px;
    width: 300px;
  }

  /*입출금내역 dialog*/

  .riderBank-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    z-index: 2;
  }

  .riderBank-content {
    padding: 30px;
    background-color: white;
  }

  .riderBank-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }

  .riderBank-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .riderBank-inner {
    width: 100%;
    height: 650px;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .riderBank-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .riderBank-list {
    height: 500px;
  }
  .riderBank-list-01 {
    display: inline-block;
    width: 950px;
    text-align: left;
    margin-top: 30px;
  }

  .riderBank-list-01 .riderBank-datepicker {
    display: inline-block;
  }

  .riderBank-list-01 td {
    display: inline-block;
    width: 100px;
    font-size: 25px;
    text-align: center !important;
  }
  .riderBank-list-01 Button {
    margin-left: 30px;
    background-color: #40a9ff;
    color: #fff;
  }

  /* 필터링 dialog */
  .filtering-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .filtering-content {
    padding: 30px;
    background-color: white;
  }

  .filtering-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    display: inline-block;
    vertical-align: middle;
  }

  .filtering-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .filtering-inner {
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px 30px 10px;
    text-align: left;
    margin-top: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .filtering-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .filtering-box {
    /* border: 1px solid #e0e0e0; */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    height: 33px;
  }
  .filtering-box-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    border: 1px solid #e0e0e0;
  }
  .voice {
    margin-top: 10px;
    padding: 5px;
  }
  .filtering-box-wrapper > div:last-child {
    margin-bottom: 0;
  }
  .filtering-name {
    text-align: center;
    font-size: 16px;
    border-right: 1px solid #e0e0e0;
    margin-right: 15px;
    display: inline-block;
    padding: 0 10px 5px 5px;
  }
  .filtering-box .ant-checkbox {
    top: 0em !important;
  }

  .filtering-box .ant-checkbox-wrapper {
    display: table;
  }
  .filtering-box .ant-checkbox-group {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .filtering-box .ant-checkbox-wrapper > span {
    display: table-cell;
    text-align: center;
  }

  .filtering-box .ant-checkbox-inner:hover {
    display: none;
  }
  .filtering-box .ant-checkbox-inner::after {
    width: 8px;
    height: 8px;
  }
  .filtering-box .ant-checkbox-checked::after {
    width: 100%;
    height: auto;
  }

  .filtering-btn {
    display: inline-block;
    padding: 5px 0;
  }

  .filtering-box .ant-checkbox + span {
    font-size: 16px;
    vertical-align: top;
    line-height: 25px;
  }

  .riderManageBtn {
    margin-left: 10px;
  }

  /*기사관리*/
  /*일차감*/

  .taskScheduler-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    z-index: 2;
  }

  .taskScheduler-content {
    padding: 30px;
    background-color: white;
  }

  .taskScheduler-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }

  .taskScheduler-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .taskScheduler-inner {
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .taskScheduler-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .taskScheduler-inner .ant-table-expanded-row .ant-table-row {
    display: inline-block;
  }

  .listBlock .ant-btn {
    margin-left: -5px;
  }

  .mainTitle {
    display: inline-block;
    /* font-size: 20px; */
    vertical-align: middle;
    font-weight: bold;
    /* width: 45px; */
  }

  .taskScheduler-btn {
    display: inline-block;
    width: 1098px;
  }

  .taskScheduler-btn-01 {
    text-align: center;
    vertical-align: middle;
    display: inline-block;
  }

  .rtaskScheduler-btn-01 .tabBtn {
    margin: 0px !important;
  }
  .taskScheduler-btn-01 > button {
    height: 32px;
    width: 110px;
    font-size: 14px;
    color: #222;
    text-align: center;
    display: inline-block;
    padding: 0px !important;
  }
  .taskScheduler-btn-02 {
    vertical-align: middle;
    display: inline-block;
  }

  .taskScheduler-btn-02 > button {
    height: 32px;
    width: 110px;
    font-size: 14px;
    color: #222;
    padding: 0px !important;
    display: inline-block;
  }

  .taskScheduler-btn-03 {
    vertical-align: middle;
    display: inline-block;
    float: right;
  }
  .taskScheduler-btn-03 > button {
    height: 40px;
    width: 130px;
    font-size: 18px;
    background: #1890ff;
    color: #fff;
    padding: 0px !important;
    display: inline-block;
  }
  /*일차감 그룹 설정*/

  .taskGroup-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width:650px; */
    z-index: 2;
  }

  .taskGroup-content {
    padding: 30px;
    background-color: white;
  }

  .taskGroup-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }

  .taskGroup-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .taskGroup-inner {
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .taskGroup-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .taskGroup-inner .subTable .ant-table-thead {
    /* display: none; */
  }
  .taskGroup-inner .subTable .ant-table-cell {
    padding: 13px;
  }
  .taskGroup-inner .subTable .ant-table-row {
    display: inline-block;
  }

  .taskGroup-btn {
    display: inline-block;
  }

  .taskGroup-btn-01 {
    text-align: center;
    vertical-align: middle;
    display: inline-block;
  }

  .taskGroup-btn-01 .tabBtn {
    margin: 0px !important;
  }
  .taskGroup-btn-01 > button {
    height: 40px;
    width: 130px;
    font-size: 18px;
    color: #222;
    text-align: center;
    display: inline-block;
    padding: 0px !important;
  }

  .taskGroup-list {
    border: 1px solid red;
    width: 900px;
    display: inline-block;
    margin-top: 20px;
    font-size: 15px;
  }
  .taskGroup-list-01 {
    display: inline-block;
    width: 326.67px;
    height: 32px;
    background-color: #333;
    color: #fff;
    text-align: center;
    line-height: 32px;
  }
  .taskGroup-list-02 {
    display: inline-block;
    width: 173.33px;
    height: 32px;
    background-color: #333;
    color: #fff;
    text-align: center;
    line-height: 32px;
  }
  .taskGroup-list-03-place1 {
    display: inline-block;
    float: right;
  }
  .taskGroup-content .listBlock {
    width: 550px;
  }

  /*일차감 작업등록*/

  .taskWork-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 700px; */
    z-index: 2;
  }

  .taskWork-content {
    padding: 20px;
    background-color: white;
  }

  .taskWork-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }

  .taskWork-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .taskWork-title-sub {
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    background-color: #222;
    color: #fff;
    height: 32px;
    line-height: 32px;
  }

  .taskWork-inner {
    width: 550px;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 5px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .taskWork-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .taskWork-list {
    /* border:1px solid red; */
    /* height:360px; */
  }
  .taskWork-list .twl-text {
    width: 120px;
    font-size: 18px;
    display: inline-block;
    /* border:1px solid blue; */
  }

  .taskWork-list-01 .useText {
    margin-left: 5px;
  }

  .taskWork-list-01 .taskWorkSub {
    width: 90px;
    font-size: 16px;
    /* display: inline-block; */
    float: left;
    /* border:1px solid blue; */
  }

  .taskWork-list-05 .useBtn {
    margin-left: 10px;
    margin-right: 15px;
  }

  .taskWork-list .taskWork-place1 {
    display: inline-block;
  }

  .taskWork-list .twl {
    width: 100%;
    display: inline-block;
    height: 50px;
    line-height: 50px;
    /* border:1px solid red */
  }

  .twl:nth-child(1) {
    margin-top: -10px;
  }

  .taskWork-place1 .searchRiderInput {
    width: 185px !important;
    margin-left: 0px !important;
  }
  .taskWork-btn-01 {
    margin-top: 20px;
    text-align: center;
  }

  .taskWork-btn-01 .tabBtn {
    margin: 0px !important;
  }
  .taskWork-btn-01 > button {
    height: 40px;
    width: 130px;
    font-size: 18px;
    background-color: #1890ff;
    color: #fff;
    text-align: center;
    display: inline-block;
    padding: 0px !important;
  }

  .taskWork-list-06
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    margin-left: 10px;
  }
  .taskWork-list-04 .inputBox {
    color: #222 !important;
  }
  .taskWork-list .inputBox-taskWork.sub .ant-input {
    margin-left: -10px;
    width: 186.82px;
  }

  /*기사 그룹관리*/
  .riderGroup-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    z-index: 2;
  }

  .riderGroup-content {
    padding: 30px;
    background-color: white;
  }

  .riderGroup-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }

  .riderGroup-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .riderGroup-inner {
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .riderGroup-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .riderGroup-inner .listBlock {
    margin-top: 20px;
  }
  .riderGroup-inner .listBlock .ant-btn {
    margin-left: -5px;
  }

  .riderGroup-inner .mainTitle {
    display: inline-block;
    font-size: 20px;
    vertical-align: middle;
    font-weight: bold;
    /* width: 75px; */
  }

  .riderGroup-ftline {
    line-height: 30px;
    font-size: 18px;
    /* height: 215px; */
    padding: 20px;
    margin-top: 10px;
    /* border:1px solid #ccc; */
  }
  .riderGroup-ftline p {
    display: inline-block;
    width: 100px;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
  .riderGroup-ftline > div {
    margin-bottom: 10px;
  }
  .riderGroup-ftline > div:last-child {
    margin-bottom: 0;
  }
  .riderGroup-ftline td {
    width: 150px;
    display: inline-block;
  }

  .riderGroup-ftline-02 .riderSubtext,
  .riderGroup-ftline-03 .riderSubtext,
  .riderGroup-ftline-04 .riderSubtext {
    margin-left: 20px;
  }
  .riderGroup-ftline-04 {
    display: inline-block;
  }
  .riderGroup-ftline .ant-radio-wrapper {
    font-size: 16px !important;
  }
  .riderGroup-ftline-06 {
    display: inline-block;
  }

  .riderGroup-btn {
    display: inline-block;
    float: right;
  }

  .riderGroup-btn-01 {
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    /* margin-left:20px; */
  }

  .riderGroup-btn-01 .tabBtn {
    margin: 0px !important;
    display: inline-block;
  }
  .riderGroup-btn-01 > button {
    height: 40px;
    width: 130px;
    background: #1890ff;
    color: #fff;
    font-size: 18px;
    text-align: center;
    padding: 0px !important;
  }

  .riderGroup-ftline-01,
  .riderGroup-ftline-05 {
    display: inline-block;
  }

  .riderGroup-ftline-05 .ant-input {
    margin-left: 20px;
  }
  .riderGroup-ftline-05 td {
    margin-left: 35.15px;
  }
  .riderGroup-ftline .inputBox .ant-form-item {
    margin-bottom: 0px;
    display: inline-block;
    vertical-align: middle;
  }
  .riderGText {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    color: #000 !important;
    font-size: 16px;
  }
  .riderGroup-ftline .ant-input {
    padding: 0px !important;
  }

  .riderGroup-ftline .inputBox {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0px !important;
  }

  .riderGroup-ftline .inputBox-rider.sub .ant-input {
    margin-left: -10px;
  }

  .riderGroup-ftline .select-fee-pay-type {
    margin-left: 20px;
  }

  .riderGroup-inner .ant-table-row-level-0-active {
    background-color: #1a1a1a;
  }

  .ant-checkbox-checked:checked .ant-table-row-level-0:active {
    background-color: #fddc00;
  }

  /*기사 그룹관리 라인활성화*/

  .clickRowStyl {
    background-color: #40a9ff;
  }

  /* .riderGroup-inner .ant-table-row-level-0:hover{
    background-color: #40a9ff !important;
  }

  .riderGroup-inner .clickRowStyl:hover{
    background-color: #40a9ff !important;
    transition: all 10s ease-in-out;
  } */

  /*기사 등록*/
  .registRider-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 960px;
    border-radius: 30px;
    z-index: 2;
  }

  .registRider-content {
    background-color: white;
    padding: 30px;
    text-align: left;
    color: #777;
  }
  .registRiderLayout {
    margin-top: 20px;
    width: 100%;
    /* height:500px; */
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px 20px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .registRiderLayout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .registRider-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .registRider-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .registRiderWrapper {
    display: inline-block;
    vertical-align: top;
  }

  .registRiderWrapper .contentBlock {
    margin-top: 15px;
  }

  .registRiderWrapper .ant-form-item-control-input-content {
    width: 260px;
  }

  .registRiderWrapper
    .ant-form-item-control-input-content
    .ant-checkbox
    + span {
    padding-right: 0;
  }

  .registRiderWrapper .mainTitle {
    width: 120px;
  }

  .registRiderCheck {
    margin-left: 20px;
    display: inline-block;
    height: 31.7px;
  }
  .registRiderCheck .ant-radio-group {
    margin-top: 4px !important;
  }
  .registRiderWrapper .submitBlock {
    margin-top: 20px;
    text-align: right;
  }

  .registRiderWrapper .clearBtn {
    margin-left: 20px;
  }

  .registRiderWrapper .contentBlock .override-input {
    margin-left: 0px;
  }

  .registRiderWrapper .contentBlock .override-select.branch {
    width: 260px !important;
    margin-left: 0px;
  }

  .registRiderWrapper .contentBlock .override-input {
    width: 260px !important;
  }

  /*오른쪽*/
  .registRiderBox {
    display: inline-block;
  }
  .registRiderWrapper.sub {
    display: inline-block;
    margin-left: 70px;
    /* width: 400px; */
  }

  .registRider-Dialog .submitBlock .ant-btn {
    width: 125px;
    height: 40px;
    font-size: 16px !important;
  }

  .giveBox {
    width: 280px;
  }

  .giveBox .ant-checkbox-wrapper {
    width: 90px;
    margin: 0px !important;
  }

  .registRiderWrapper.sub .submitBlock {
    width: 390px;
    /* margin-top: 55px; */
  }

  /* 하단 */

  .bike-type-box {
    border-top: 1px solid #ccc;
    padding-top: 30px;
    margin-top: 40px;
  }
  .bike-type-box > div {
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    color: #1890ff;
  }
  .bike-type-box ul {
    padding: 0;
    margin: 0;
  }
  .bike-type-box ul li {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 15px 20px 0 0;
  }
  .bike-type-box ul li:last-child {
    margin-right: 0;
  }
  .bike-type-box ul li p {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0 !important;
  }
  .bike-type-box ul li .selectItem {
    display: inline-block;
    width: 165px;
    margin: 0 0 0 15px;

    vertical-align: 0px;
  }
  /*직원 등록*/

  .registStaff-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    border-radius: 30px;
    z-index: 2;
  }

  .registStaff-content {
    background-color: white;
    padding: 30px;
    text-align: left;
    color: #777;
  }

  .registStaff-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .registStaff-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .registStaffWrapper .mainTitle {
    width: 130px;
  }

  .registStaffWrapper .registStaffCheck {
    display: inline-block;
    margin-left: 22px;
  }

  .registStaff-Dialog .layout {
    margin-top: 20px;
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px 20px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .registStaff-Dialog .layout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .registStaff-Dialog .layout .contentBlock {
    margin-top: 25px;
  }

  .registStaffWrapper .submitBlock {
    margin-top: 30px;
    text-align: center;
  }

  .registStaffWrapper .clearBtn {
    margin-left: 20px;
  }

  .registStaffWrapper .contentBlock .override-select.branch {
    width: 240px;
  }

  .registStaffWrapper .override-select.sub {
    width: 270px !important;
  }

  .registStaffWrapper .contentBlock .override-input {
    width: 240px;
  }
  .registStaffWrapper .ant-radio-group {
    margin-left: 20px;
  }
  .registStaffWrapper span.ant-radio + * {
    padding-left: 5px;
    padding-right: 5px;
  }
  .registStaff-Dialog .ant-btn {
    width: 130px;
    height: 40px !important;
    font-size: 18px !important;
  }

  /*기사 차단*/

  .blackRider-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    border-radius: 30px;
    z-index: 2;
  }

  .blackRider-content {
    background-color: white;
    padding: 30px;
    text-align: left;
    color: #777;
  }

  .blackRider-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .blackRider-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .blackRider-Dialog .layout {
    margin-top: 20px;
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px 20px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .blackRider-Dialog .layout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .blackRiderWrapper .mainTitle {
    width: 130px;
  }

  .blackRiderWrapper .contentBlock {
    margin-top: 25px;
  }

  .blackRiderWrapper .submitBlock {
    margin-top: 30px;
    text-align: center;
  }

  .blackRiderWrapper .clearBtn {
    margin-left: 20px;
  }

  .blackRiderWrapper .submitBlock button {
    height: 40px;
    width: 130px;
    font-size: 18px;
  }
  .blackRiderWrapper .contentBlock .override-select.branch {
    width: 240px !important;
  }

  .blackRiderWrapper .contentBlock .override-input {
    width: 240px !important;
  }

  .blackRiderWrapper .contentBlock .override-input.branch {
    width: 240px !important;
    height: 200px;
  }

  /* 환경설정 */

  .pwChange-root {
    padding: 24px;
  }
  .pwChange-Layout {
    border: 1px solid #ccc;
    padding: 20px;
    width: 40%;
    margin: 0 auto;
  }
  .pwChange-title {
    font-size: 15px;
    font-family: NotoSansBold;
    text-align: center;
    margin-top: 20px;
  }
  .pwChange-box {
    width: 100%;
    padding: 20px;
    text-align: left;
    margin-top: 5px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .pwChange-list {
    text-align: center;
    padding: 5px 0;
  }
  .pwChange-list td {
    width: 110px;
    font-size: 14px;
    display: inline-block;
    text-align: right;
    padding-right: 10px;
  }
  .pwChange-list .twl {
    width: 80px;
    display: inline-block;
    height: 60px;
    line-height: 30px;
  }
  .inputBox-pwChange input {
    width: 250px;
  }
  .pwChange-btn {
    text-align: center;
    padding: 10px 0;
  }

  /* 주소검색관리 */
  .searchAddr-inner {
    height: 80vh;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .searchAddr-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .searchAddressWrapper {
    display: flex;
    flex-direction: row;
  }
  .searchAddr-inner .searchAddr-innerBox {
    width: 100%;
  }
  .searchAddr-innerBox .contentBox {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .searchAddr-innerBox .contentBox.addCss {
    margin: 20px 0;
  }
  .contentbox {
    display: inline-block;
    width: 300px;
    vertical-align: top;
    margin-left: 10px;
  }
  .searchAddr-innerBox .selectItem {
    width: 90%;
    margin-bottom: 0;
  }
  .searchAddr-innerBox .subText,
  .third .contentBox .subText {
    width: 18%;
    margin-left: 0;
  }
  .searchAddr-innerBox .addrBtn {
    width: 100%;
  }
  .addrBtn.addCss {
    margin-top: 20px;
  }
  .third .contentBox {
    display: flex;
    flex-direction: row;
  }
  .second {
    display: inline-block;
    width: 300px;
    vertical-align: top;
    margin-left: 10px;
    margin-bottom: 0px !important;
  }
  .map-address-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    width: 130%;
    height: 100%;
    border-radius: 0;
    z-index: 5;
  }
  .map-address-Dialog .map-close {
    margin: 10px;
  }
  .map-address-Dialog .mapLayout {
    overflow: hidden;
  }
  .map-address-btn {
    float: right;
    margin: -70px 460px;
  }

  /* 추천배차 Dialog */

  .forceAllocate-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    border-radius: 30px;
    z-index: 2;
  }

  .forceAllocate-content {
    padding: 30px;
    background-color: white;
  }

  .forceAllocate-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 10px;
    float: left;
  }

  .forceAllocate-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .forceAllocate-inner {
    width: 100%;
    border: 1px solid #ccc;
    padding: 20px;
    margin-top: 5px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    clear: both;
  }

  .forceAllocate-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .forceAllocate-list {
    text-align: left;
    margin: 20px 0;
  }

  .forceAllocate-list span {
    text-align: left;
  }

  .forceAllocate-btn-01 {
    margin-top: 20px;
    text-align: center;
  }

  .forceAllocate-btn-01 .tabBtn {
    margin: 0px !important;
  }
  .forceAllocate-btn-01 > button {
    height: 40px;
    width: 200px;
    font-size: 18px;
    background-color: #1890ff;
    color: #fff;
    font-size: 20px;
    text-align: center;
    display: inline-block;
    padding: 0px !important;
  }

  .forceAllocate-select-btn .ant-checkbox-wrapper {
    margin: 0 !important;
  }

  .forceAllocate-Dialog .ant-table-content thead th {
    padding: 10px;
  }

  .forceAllocate-Dialog .forceAllocate-inner .ant-spin-container .ant-table {
    margin: 0 !important;
  }
  /* 메세지 dialog */

  .message-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 345px;
    z-index: 2;
  }

  .message-content {
    padding: 30px;
    background-color: white;
  }

  .message-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }

  .message-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .message-inner {
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin-top: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .message-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .selectLayout {
    margin-left: 0;
    margin-right: 1rem;
    margin-top: -30px;
  }

  /* ModifyOrdeDialog */

  .modifyOrderLayout {
    margin-top: 20px;
    border: 1px solid #ccc;
    padding: 10px 20px 20px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .modifyOrder-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 1000px; */
    border-radius: 30px;
    z-index: 2;
  }
  .modifyOrder-container {
    background-color: white;
    padding: 30px;
    text-align: left;
    color: #777;
  }
  .chat-container {
    width: 440px;
    /* height: 700px; */
    height: 100%;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }
  .chat-page {
    padding: 10px 0px;
  }
  .chat-page > ul > li {
    width: 32px;
    height: 32px;
    line-height: 30px;
    font-size: 13px;
  }
  .chat-page > ul > li:last-child {
    display: block;
    margin-left: 310px;
    margin-top: 10px;
  }
  .chat-page > ul > li a {
    padding: 0;
  }
  .chat-page .ant-select-selector {
    font-size: 12px;
    height: 30px !important;
  }
  /* .chat-list-container{
    height:650px;
    border:1px solid red;
  } */
  .chat-title {
    width: 100%;
    background-color: #fddc00;
    padding: 20px;
    text-align: center;
    font-size: 20px;
    letter-spacing: -1px;
    font-family: NotoSansMedium;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .chat-item-container {
    padding: 10px 20px;
    cursor: pointer;
  }
  .chat-item-image {
    display: inline-block;
  }
  .chat-item-image img {
    width: 65px;
  }
  .chat-item-content {
    display: inline-block;
    width: 300px;
    vertical-align: middle;
    padding-left: 20px;
  }
  .chat-item-top {
    width: 100%;
  }
  .chat-item-top-title {
    font-size: 20px;
    letter-spacing: -1px;
    font-family: NotoSansBold;
  }
  .chat-item-top-time {
    float: right;
    font-size: 18px;
    letter-spacing: -1px;
    font-family: NotoSansRegular;
    color: #b1b1b1;
  }
  .chat-item-bottom {
    font-size: 16px;
    letter-spacing: -1px;
    font-family: NotoSansMedium;
    color: #999999;
    width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .chat-message-container {
    /* float: right; */
    padding: 0px;
    width: 440px;
    height: 100%;
    border-radius: 10px;
    background-color: #ededed;
    margin-left: 30px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }
  .chat-input {
    border-radius: 12px;
    border: 1px solid #000;
    margin: 10px;
    height: 55px;
    background-color: #fff;
  }

  .chat-send-input {
    width: 330px;
    font-size: 18px;
    letter-spacing: -1px;
    font-family: NotoSansMedium;
    margin-top: 10px;
    border: 0px !important;
    margin-left: 15px;
  }
  .chat-send-input:focus {
    outline: none;
  }
  .chat-send-btn {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    float: right;
    background-color: #000;
    height: 100%;
    width: 60px;
    padding-top: 10px;
    text-align: center;
    font-size: 18px;
    letter-spacing: -1px;
    font-family: NotoSansMedium;
    color: #fff;
    cursor: pointer;
  }

  .msginput-container {
    padding: 10px;
    background-color: #000;
    border-radius: 7px;
  }

  .msginput-basic {
    margin: 10px;
    padding: 10px;
    background-color: #fbdd01;
    color: #000;
    font-family: NotoSansRegular;
    font-size: 24px;
    letter-spacing: -1px;
    border-radius: 5px;
    cursor: pointer;
  }

  .chat-message {
    height: 500px;
    padding: 20px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
  }
  .chat-message-item {
    margin: 10px 0px;
  }
  .chat-message-item.my {
    text-align: right;
  }
  .chat-message-icon {
    display: inline-block;
  }
  .chat-message-icon img {
    width: 40px;
  }
  .chat-message-content {
    display: inline-block;
    width: 320px;
    margin-left: 10px;
    vertical-align: top;
  }
  .chat-message-content-text {
    background-color: #fff;
    border-radius: 20px;
    padding: 5px 20px;
    color: #000;
    font-family: NotoSansRegular;
    font-size: 16px;
    letter-spacing: -1px;
    display: inline-block;
  }
  .chat-message-content-text.my {
    background-color: #fbdd01;
  }
  .chat-message-content-date {
    text-align: right;
    color: #999999;
    font-family: NotoSansRegular;
    font-size: 14px;
    letter-spacing: -1px;
  }
  .chat-message-content-date.my {
    padding-right: 10px;
  }

  /* 블라인드 */
  .blind-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    border-radius: 30px;
    z-index: 2;
  }
  .blind-container {
    background-color: white;
    padding: 15px 15px 65px 15px;
    text-align: left;
    color: #777;
  }

  .blind-container > div:nth-child(2) {
    display: inline-block;
  }

  .blind-title {
    /* font-size: 28px; */
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 18px;
    line-height: 30px;
    vertical-align: middle;
  }
  .blind-check-text {
    display: inline-block;
    vertical-align: middle;
  }
  .blind-close {
    width: 30px;
    background-color: #fddc00;
    padding: 8px;
    cursor: pointer;
    /* display: inline-block; */
    float: right;
  }
  .blind-check {
    float: right;
    margin-bottom: 10px;
  }
  .blindLayout {
    margin-top: 10px;
    width: 100%;
    /* height: 350px; */
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .blindLayout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .blindLayout .elipsis-table-row {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 130px;
    display: inline-block;
    vertical-align: middle;
    /* text-align: center; */
  }

  .blindWrapper.bot {
    margin-top: 10px;
    text-align: left;
  }
  .blindWrapper.bot .blindTitle {
    font-size: 20px;
    vertical-align: middle;
    font-weight: bold;
    color: #1890ff;
  }

  .blindWrapper.bot .mainTitle {
    font-size: 16px;
    vertical-align: middle;
    font-weight: bold;
    width: 70px;
    margin-bottom: 10px;
  }
  .blindWrapper.bot .subTitle {
    font-size: 16px;
    vertical-align: middle;
    font-weight: bold;
    width: 70px;
    margin-bottom: 10px;
  }
  .blindWrapper.bot .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }
  .blindWrapper.bot .override-input.sub {
    /* margin-left: -30px; */
    margin-left: 10px;
    width: 135px;
  }
  .blindWrapper.bot .selectItem {
    vertical-align: middle;
  }
  .blindWrapper.bot .override-select {
    margin-left: 10px;
    width: 230px;
    font-size: 15px;
    vertical-align: middle;
  }
  .blindWrapper.bot .callTab {
    width: 185px;
    height: 40px;
    font-size: 18px;
    margin-top: 12px;
    margin-left: 45px;
    display: inline-block;
    vertical-align: middle;
  }
  /* 모바일버전 블라인드 라이더 모달 창 */
  .riderBlind-box > div {
    display: inline-block;
  }
  .riderBlind-box > div:nth-child(2) {
    vertical-align: top;
    float: right;
  }
  .riderBlind-box > div:last-child {
    vertical-align: top;
    float: right;
    text-align: right;
  }
  .riderBlind-box .blind-title {
    text-align: left;
  }
  .riderBlind-box .blind-close {
    float: none;
  }
  .riderBlind-box .listBlock {
    margin-top: 0;
  }

  .modifyOrderWrapper {
    display: inline-block;
  }

  .modifyOrderLayout .contentBlock {
    margin-top: 1rem;
  }

  .modifyOrderLayout .mainTitle {
    width: 8rem;
  }

  .modifyOrderBtn {
    margin-top: 3rem;
    text-align: center;
  }

  /* paymentModal */

  .payment-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 1000px; */
    z-index: 2;
    background-color: white;
    padding: 10px;
    text-align: left;
    color: #777;
  }
  .payment-container div {
    display: block;
  }
  .detail-title {
    /* font-size: 28px; */
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    font-size: 25px;
    line-height: 30px;
  }
  .detail-title > div:first-child {
    display: inline-block;
  }
  .detail-title > div:last-child {
    width: 30px;
    height: 30px;
    background-color: #fddc00;
    cursor: pointer;
    float: right;
  }
  .detail-title > div:last-child img {
    width: 14px;
    margin: 4px 8px 8px 8px;
  }
  .detail-content {
    margin-top: 20px;
    width: 100%;
    /* height: 510px;  */
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .detail-content::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .split-content-title {
    font-weight: bold;
    font-size: 18px;
    color: black;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }
  .split-content-title > div:first-child {
    display: inline-block;
  }
  .split-content-title > div:last-child {
    float: right;
    right: 0;
  }
  .split-payment-box {
    padding: 25px 0px 15px 0px;
    width: 250px;
    height: auto;
  }
  .split-payment-box div {
    display: inline-block;
  }
  .split-payment-box > div:first-child {
    margin-bottom: 0;
  }
  .split-bottom-box {
    border-top: 1px solid #ccc;
    font-size: 18px;
    font-weight: bold;
    color: black;
    padding-top: 20px;
    text-align: center;
  }
  .orderPayment-wrapper {
    display: inline-block;
  }
  .orderPayments-wrapper {
    display: flex;
    flex-direction: column;
  }
  .orderPayment-col {
    width: auto;
  }
  .change {
    vertical-align: middle;
    text-align: center;
    width: 100px;
  }

  /*sns전송ㅇ*/
  .snsDialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    border-radius: 30px;
    padding: 20px;
    z-index: 2;
  }

  .snsLayout {
    margin-top: 5px;
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    padding: 20px;
    margin-top: 20px;
  }

  .snsDialog .container {
    background-color: white;
    padding: 30px;
    text-align: left;
    color: #777;
  }

  .sns-title {
    /* font-size: 28px; */
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .sns-close {
    width: 30px;
    background-color: #fddc00;
    padding: 8px;
    cursor: pointer;
    /* display: inline-block; */
    float: right;
  }

  .snsLayout::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  /* .snsLayout .snslistBlock {
    float: left;
    width: 55%;
  } */

  .snsLayout .snsDetailBlock {
    margin: 0 auto;
    width: 100%;
    /* border:1px solid red; */
  }

  .snsLayout .inputBox {
    width: 100%;
    color: #000;
    font-size: 17px;
  }

  /* .snsLayout .snsInputBox {
    width: 100%;
    height: 380px;
  } */
  .snsLayout .snsInputBox {
    width: 558px;
    margin-top: 10px;
    margin-left: -10px;
    padding-bottom: 100px;
  }

  .snsLayout .dataTableLayout-01 {
    margin-top: 20px;
  }

  .snsLayout .submitBtn {
    float: right;
    background-color: #fff;
    border: 1px solid #1890ff;
    color: #1890ff;
    box-shadow: none;
  }

  /* paymentDialog */
  .plusBtn {
    width: 30px;
    height: 30px;
    padding: none;
    text-align: center;
    vertical-align: center;
    color: white;
    font-size: 2rem;
    background-color: #fddc00;
    cursor: pointer;
    float: right;
  }
  .minusBtn {
    width: 100px;
    height: 30px;
    padding: none;
    text-align: center;
    vertical-align: center;
    color: white;
    font-size: 2rem;
    background-color: #fddc00;
    cursor: pointer;
  }
  .btnWrapper {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 15px;
  }

  /* 할증 그룹관리 dialog */
  .surchargeGroupList-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    z-index: 2;
  }

  .surchargeGroupList-content {
    padding: 30px;
    background-color: white;
  }

  .surchargeGroupList-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }

  .surchargeGroupList-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .surchargeGroupList-inner {
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    text-align: left;
    margin-top: 20px;
    padding-top: 10px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .surchargeGroupList-inner .elipsis-table-row {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80px;
    display: inline-block;
    vertical-align: middle;
    /* text-align: center; */
  }

  .surchargeGroupList-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .surchargeGroupList-inner .subTable {
    width: 255px;
  }
  .surchargeGroupList-inner .subTable .ant-table-thead {
    /* display: none; */
  }
  .surchargeGroupList-inner .subTable .ant-table-cell {
    padding: 13px;
  }
  .surchargeGroupList-inner .subTable .ant-table-row {
    display: inline-block;
  }

  .surchargeGroupList-btn {
    display: inline-block;
  }

  .surchargeGroupList-btn-01 {
    text-align: center;
    vertical-align: middle;
    display: inline-block;
  }

  .surchargeGroupList-btn-01 .tabBtn {
    margin: 0px !important;
  }
  .surchargeGroupList-btn-01 > button {
    font-size: 14px;
    color: #222;
    text-align: center;
    display: inline-block;
  }

  /* .surchargeGroupList-list {
    border: 1px solid red;
    width: 900px;
    display: inline-block;
    margin-top: 20px;
    font-size: 15px;
  }
  .surchargeGroupList-list-01 {
    display: inline-block;
    width: 326.67px;
    height: 32px;
    background-color: #333;
    color: #fff;
    text-align: center;
    line-height: 32px;
  }
  .surchargeGroupList-list-02 {
    display: inline-block;
    width: 173.33px;
    height: 32px;
    background-color: #333;
    color: #fff;
    text-align: center;
    line-height: 32px;
  }
  .surchargeGroupList-list-03-place1 {
    display: inline-block;
    float: right;
  } */

  /* 할증 가맹점 추가 dialog */
  .surchargeFr-Dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .surchargeFr-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 650px;
    border-radius: 30px;
    z-index: 2;
  }

  .surchargeFr-content {
    background-color: white;
    padding: 30px;
    /* text-align: left; */
    /* color: #777; */
  }

  .surchargeFr-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .surchargeFr-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .surchargeFr-Dialog .addRiderlayout {
    height: 540px;
  }

  .surchargeFrWrapper .contentBlock {
    margin-top: 30px !important;
  }

  .surchargeFrWrapper .contentBlock .selectItem .override-select {
    width: 345px;
  }

  .surchargeFr-Dialog .dataTableBlock {
    margin-top: 40px;
  }

  .surchargeFrWrapper .submitBlock {
    margin-top: 30px;
    text-align: center;
  }

  /* 할증 그룹 추가 dialog */
  .surchargeGroup-Dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .surchargeGroup-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    border-radius: 30px;
    z-index: 2;
  }

  .surchargeGroup-content {
    background-color: white;
    padding: 10px;
    /* text-align: left; */
    /* color: #777; */
  }

  .surchargeGroup-title {
    letter-spacing: -1px;
    font-weight: bold;
    color: #333;
    text-align: right;
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
  }

  .surchargeGroupWrapper {
    margin-top: 20px;
  }
  .surchargeGrouplayout .contentBlock {
    width: 300px;
  }

  .surchargeGrouplayout .contentBlock .override-input {
    width: 230px;
  }

  .surchargeGroup-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .surchargeGroup-Dialog .addGrouplayout {
    height: 100px;
  }

  .surchargeGroupWrapper .submitBlock {
    margin-top: 30px;
    text-align: center;
  }

  .surchargeGroupWrapper .ant-btn-primary {
    width: 130px;
    height: 40px;
    font-size: 18px;
  }

  /* 채팅 */
  .Modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.56);
    z-index: 10;
  }

  .Modal-chat {
    z-index: 20;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    height: 650px;
    display: flex;
  }

  .Modal-chat .search-wrapper {
    display: flex;
    flex-direction: center;
    /* margin-top: 20px; */
  }

  .Modal-chat .search-wrapper .search-btn {
    width: 50%;
    height: 50px;
    font-size: large;
    background-color: black;
    color: #fddc00;
  }

  .Modal-chat .search-wrapper .ant-btn:hover,
  .Modal-chat .search-wrapper .ant-btn:focus {
    color: #fddc00;
    background: black;
    border-color: none;
  }

  .error-message {
    color: red;
  }

  td.ant-table-column-sort {
    background: none;
  }

  .ant-table-filter-trigger-container-open,
  .ant-table-filter-trigger-container:hover,
  .ant-table-thead
    th.ant-table-column-has-sorters:hover
    .ant-table-filter-trigger-container:hover {
    background: none;
  }
  .ant-table-filter-trigger-container-open .ant-table-filter-trigger,
  .ant-table-filter-trigger:hover {
    color: #bfbfbf;
  }

  .regist-bike-title {
    display: 'inline-block';
    width: 80px;
  }
  .bike-type-box .ant-picker {
    width: 165px;
  }

  .marker-name {
    font-family: NotoSansBold;
  }

  /* 배송지역 모달 */

  .zone-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: 320px;
    max-height: 90%;
    overflow: scroll;
  }

  .zone-content {
    padding: 10px;
    background-color: white;
  }

  .zone-content > div:first-child {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    display: inline-block;
    vertical-align: middle;
  }

  .zone-content > img {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }
  .zone-inner {
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
    margin-top: 20px;
  }
  .zone-inner > div {
    display: inline-block;
  }

  .inner-left > div:first-child {
    border-top: none;
    padding-top: 0px;
  }

  .inner-left > div:nth-child(2n) {
    margin-bottom: 10px;
  }
  .inner-left > div:nth-child(6),
  .inner-left > div:nth-child(7) {
    display: inline-block;
    margin-bottom: 0px;
  }

  .zone-title {
    font-size: 20px;
    font-weight: bold;
    color: #1890ff;
    border-top: 1px solid #b5b5b5;
    margin-bottom: 10px;
    width: 265px;
  }

  .radio-btn {
    float: right;
  }

  .zone-box {
    display: inline-block;
    text-align: center;
  }
  .zone-box > div {
    display: inline-block;
  }
  .zone-el {
    height: auto;
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 5px;
    border: none;
    margin: 5px;
    font-size: 16px;
    box-shadow: none;
    display: inline-block;
    cursor: pointer;
  }
  .zone-el-new {
    height: auto;
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 5px;
    border: none;
    margin: 5px;
    font-size: 16px;
    box-shadow: none;
  }
  .zone-el:hover {
    color: black;
  }
  .zone-el-active {
    background-color: black;
    color: #fddc00;
    height: auto;
    border-radius: 5px;
    padding: 5px;
    border: none;
    margin: 5px;
    font-size: 16px;
    box-shadow: none;
    display: inline-block;
    cursor: pointer;
  }

  .zone-input {
    display: inline-block;
  }

  /* flex 모달 css */
  .flex-wrapper {
    width: 320px;
    padding: 0.65rem;
  }

  .flex-inner {
    margin-top: 1.25rem;
    padding: 0.5rem;
  }

  .dialog-close {
    width: 2rem;
    height: 2rem;
    background-color: #fddc00;
    cursor: pointer;
    padding: 8px;
  }
  .contentBox {
    display: flex;
    flex-direction: column;
  }

  /*주문공유설정*/
  .order-share-icon-on {
    width: 29.8%;
  }

  .order-share-icon-off {
    width: 29.8%;
  }

  /* 시간지연 dialog */

  .timeDelay-box > button {
    width: 31%;
    padding: 0.3rem;
    margin-left: 0.3rem;
    margin-bottom: 0.3rem;
  }

  .timeDelay-btn-box {
    margin-top: 2rem;
  }

  .timeDelay-btn .ant-checkbox-input {
    height: 40px;
  }

  /* 할증 dialog */
  .flex-wrapper.surcharge-dialog {
    max-height: 90%;
  }
  .surcharge-inner {
    display: block;
    overflow-x: scroll;
  }
  .surcharge-box {
    padding: 0.1rem;
  }
  .surcharge-box .listTable {
    min-width: 440px;
    overflow-x: scroll;
  }
  .surcharge-box .searchRequirement {
    margin-top: 0 !important;
    margin-left: 10px;
    margin-right: 0 !important;
  }
  .surcharge-box .checkbox {
    display: inline-block;
    font-size: 1rem;
    font-weight: bold;
    vertical-align: -1px;
  }
  .surcharge-inner .contentBox {
    margin-top: 0.4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .surcharge-inner .contentBox > div {
    margin: 0.2rem 0;
  }
  .surcharge-inner .contentBox > div .checkboxInput {
    display: inline-block;
  }

  .insertBox .inputB {
    width: 13rem;
    margin-bottom: 0.3rem;
  }

  .inputB .ant-form-item {
    margin-right: 0rem !important;
  }

  .priceText {
    margin-left: 0px !important;
  }

  .dateBox .ant-picker-input > input {
    text-align: center;
    width: 3.3rem;
  }
  .surcharge-box .selectItem {
    display: inline-block;
  }
  .radioBox .search-input {
    display: inline-block;
    margin-left: 1rem;
  }
  .radioBox .override-input.sub {
    text-align: center;
    display: inline-block;
    width: 8rem !important;
    margin-left: 1.2rem !important;
  }

  .zone-text {
    margin-bottom: 0.4rem;
    padding-top: 0.4rem;
    width: 17.5rem;
  }
  .zoneBox {
    flex-wrap: wrap;
  }
  .zoneBox-wrap {
    width: 17.5rem;
    overflow-y: scroll;
    height: 12.75rem;
  }

  .zone-b {
    width: auto;
    height: auto;
    padding: 0.3rem;
  }
  .zone-b-new {
    width: auto;
    height: auto;
    padding: 0.3rem;
  }

  .zone-b-active {
    width: auto;
    height: auto;
    padding: 0.3rem;
  }

  .navermap-region {
    display: inline-block;
    margin-top: 0.7rem;
    width: 100%;
    height: 18.75rem;
  }
  .zoneBox-inner-right {
    width: 100%;
  }

  .contentBlock .search-address-Dialog {
    width: 260px;
  }
  .search-address-Dialog-scroll {
    width: 260px;
    margin-left: 0px;
  }

  .rider-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    z-index: 999;
  }
  .rider-content {
    background-color: white;
  }

  .rider-title {
    font-size: 15px;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
  }

  .rider-close {
    width: 30px;
    cursor: pointer;
    background-color: #fddc00;
    /* display: inline-block; */
    float: right;
    padding: 8px;
  }

  .rider-inner {
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    text-align: left;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .rider-inner .ant-table-tbody > tr > td {
    width: 50%;
    font-size: 12px;
  }
  .rider-inner .ant-pagination > li {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }

  .selPoopPoint {
    margin-left: 20px !important;
  }

  .payablePoopPoint-sub-title {
    letter-spacing: -1px;
    color: #333;
    text-align: left;
    font-size: 15px;
    line-height: 40px;
  }

  .payablePoopPoint-sub-title-gray {
    letter-spacing: -1px;
    color: #a6a6a6;
    text-align: left;
    font-size: 15px;
    line-height: 40px;
  }

  .payablePoopPoint-content {
    display: inline-block;
    text-align: right;
    font-size: 15px;
    width: 10%;
    color: #333;
  }

  .payablePoopPoint-content-gray {
    display: inline-block;
    text-align: right;
    font-size: 15px;
    width: 10%;
    color: #a6a6a6;
  }

  .rider-login-Dialog2 {
    position: fixed;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
    width: 600px;
    z-index: 999;
  }
  .franchise-Dialog2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    z-index: 999;
  }
  .franchise-login-Dialog2 {
    position: fixed;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
    width: 600px;
    z-index: 999;
  }

  .systemKey-Dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 365px;
    height: 75%;
    z-index: 40;
    max-height: 90%;
    overflow: scroll;
  }
  .systemKey-content {
    padding: 30px;
    background-color: white;
  }
  .systemKey-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    /* width:300px; */
    display: inline-block;
    vertical-align: middle;
  }
  .surcharge-close {
    width: 30px;
    background-color: #fddc00;
    padding: 8px;
    cursor: pointer;
    float: right;
  }
  .systemKey-inner {
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
    margin-top: 15px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .systemKey-inner::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .systemKey-Dialog .systemKeyWrapper > div {
    vertical-align: top;
  }
  .create-control-form-item-title {
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    width: 60px;
  }
  .create-control-form-item-title2 {
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    width: 80px;
  }
  .create-control-form-sub {
    width: 100%;
    text-align: left;
  }
  .create-control-form-sub2 {
    width: 100%;
    text-align: left;
  }
  .create-control-form-item-auth {
    width: 50%;
  }

  .create-control-form-button {
    width: 45%;
    background-color: #fddc00;
    color: #1a1a1a;
    border-color: #696968;
    text-align: center;
  }


}
