.ant-table-content thead th {
  /* 흐릿하게 */
  /* background-color:rgba( 253, 220, 0, 0.4 ) !important; */
  background-color: black !important;
  color: white !important;
  letter-spacing: -1.5px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #fddc00 !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #fddc00 !important;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #fddc00 !important;
}

.ant-input-search-button {
  background-color: #222 !important;
  border-color: #222 !important;
}
.ant-table-tbody > tr > td {
  padding: 2px !important;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
  margin: 0px !important;
}
.ant-table-thead > tr > th {
  padding: 5px !important;
}
.ant-table-expanded-row .ant-table-thead > tr > th {
  background-color: #e0e0e0 !important;
  color: #000 !important;
}
.ant-table-expanded-row .ant-table-wrapper {
  margin-bottom: 30px !important;
}

#reception-table
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0 8px !important;
}

.fr-order-agency-popover {
  display: block;
}
.scroll-bar-hidden .ant-table-body {
  scrollbar-width: none;
}
