@media screen and (min-width: 1200px) {
  .desk {
  }

  .mobile {
    display: none !important;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }

  .tooltip .tooltipText {
    visibility: hidden;
    width: 200px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    margin-left: 85px;
    z-index: 2;
  }

  .tooltip:hover .tooltipText {
    visibility: visible;
  }

  .franchiseContainer {
    /* position: fixed;
    width: 100%;
    left: 15px;
    top: 80px;
    right: 0;
    z-index: 1000; */
  }

  .franchiseContainer .selectLayout {
    margin-top: -5px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    display: inline-block;
    background-color: white;
    z-index: 100;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .selectLayout-box {
    flex-wrap: nowrap;
  }

  .franchiseContainer .override-select.fran {
    width: 220px;
    margin-left: 10px;
  }

  #franchise-table .ant-table .ant-table-thead {
    position: sticky;
    top: 127px;
    z-index: 2;
  }

  .searchTab {
    margin-left: 0px !important;
  }

  .searchFranchiseInput {
    width: 200px !important;
    margin-left: 15px;
  }

  .exel img {
    width: 20px;
    margin-right: 5px;
    margin-bottom: 3px;
  }

  .excelUpload {
    display: flex;
    margin-left: 10px;
  }

  .frStatus {
    float: right;
    /* margin-top: -35px; */
    margin-right: 15px;
  }

  .fr-status {
    display: inline-block;
    width: 120px;
    font-size: 15px;
    border: 1px solid #d1d1d1;
    text-align: center;
    /* margin-right: 15px; */
    margin-left: 15px;
    border-radius: 5px;
    height: 32px;
    line-height: 32px;
  }

  /* 상세 */
  /* .franchiseContainer .ant-table-tbody > tr > td >
.ant-table-wrapper:only-child .ant-table{
    margin: 0px;
} */

  /* .franchiseContainer .ant-input-group-wrapper{
    vertical-align: middle;
} */
}
