@tailwind base;
@tailwind components;
@tailwind utilities;

@media screen and (min-width: 1200px) {
  .desk {
  }

  .mobile {
    display: none !important;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: NotoSansRegular;
    height: 100%;
  }

  #root {
    height: 100%;
  }

  .m-l-10 {
    margin-left: 10px;
  }

  .m-r-2 {
    margin-right: 8px;
  }

  .m-r-10 {
    margin-right: 10px;
  }

  .m-t-10 {
    margin-top: 10px !important;
  }

  .m-t-m13 {
    margin-top: -13px !important;
  }

  .m-t-20 {
    margin-top: 20px !important;
  }

  .m-b-0 {
    margin-bottom: 0px !important;
  }

  .m-b-1 {
    margin-bottom: 4px !important;
  }

  .m-b-2 {
    margin-bottom: 8px !important;
  }

  .m-b-10 {
    margin-bottom: 10px !important;
  }

  .width-50 {
    width: 50%;
  }

  .flex-1 {
    flex: 1;
  }

  .flex-row {
    display: flex;
  }

  .flex-column {
    flex-direction: column;
  }

  .flex-sb {
    justify-content: space-between;
  }

  .flex-end {
    justify-content: flex-end;
  }

  .flex-center {
    align-items: center;
  }

  .justify-center {
    justify-content: center;
  }

  .pointer {
    color: blue;
    cursor: pointer;
  }
  .ant-descriptions-item-label {
    background-color: #cef1ce !important;
    border: 1px #bbb solid !important;
  }
  .ant-descriptions-item-content {
    background-color: #fff !important;
    border: 1px #bbb solid !important;
  }
  .ant-table-thead > tr > th {
    /* text-align: center; */
    background-color: #cee2f1;
    /* border: 1px solid #c2c2c0;
  font-weight: 530; */
  }

  .table-column-text {
    word-break: break-all;
  }

  .table-column-center {
    text-align: center !important;
  }
  .table-column-hide {
    display: none;
  }

  .c-p-10 {
    padding: 0px 10px;
    text-align: center;
  }

  .c-p-15 {
    padding: 0px 10px;
    text-align: center;
  }

  .c-p-20 {
    padding: 0px 20px;
    text-align: center;
  }

  .c-p-25 {
    padding: 0px 25px;
    text-align: center;
  }

  .c-p-30 {
    padding: 0px 30px;
    text-align: center;
  }

  /* .table-column-surGroup{
  text-align: center;
  width: 50%;
} */

  .table-column-left {
    display: inline-block;
    width: 550px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    height: 1.5em;
    -webkit-box-orient: vertical;
    line-height: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  .table-column-left-only {
    text-align: left !important;
  }

  .table-column-right-only {
    text-align: right !important;
  }

  .table-column-content {
    text-align: left;
    border: 1px solid #d1d1d1;
    background-color: #f5f5f5;
    margin-bottom: 10px;
    padding: 10px 15px 10px 20px;
    word-break: keep-all;
  }

  .detailModal-wrapper {
    /* border: 1px solid #696968; */
    border-radius: 0px;
    padding: 20px;
  }

  .detailRegistryModal-wrapper {
    border: 1px solid #696968;
    border-radius: 0px;
    padding: 20px;
  }

  .grid-title {
    border: 1px solid #cfcfcf;
    background-color: #cee2f1;
    text-align: center;
    height: 30px;
  }

  .grid-content {
    border: 1px solid #cfcfcf;
    padding-left: 10px;
  }

  .grid-content-bottom {
    border-bottom: 1px solid #cfcfcf;
    border-right: 1px solid #cfcfcf;
    padding-left: 10px;
  }

  .grid-content-subText > {
    min-height: 800px;
    margin-left: 10px;
  }

  .width-1240 {
    min-width: 1000px;
  }

  /* system*/
  .block-chain-input {
    width: 200px;
  }

  .menu-wrapper {
    height: 63px;
  }
  .menu-right {
    position: absolute;
    right: 30px;
    top: 0px;
  }
  .top-menu {
    display: inline-block;
    margin: 0px 15px;
    padding: 0px 15px;
    cursor: pointer;
    font-family: NotoSansRegular;
    font-size: 18px;
    color: #fff;
    font-weight: normal;
  }
  .top-menu:hover {
    color: #fddc00;
    border-bottom: solid #fddc00;
    height: 80%;
  }
  .top-menu.active {
    color: #fddc00;
    border-bottom: solid #fddc00;
    height: 80%;
  }

  .login-container {
    max-width: 1200px;
    margin: 100px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-bottom: 200px;
  }

  .login-img {
    display: inline-block;
    vertical-align: top;
  }
  .login-form {
    text-align: center;
    width: 380px;
    padding: 50px;
    display: inline-block;
    background-color: #fddc00;
    height: 753px;
  }
  .login-logo {
    margin-top: 100px;
  }
  .login-system-name {
    font-size: 28px;
  }
  .login-input {
    margin-top: 40px;
    text-align: left;
  }
  .login-input-item {
    height: 40px;
    font-size: 16px;
  }
  .login-version {
    margin-top: 120px;
    text-align: right;
  }
  .login-form-button {
    background-color: #000;
    color: #fff;
    border-width: 0px;
    width: 100%;
    height: 40px;
    font-size: 16px;
  }
  .login-form-button:hover {
    background-color: #fff;
    color: #fddc00;
    border-width: 0px;
  }
  .top-input-checkbox {
    margin-top: 0px;
    padding-left: 5px;
    text-align: left;
  }
  .top-input-checkbox-text {
    margin-left: 5px;
    display: inline-block;
    font-size: 16px;
    letter-spacing: -1px;
  }

  .inline-formitem {
    display: inline-block;
    margin-bottom: 0;
  }

  .multi-depth-menuitem {
    padding-left: 60px !important;
  }

  .table-disabled {
    background-color: #eeeeee;
  }
  .font-red {
    color: #ff0000;
  }
  .text-ellipsis {
    max-width: 180px;
    white-space: nowrap;
    /* width: 180px; */
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .batch {
    font-size: 13px;
    color: green;
  }
  .logo {
    width: 300px;
    margin-bottom: 50px;
  }

  table {
    visibility: visible !important;
  }

  .ant-layout-footer {
    width: 100vw;
  }
}


/* 정산내역 상세 */
#settlement {
  max-width: 1920px;
  /*margin: 0 auto;*/
}
.selectMonthTable{
  width: 400px;
}
.selectMonthTable > div> div {
  flex: 1;
}
.settleDetail {
  display: flex;
  justify-content: space-between;
}

.settleDetailContent {
  width: 32%;
}
.settleDetailTitle {
  font-size: 24px;
  font-weight: bold;
  font-family: 'NotoSansBold';
}

.settleDetailBox {
  border: 1px solid #D6D6D6;
  border-radius: 3px;
  height: 500px;
}

.settleDetailCategoryBox {
  display: flex;
  justify-content: space-between;
  padding: 8px 26px;
  align-items: center;
  cursor: pointer;
}

.settleDetailDiv {
  width: 30%;
  font-weight: bold;
  font-size: 16px;
  word-break: keep-all;
}

.settleDetailCount {
  width: 25%;
  text-align: right;
  font-size: 16px;
}

.settleDetailTotal {
  width: 40%;
  text-align: right;
  font-size: 16px;
}

.settleDetailSumBox {
  display: flex;
  justify-content: space-between;
  padding: 10px 26px;
  color: #fff;
  background-color: #4EA6EB;
  border-radius: 3px;
  font-weight: bold;
  align-items: center;
}
.settleSubContent {
  width: 32%;
  border: 1px solid #D6D6D6;
  border-radius: 3px;
  cursor: pointer;
}
.subTitle{
  background-color: #d8d8d8;
  width: 100%;
  height: 50%;
  font-size: 16px;
  padding: 8px 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subContent{
  height: 50%;
  padding: 8px 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subContent > p, .settleBottomBox > p, .settleDetailDiv > p {
  margin: 0px;
  padding: 0px;
}
.subContent.transaction {
  border-bottom: 1px solid #d6d6d6;
}
.subNcash{
  font-size: 24px;
  color: #f00000;
  font-weight: bold;
}
.subContent > p:first-child{
  font-size: 16px;
}
.transaction-title {
  font-weight: bold;
}
.subContent.transaction > .subNcash > p {
  font-size: 24px;
  color: #f00000;
  font-weight: bold;
}
.seperateBar{
  height: 2px;
  background-color: #797979;
  margin-top: 10px;
}
.settleDetailSub{
  font-size: 12px;
  margin-top: -6px;
  margin-bottom: -4px;
}
.settleDetailPrice{
  margin: 0px;
  padding: 0px;
}
.settleDetailMisu{
  font-size: 12px;
  margin-top: -4px;
  margin-bottom: -4px;
  color: #f00000;
}
#settlementDetail {
  width: 100%;
  height: 100%;
}
.spin-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 100;
}
.spin{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
/* 정산내역 대리점 입/출금 */
.settleWithdrawTop {
  display: flex;
  justify-content: space-between;
}
.copyRegionException-noti{
  border: 1px solid #ccc;
  text-align: center;
  margin-top: 10px;
}
.copyRegionException-container{
  overflow-y:scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#ncashCompanyList{
  /*padding: 20px;*/
}
.ncl-menu-wrap{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ncl-menu-totalsum{
  background-color: #eee;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px;
  font-family: 'NotoSansBold' ;
}
#ncashCompanyList .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
#agencyTaxReturn .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  font-size: 12px;
  border-color: #fff;
  padding: 0
}
.ncl-select-box{
  display: flex;
  align-items: center;
  width: 180px;
  height: 40px;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  margin-left: 10px;
  padding-left: 10px;
}
.ncl-input-box{
  border: 1px solid #c1c1c1;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.ncl-input-box > input::placeholder{
  color: #c1c1c1;
}
.ncl-input-box > input:focus,
.ncl-input-box > input:active,
.ncl-input-box .ant-picker-input > input:focus,
.ncl-input-box .ant-picker-input > input:active,
.ncl-input-box .ant-select:focus,
.ncl-input-box .ant-select:active,
.ncl-input-box .ant-select-focused{
  border: none;
  outline: none;
  box-shadow: none;
}
#ncashCompanyList .ant-checkbox-group-item {
  margin-right: 0
}
.ncl-excel-box{
  margin-left: auto;
  border: 1px solid #005929;
  height: 40px;
  background: #217D4B;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}
.ncl-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FADC00;
  border: 1px solid #C1C1C1;
  color: #000;
}
.ncl-checkbox .ant-checkbox-checked .ant-checkbox-inner:after{
  position: absolute;
  display: table;
  border: 2px solid #000;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%,-50%);
  opacity: 1;
  transition: all .2s cubic-bezier(.12,.4,.29,1.46) .1s;
  content: " ";
}
.ncl-table{
  margin: 20px 0;
}
.ncl-table .ant-table .ant-table-content .ant-table-tbody > tr > td {
  border-right: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
  line-height: 36px;
  padding-right: 10px;
}
.ncl-table .ant-table .ant-table-content .ant-table-tbody > tr > td:first-child{
  border-left: 1px solid #c1c1c1;
}
.ncl-table .ncl-table-row,
.agency-tax-return-detail-table .ncl-table-row {
  background-color: #f9f9f9;
}

/*세금신고*/
#agencyTaxReturn {
  padding: 0 15px;
  height: 100%;
}
.agency-tr-layout{
  display: flex;
  flex-direction: column;
  height: calc(100vh - 135px);
}
.agency-btn {
  color: #fff;
  margin-left: 10px;
  background-color: #217D4B;
  border-color: #005929;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  border-radius: 5px;
}
.agency-tr-tbl{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
}
.agency-tr-table-container{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0;
  height: 100%;
}
.agency-tr-main-table-columns,
.detail-tbl-columns{
  flex-basis: 42px;
  flex-grow: 0;
}
.agency-tr-main-table-content2 {
  min-height: 160px;
  border: 1px solid #c1c1c1;
  border-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.agency-tr-main-table-content,
.detail-tbl
{
  flex-basis: 160px;
  overflow-y: scroll;
  flex-grow: 1;
  border: 1px solid #c1c1c1;
  border-top: 0;
}
.agency-tr-main-table-content::-webkit-scrollbar,
.detail-tbl::-webkit-scrollbar{
  width: 10px;
  background-color: #f9f9f9;
}
.agency-tr-main-table-content::-webkit-scrollbar-thumb,
.detail-tbl::-webkit-scrollbar-thumb{
  background-color: rgba(0,0,0,0.2);
}
.agency-tr-main-table-content::-webkit-scrollbar-track,
.detail-tbl::-webkit-scrollbar-track{
  background-color: rgba(0,0,0,0.1);

}
.agency-tr-main-table-footer {
  flex-basis: 50px;
  flex-grow: 0;
}
.agency-tr-main-table-columns{
  display: flex;
  align-items: center;
  border: 1px solid #c1c1c1;
  background-color: #eee;
}
.agency-tr-main-table-columns > div,
.agency-tr-main-table-row > div{
  flex: 1;
  min-height: 42px;
  max-width: 300px ;
  font-size: 12px;
  text-align: center;
  border-right: 1px solid #c1c1c1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.agency-tr-main-table-columns > div,
.detail-tbl-columns > div{
  word-break: keep-all;
}
.agency-tr-main-table-row > div {
  word-break: break-all;
}
.agency-tr-main-table-columns > p:last-child {
  width: 9.5px;
  height: 100%;
  margin: 0;
}
.agency-tr-main-table-columns.fr > p:last-child {
  background-color: #DAF8FF;
}

.agency-tr-main-table-row > div,
.detail-tbl-item > div
{
  padding: 4px;
  align-self: stretch;
}
.agency-tr-main-table-columns > div:last-child,
.agency-tr-main-table-row > div:last-child{
  border-right: 0;
}
.agency-tr-main-table-row{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid #c1c1c1;
}
.agency-tr-main-table-row:hover{
  background-color: #f9f9f9;
  cursor: pointer;
}
.agency-tr-main-table-row > .font-a-r {
  display: flex;
  justify-content: flex-end;
}
.agency-tr-main-table-row.odd,
.detail-tbl-item.odd{
  background-color: #f9f9f9;
}
.font-a-r > span{
  margin-right: 6px;
}
.agency-tr-main-table-footer{
  background-color: #222;
  display: flex;
  align-items: center;
  padding-right: 6px;

}
.agency-tr-main-table-footer > div {
  height: 30px;
  border-radius: 5px;
  font-size: 11px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 5px;
  flex: 1;
}
.agency-tr-main-table-footer > .totalBox {
  background-color: #fff;
  font-weight: bold;
  text-align: right;
}

.agency-tax-return-table-footer{
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding-right: 10px;
}
.agency-tax-return-table-footer > div {
  flex: 1;
  height: 30px;
  border-radius: 5px;
  font-size: 11px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.agency-tr-main-table-columns.fr > div:nth-child(n+6):nth-child(-n+7){
  background-color: #FEEED5 !important;
}
.agency-tr-main-table-row.fr > div:nth-child(n+6):nth-child(-n+7) {
  background-color: #FFFBF4 !important;
}
.agency-tr-main-table-columns.fr > div:nth-child(n+8):nth-child(-n+11){
  background-color: #E4FFDE !important;
}
.agency-tr-main-table-row.fr > div:nth-child(n+8):nth-child(-n+11) {
  background-color: #F6FFF4 !important;
}
.agency-tr-main-table-columns.fr > div:nth-child(n+12):nth-child(-n+14){
  background-color: #DAF8FF !important;
}
.agency-tr-main-table-row.fr > div:nth-child(n+12):nth-child(-n+14) {
  background-color: #F4FDFF !important;
}
.agency-tr-main-table-columns.rider > div {
  background-color: #eee !important;
}

#agencyTaxReturn .ant-table-footer,
.footer-container{
  background-color: #222;
  padding: 0;
  height: 50px;
  /*min-width: 1080px;*/
  /*line-height: 50px;*/
  display: flex;
  align-items: center;
  justify-content: center;
}
.agency-tax-return-table-footer{
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding-right: 6px;
}
.agency-tax-return-table-footer > div {
  flex: 1;
  height: 30px;
  border-radius: 5px;
  font-size: 11px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 5px;
}
.agency-tax-return-table-footer > .totalBox {
  background-color: #fff;
  font-weight: bold;
}
#agencyTaxReturn .agency-tax-return-table .ant-table-tbody{
  margin-right: 10px;
}
#agencyTaxReturn .ant-table-tbody > tr > td.tax-return-table-column-right{
  text-align: right;
  padding-right: 10px !important;
}
#agencyTaxReturn .ant-table-tbody > tr > td.tax-return-table-column-center {
  text-align: center;
}
#agencyTaxReturn .detail-tbl-columns{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c1c1c1;
  background-color: #eee;
}
#agencyTaxReturn .detail-tbl-columns > div {
  border-right: 1px solid #c1c1c1;
  font-size: 12px;
  min-height: 42px;
  max-width: 250px;
  flex: 1;
  text-align: center;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
}
#agencyTaxReturn .detail-tbl-columns > div:first-child{
  border-right: 1px solid #c1c1c1;
}
.detail-tbl-columns > p:last-child {
  width: 9.5px;
  margin: 0;
}
/*#agencyTaxReturn .detail-tbl-columns > div:last-child{*/
/*  background-color: inherit;*/
/*  border: 0;*/
/*  min-width: 15px;*/
/*  max-width: 15px;*/
/*  flex: 0.1;*/
/*}*/
#agencyTaxReturn .detail-tbl {
  border: 1px solid #c1c1c1;
  border-top: 0;
  min-height: 100px;
  font-size: 12px;
  height: 900px;
  overflow-y: scroll;
}
#agencyTaxReturn .detail-tbl-date {
  background-color: #FEFFDA;
  line-height: 42px;
  padding: 0 10px;
  border-bottom: 1px solid #c1c1c1;
  font-weight: bold;
}
#agencyTaxReturn .detail-tbl-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c1c1c1;
}
#agencyTaxReturn .detail-tbl-item > div{
  border-right: 1px solid #c1c1c1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
  min-height: 42px;
  max-width: 150px;
  align-self: stretch;
}
#agencyTaxReturn .detail-tbl-item > div:last-child{
  border-right: 0;
}
#agencyTaxReturn .detail-tbl-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #c1c1c1;
  height: 42px;
}
#agencyTaxReturn .detail-tbl-total > div {
  flex: 1;
}
  /*#agencyTaxReturn .detail-tbl-total > div {*/
/*  flex: 1;*/
/*}*/
#agencyTaxReturn .detail-tbl-total-columns {
  display: flex;
  align-items: center;
}
#agencyTaxReturn .detail-bold {
  flex: 1;
  text-align: right;
  font-weight: bold;
}
#agencyTaxReturn .detail-bold > span {
  margin-right: 10px;
}
.ncl-input-box.taxReturn{
  display: flex;
  align-items: center;
  justify-content: center;
}
.ncl-input-box.taxReturn > div {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shareCallBtn.active {
  background-color: #FADC00;
  font-weight: bold;
}
.shareCallBtn{
  cursor: pointer;
}

.ncl-table.delivery .ant-table .ant-table-container .ant-table-tbody > tr > td {
  line-height: inherit;
  padding: 0 6px;
  min-width: 90px;
}

.shareCallBtn.active {
  background-color: #FADC00;
  font-weight: bold;
}
.shareCallBtn{
  cursor: pointer;
}