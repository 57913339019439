@media screen and (max-width: 1199px) and (min-width: 300px) {
  .desk {
    display: none !important;
  }

  .mobile {
  }

  .ant-table-content thead th {
    /* 흐릿하게 */
    /* background-color:rgba( 253, 220, 0, 0.4 ) !important; */
    background-color: black !important;
    color: white !important;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #fddc00 !important;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: #fddc00 !important;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #fddc00 !important;
  }

  .ant-input-search-button {
    background-color: #222 !important;
    border-color: #222 !important;
  }
  .ant-table-tbody > tr > td {
    padding: 2px !important;
  }
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: 0px !important;
  }
  .ant-table-thead > tr > th:first-child {
    padding: 5px !important;
    height: 40px !important;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    /* width:5%!important; */
    /* border:1px solid #fff; */
    width: 20%;
  }

  .ant-table-expanded-row .ant-table-thead > tr > th {
    background-color: #e0e0e0 !important;
    color: #000 !important;
  }
  .ant-table-expanded-row .ant-table-wrapper {
    margin-bottom: 10px !important;
  }

  .ant-modal-content {
    width: 280px;
    position: fixed;
    left: 50%;
    top: 10%;
    transform: translate(-50%, -10%);
    /* top:10%;
    left:30%; */
  }
}
