.riderContainer
  .ant-table-tbody
  > tr
  > td
  > .ant-table-wrapper:only-child
  .ant-table {
  margin: 0px;
}

.searchRiderInput {
  width: 200px !important;
  margin-left: 15px;
}

.riderMain-container .selectLayout {
  margin-top: -5px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  display: inline-block;
  background-color: white;
  z-index: 100;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.selectLayout-box {
  flex-wrap: nowrap;
}
.riderGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.riderGroup > div {
  margin: 0 10px;
}

.riderGroup-span {
  color: #d1d1d1;
}

#rider-table .ant-table .ant-table-thead {
  position: sticky;
  top: 127px;
  z-index: 2;
}

@media screen and (min-width: 1200px) {
  .rider-filtering-box {
    border: 1px solid #d1d1d1;
    display: flex;
    width: 770px;
    margin-top: 5px;
  }
  .riderStatus {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .rider-status {
    width: 110px;
    font-size: 14px;
    border: 1px solid #d1d1d1;
    text-align: center;
    border-radius: 5px;
    height: 32px;
    line-height: 32px;
  }

  .excel img {
    width: 20px;
    margin-right: 5px;
    margin-bottom: 3px;
  }
}
