
/*Figma*/
.nn-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.56);
    z-index: 999;
}

.nn-modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    background-color: white;
    border-radius: 20px;
    transform: translate(-50%, -50%);
}

.nn-modal-square-container {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    background-color: white;
    transform: translate(-50%, -50%);
}

.nn-modal-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    margin: 0 20px 0 20px;
    font-weight: bold;
    font-family: 'Noto Sans';
    font-size: 18px;
}

.nn-modal-title-divider {
    border-bottom: 1px solid #c1c1c1;
}

.nn-modal-sidebar-scroll-container {
    overflow-y: auto;
    height: calc(100% - 134px);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.nn-modal-content-wrapper {
    padding: 20px;
}

.nn-modal-input-label {
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: bold;
    font-family: 'Noto Sans';
}

.nn-modal-input {
    height: 40px;
    border-radius: 5px;
}

.nn-modal-input-wrapper {
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    padding: 20px 20px 12px 20px;
    margin-bottom: 20px;
    max-height: 500px;
    overflow-y: auto;
}

.nn-modal-border-left {
    border-left: 1px solid #c1c1c1;
}

.nn-modal-border-bottom {
    border-bottom: 1px solid #c1c1c1;
}

/*font*/
.nn-noto-font-bold {
    font-weight: bold;
    font-family: 'Noto Sans';
}

/*btn*/
.nn-modal-button {
    border-radius: 5px;
    border-color: #c1c1c1;
    border-width: 1px;
    border-style: solid;
    font-weight: bold;
    font-family: 'Noto Sans';
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*icon*/
.nn-modal-close {
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;
    content: '';
}

.nn-modal-close:before {
    position: absolute;
    content: ' ';
    height: 20px;
    width: 2px;
    right: 15px;
    background-color: #000;
    transform: rotate(45deg);
}

.nn-modal-close:after {
    position: absolute;
    content: ' ';
    height: 20px;
    width: 2px;
    right: 15px;
    background-color: #000;
    transform: rotate(-45deg);
}

.nn-left-arrow {
    width: 12px;
    height: 12px;
    border-top: 2px solid black;
    border-left: 2px solid black;
    transform: rotate(-45deg);
}

.nn-right-arrow {
    width: 12px;
    height: 12px;
    /*border-top: 2px solid black;*/
    /*border-left: 2px solid black;*/
    /*transform: rotate(-45deg);*/
    border-top: 2px solid black;
    border-right: 2px solid black;
    transform: rotate(45deg);
}
/*naverMap*/
.nn-map-side-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 60px;
    background-color: white;
    transform: translateY(-50%);
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-top: 1px solid #C1C1C1;
    border-right: 1px solid #C1C1C1;
    border-bottom: 1px solid #C1C1C1;
    cursor: pointer;
}
.nn-map-name-marker-container {
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: white;
    border: 1px solid black;
    white-space : nowrap;
    z-index: 13;
    transform:translate(-50%,-75%);
}
.nn-map-name-marker-point {
    z-index: 14;
    position: absolute;
    background-color: white;
    bottom: -6px;
    transform: translateX(-50%) rotate(45deg);
    left: 50%;
    width: 10px;
    height: 10px;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
}
