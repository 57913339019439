@media screen and (min-width: 1200px) {
  .desk {
  }

  .mobile {
    display: none !important;
  }

  .ant-table-column-sorters {
    padding: 0px !important;
  }
  .reception-box .tabBtn {
    margin-left: 5px !important;
  }

  .reception-box .tabBtn-rt {
    margin-left: 2px;
    padding: 4px !important;
  }

  .ant-table-column-sorter {
    margin-left: 2px !important;
    height: 22px !important;
  }
  .ant-table-column-sorter-full {
    margin-top: -1rem !important;
  }

  .arriveArea {
    text-align: left;
    /* cursor: pointer; */
  }

  .shareTab {
    margin-left: 0px !important;
    margin-right: 5px;
  }

  .tabBtn {
    margin-left: 10px;
  }

  .orderTab {
    margin-right: 20px !important;
  }

  .selectLayout {
    margin-top: 10px !important;
    display: inline-block;
  }

  .filterTab {
    margin-left: 20px !important;
  }

  #react-naver-map {
    z-index: 10;
  }

  .receptionContainer .selectLayout {
    /* margin-top: -5px !important; */
    margin-top: -10px !important;
    padding-bottom: 10px !important;
    /* display: inline-block; */
    background-color: white;
    width: 100%;
    /* height: 140px; */
    height: 100%;
    padding-left: 10px;
    /* display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap; */
  }

  .selectLayout-box {
    flex-wrap: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .selectLayout_sub {
    margin: 5px 0;
    display: flex;
    /* flex-direction: row; */
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .selectLayout-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  .selectLayout-wrapper2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 5px;
  }

  /* 라이더 */
  .dataTableLayout {
    /* margin-top: 10px; */
    white-space: nowrap;
  }

  .dataTableLayout th .ant-table-column-sorters {
    padding: 16px 0px;
  }

  .dataTableLayout th .ant-table-column-sorter {
    margin-left: 0px;
    /* margin-right: 5px; */
  }

  .dataTableLayout .ant-table-thead {
    z-index: 0;
  }

  /* 가맹점 */
  .dataTableLayout2 {
    /* margin-top: 10px; */
    white-space: nowrap;
  }

  .dataTableLayout2 th .ant-table-column-sorters {
    padding: 16px 0px;
  }

  .dataTableLayout2 th .ant-table-column-sorter {
    margin-left: 0px;
    /* margin-right: 5px; */
  }

  .dataTableLayout2 .ant-table-thead {
    z-index: 0;
  }

  .riderTableLayout {
    position: absolute;
    top: 0px;
    right: 10px;
    left: 10px;
    z-index: 80;
  }

  .orderTab {
    margin-left: 0px !important;
  }

  .textLayout {
    text-align: center;
    height: 30px;
  }

  .riderText {
    /* margin-left: 230px; */
    font-size: 18px;
    font-family: NotoSansBold;
  }
  .mapLayout {
    width: 100%;
    height: 100vh;
    float: left;
  }
  .mapLayout2 {
    width: 730px;
    height: 200px;
    overflow: hidden;
    margin-bottom: 24px;
  }
  .mapLayout3 {
    width: 1000px;
    height: 200px;
    overflow: hidden;
    margin-bottom: 24px;
  }
  .regist-call-map {
    height: 200px;
    overflow: hidden;
  }
  /* .map-navermap {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    width: 100%;
    height: 100%;
  } */

  .riderName {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }

  .reception-box .reception-box .ant-checkbox-inner {
    width: 25px;
    height: 25px;
  }

  /* .reception-box .ant-checkbox-inner::after {
    width: 15px;
    height: 15px;
  } */

  .reception-box .ant-checkbox-wrapper {
    /* height: 32px; */
    margin-left: 10px !important;
    /* margin-top: 5px;
    vertical-align: top; */
  }

  .filtering-box1 .ant-checkbox-wrapper {
    margin-left: 0px !important;
  }

  .surcheck .ant-checkbox-wrapper {
    margin-left: 10px !important;
    height: 32px;
    line-height: 32px;
    vertical-align: middle;
    margin-bottom: 3px;
  }

  .reception-box .span1 {
    font-size: 15px;
    margin-left: 0px;
    vertical-align: top;
  }

  .table-red {
    background-color: rgb(255, 204, 204);
  }
  .table-redalert {
    background-color: #ff0000;
    color: white;
  }
  .table-redalert:hover {
    background-color: #fff;
    color: #000;
  }

  .table-gray {
    background-color: #c9c9c9;
  }

  .table-purple {
    background-color: #b1b6fb;
  }

  .table-blue {
    background-color: #d6edfe;
    /* color: white; */
  }

  .table-yellow {
    background-color: #b1b6fb;
  }

  .table-lightyellow {
    background-color: #ffffbf;
  }

  .table-orange {
    background-color: #fedeaa;
  }
  .table-pink {
    background-color: rgb(255, 194, 219);
    /* background-color: #ffcce6; */
  }

  .btnLayout {
    margin-top: 10px;
    /* width:100%; */
  }

  #reception-table {
    margin-top: 10px;
  }

  #reception-table .ant-table .ant-table-thead {
    position: sticky;
    /* top: 204px; */
    z-index: 2;
  }

  #reception-table .ant-table .ant-table-tbody .ant-table-thead {
    /* position: sticky; */
    /* top: 105px; */
    z-index: 1;
  }
  #reception-table .ant-table .ant-table-tbody > tr:hover > td {
    background-color: #d4fff1;
  }

  /* #reception-table .ant-table .ant-table-tbody > tr > td:first-child {
    padding-left: 20px !important;
  } */

  #reception-table .ant-table .ant-table-tbody > tr > td > div {
    letter-spacing: -1.4px;
  }

  #reception-table .ant-table .ant-table-tbody > tr > td:last-child {
    padding-right: 20px !important;
  }

  #reception-table .ant-table .ant-table-thead > tr > th:last-child {
    padding-right: 20px !important;
  }

  .ant-table .ant-table-thead > tr > th {
    background-color: black;
    color: white;
    line-height: 32px !important;
  }

  #reception-table .ant-table-measure-row > td:last-child {
    display: none;
  }
  .dataTableLayout2 .ant-table-measure-row > td:last-child {
    display: none;
  }
  #rider-table .ant-table-measure-row > td:last-child {
    display: none;
  }

  .ant-table .ant-table-thead > tr > th.ant-table-cell-scrollbar {
    display: none;
  }

  .tableSub {
    width: 150px;
  }

  .delivery-status-box {
    margin-left: 10px;
  }

  .delivery-status-box > div {
    display: inline-block;
    width: 7rem;
    font-size: 14px;
    text-align: center;
    margin-right: 0.3rem;
    border-radius: 5px;
    height: 33px;
    line-height: 33px;
  }

  .delivery-show-box {
    border: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    padding: 0 0 0 14px;
    height: 33px;
    margin-left: 5px;
  }
  .delivery-show-box > div {
    border-right: 1px solid #e0e0e0;
    padding-right: 14px;
  }
  .delivery-show-box > div > span {
    margin: 0 0.3rem;
    font-weight: bold;
  }
  .delivery-show-box input {
    border: none;
    text-align: center;
    margin-left: 14px;
  }
  .delivery-show-box input::placeholder {
    text-align: center;
  }
  .rider-status-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 33px;
    border: 1px solid #e0e0e0;
    padding: 0 8px;
  }

  .rider-status-box > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    height: 33px;
    border-right: 1px solid #e0e0e0;
  }

  .rider-status-box > div:nth-child(1) {
    font-size: 14px;
    border-right: 1px solid #e0e0e0;
    height: 22px;
    line-height: 22px;
    padding-right: 10px;
  }

  .rider-status-box > div:nth-child(4) {
    border-right: none !important;
  }

  .fr-status-box {
    display: inline-block;
    /* float: right; */
    margin-bottom: 5px;
  }
  .fr-status-box > div {
    display: inline-block;
    width: 125px;
    font-size: 15px;
    text-align: center;
    margin-right: 15px;
    margin-top: 15px;
    border-radius: 5px;
    height: 35px;
    line-height: 35px;
    border: 1px solid #d1d1d1;
  }
  .fr-status-box > div:nth-child(1) {
    border: none;
    width: 60px;
    margin-right: 5px;
  }
  /* 통계 */
  .orderStats {
    margin: 10px 0;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #d1d1d1;
    /* padding-bottom: 10px; */
  }
  .stats-input {
    width: 200px;
  }
  .stats-radio {
    margin-left: 20px;
    padding-top: 3px;
  }
  .stats-date {
    width: 350px;
  }

  .left-border-columns {
    border-left: 1px solid black;
  }
  .right-border-columns {
    border-right: 1px solid black;
  }
  .parent-column {
    text-align: center;
  }

  .stats-tables {
    padding: 10px;
    width: 49%;
    display: inline-block;
  }
  .stats-tables-not-total {
    padding: 10px;
    width: 100%;
    display: inline-block;
  }
  .stats-graph {
    width: 49%;
    height: 340px;
    padding: 15px;
    margin-left: 2%;
    display: inline-block;
    vertical-align: top;
    border: 10px solid #f5f5f5;
  }
  .stats-line {
    height: 290px;
  }

  .joinexit-stats-tables {
    padding: 10px;
    width: 49%;
    display: inline-block;
  }
  .joinexit-stats-tables2 {
    padding: 10px;
    width: 49%;
    overflow-y: scroll;
    text-align: left;
    border: 1px solid #ccc;
  }
  .joinexit-stats-graph {
    width: 49%;
    height: 660px;
    display: inline-block;
    vertical-align: top;
    border: 10px solid #f5f5f5;
  }
  .joinExitStats {
    margin: 1px 0;
    display: flex;
    border-bottom: 1px solid #d1d1d1;
    /* padding-bottom: 10px; */
  }
}
