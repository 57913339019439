.searchRequirement {
  margin-top: 10px !important;
}

.searchRequirementText {
  font-size: 15px;
  font-family: NotoSansBold;
  display: inline-block;
  margin-right: 10px;
  padding-left: 10px;
}

.registStaff {
  /* float: right; */
  background-color: #fddc00;
  font-family: NotoSansRegular;
  border: #fddc00;
}

.table-column-center .buyList-select {
  padding-left: -100px;
}
