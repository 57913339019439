@media screen and (max-width: 1199px) and (min-width: 300px) {
  .desk {
    display: none !important;
  }

  .mobile {
  }
  .reception-detail {
    margin-top: 20px;
    position: fixed;
    width: 100%;
    z-index: 100;
    background-color: #fff;
    padding: 0 10px;
  }

  .r-summary {
    font-weight: bold;
    line-height: 40px;
    font-size: 17px;
  }

  .receptionContainer .ant-affix {
    background-color: #fff;
    z-index: 1;
  }
  .arriveArea {
    text-align: left;
    cursor: pointer;
  }

  .shareTab {
    padding: 0px !important;
    width: 30vw;
  }
  .delayTab {
    width: 30vw;
    padding: 0px !important;
  }
  .mapTab {
    margin-left: 0px !important;
    width: 30vw;
    padding: 0px !important;
  }
  .surchargeTab {
    width: 30vw;
    padding: 0px !important;
  }
  .registTab {
    width: 30vw;
    padding: 0px !important;
  }
  .filterTab {
    width: 30vw;
    margin-left: 20px !important;
  }

  .riderTableLayout {
    position: absolute;
    top: 35px;
    left: 10px;
    right: 10px;
    z-index: 80;
  }
  .selectLayout-top {
    width: 100vw;
    margin: 15px 0 10px 0px;
  }
  .delivery-show-m-box {
    border: 1px solid rgba(217, 217, 217);
    display: flex;
    align-items: center;
    padding-left: 16px;
    height: 32px;
    margin-bottom: 10px;
  }
  .delivery-show-m-box > div {
    width: 90%;
    border-right: 1px solid rgba(217, 217, 217);
    padding-right: 14px;
    text-align: center;
  }
  .delivery-show-m-box > div span {
    font-weight: bold;
    margin: 0 4px;
  }

  .delivery-show-m-box input {
    border: none;
    background-color: none;
    text-align: center;
    margin-left: 14px;
  }
  .select-mobile {
    width: 100%;
    margin-bottom: 10px;
  }
  .orderTab {
    margin-left: 0px !important;
  }
  .textLayout {
    height: 30px;
    text-align: center;
  }
  .riderText {
    /* margin-left: 120px; */
    font-size: 18px;
    font-family: NotoSansBold;
  }
  .mapLayout {
    width: 100%;
    height: 600px;
    float: left;
  }
  .regist-call-map {
    height: 200px;
    overflow: hidden;
  }
  .map-navermap {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    width: 100%;
    height: 100%;
  }

  .riderName {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }
  .reception-box {
    width: 100%;
  }
  .reception-box .ant-checkbox-inner {
    width: 25px;
    height: 25px;
  }
  .reception-box .ant-checkbox-inner::after {
    width: 15px;
    height: 15px;
  }

  .reception-box .ant-checkbox-wrapper {
    height: 30px;
    vertical-align: top;
  }

  .reception-box .span1 {
    font-size: 16px;
    margin-top: -40px;
  }

  .table-red {
    background-color: rgb(255, 181, 181);
  }

  .table-redalert {
    background-color: #ff0000;
    color: white;
  }
  .table-redalert:hover {
    background-color: #fff;
    border: 1px solid #ff0000;
    color: #000;
  }
  .table-gray {
    background-color: #a9a9a9;
  }
  .table-purple {
    background-color: #b1b6fb;
  }
  .table-blue {
    background-color: #d6edfe;
    /* color: white; */
  }
  .table-lightyellow {
    background-color: #ffffbf;
  }

  .table-yellow {
    background-color: #b1b6fb;
  }

  .table-orange {
    background-color: #fedeaa;
  }
  .table-moreRed {
    background-color: rgba(246, 110, 111, 0.8);
  }

  .table-white {
    background-color: #fff;
  }
  .btnLayout {
    width: 100vw;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  #reception-table .ant-table .ant-table-tbody > tr:hover > td {
    background-color: #d4fff1;
  }

  #reception-table .ant-table .ant-table-tbody > tr {
    width: 100vw;
  }
  #reception-table .ant-table .ant-table-tbody > tr > td:first-child {
    padding-left: 10px !important;
    width: 30px;
  }
  #reception-table .ant-table .ant-table-tbody > tr > td:last-child {
  }
  #reception-table .ant-table .ant-table-thead > tr > th {
  }
  #reception-table .ant-table .ant-table-thead > tr > th:last-child {
    padding-right: 10px !important;
  }
  #reception-table .ant-table-row-expand-icon-cell {
    display: none;
  }

  /* 기사관리-테이블 재정의 */
  .riderMain-container {
    width: 100%;
  }
  .selectRiderBox {
    margin-top: 30px;
  }
  .dataTableLayout {
    margin-top: 20px;
  }
  .status-box {
    padding: 10px 5px;
    line-height: 26px;
  }
  .status-box > p {
    margin: 0;
    padding: 0;
    font-weight: bold;
    border-radius: 15px;
  }
  .status-box .fr-order-info {
    background-color: #fcdd00;
  }
  .status-box .rider-info {
    background-color: #d1d1d1;
    margin-top: 2px;
  }

  /* hr css */
  .light-hr {
    border: 0;
    height: 1px;
    background-color: #d1d1d1;
  }
  .table-column-sub {
    display: inline-block;
  }

  /*가맹점관리-*/

  /* .ant-table-row-expand-icon-cell{
    width:10px!important;
  } */
  .franchiseContainer {
    width: 310px;
    overflow: scroll;
  }
  .droptable_fr {
    width: 308px;
  }
  .delivery-status-box {
    padding-left: 10px;
    width: 100%;
    display: flex;
  }
  .delivery-status-box > div:last-child {
    border: 1px solid #d1d1d1;
  }
  .delivery-status-mobile {
    display: inline-block;
    text-align: center;
    width: 32%;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 5px;
    font-size: 12px;
  }
  .rider-status-box {
    width: 100%;
    display: flex;
  }
  .rider-status-mobile {
    display: inline-block;
    text-align: center;
    width: 22%;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 5px;
    font-size: 12px;
  }
  .rider-status-box > div:nth-child(4) {
    border: 1px solid #d1d1d1;
    font-size: 12px;
    width: 30%;
  }

  .fr-status-box {
    width: 100%;
    display: flex;
  }
  .fr-status-mobile {
    display: inline-block;
    text-align: center;
    width: 23%;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 5px 8px;
    font-size: 15px;
  }

  /* 통계 */
  .orderStats {
    margin: 10px 0;
    min-width: 320px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
  }

  .stats-input {
    width: 180px;
  }
  .stats-date-box {
    display: flex;
    flex-wrap: wrap;
  }
  .stats-radio {
    padding: 10px 0;
  }
  .stats-date {
    width: 300px;
  }
  .stats-tables {
    width: 100vw;
  }
  .stats-graph {
    width: 98vw;
    padding: 8px;
    border: 5px solid #f5f5f5;
    margin-bottom: 20px;
    margin-left: 5px;
  }
  .stats-line {
    height: 240px;
  }
}
