@media screen and (max-width: 1199px) and (min-width: 300px) {
  .desk {
    display: none !important;
  }

  .mobile {
  }

  .franchiseContainer .selectLayout {
    margin-top: -20px;
    padding-top: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #b5b5b5;
    vertical-align: middle;
    margin-right: 10px !important;
    background-color: white;
    z-index: 100;
    width: 100%;
  }

  .franchiseContainer .dataTableLayout {
    margin-top: 20px;
  }

  .franchiseContainer .override-select.fran {
    width: 220px;
    /* margin-left: 20px; */
  }

  .searchTab {
    margin-left: 0px !important;
  }

  .searchFranchiseInput {
    width: 205px !important;
    margin-left: 15px;
    margin-bottom: 10px;
  }

  .exel img {
    width: 20px;
    margin-right: 5px;
    margin-bottom: 3px;
  }
  .addFranTab {
    margin-left: 0px !important;
    width: 45vw;
  }

  .sectionTab {
    width: 45vw;
  }

  /*가맹점관리-모바일*/
  .dataTableLayout2 {
    /* width: 310px; */
    /* margin-top: 20px; */
  }

  /* .franchiseContainer{
    width:310px;
  } */
  .droptable_fr {
    width: 308px;
  }

  .blind-mobilebtn {
    margin-left: 10px;
  }

  /* 상세 */
  /* .franchiseContainer .ant-table-tbody > tr > td >
      .ant-table-wrapper:only-child .ant-table{
          margin: 0px;
      } */

  /* .franchiseContainer .ant-input-group-wrapper{
          vertical-align: middle;
      } */
}
